import { API_BASE_URL, getOTA,addOTA,deleteOTA } from "../../../utils/urls";
import { FETCH_OTA_START, FETCH_OTA_FAILED, FETCH_OTA_SUCCESS} from "../../actionTypes/ota";
import http from "../../../utils/http-service";
import {decrypt} from "../../../utils/encryption";


export const getOTAs = () => async(dispatch) => {
  dispatch({type: FETCH_OTA_START})
  try {
    const res = await http.get(`${API_BASE_URL}${getOTA}`);
    let otas=res.data.data
    // devices=decrypt(devices);
    // devices=JSON.parse(devices);
//     let decryptedOTA = otas.firmwares&& otas.firmwares.length>0 && otas.formwates.map((obj, index)=<{
//       let object = {
//         _id(pin):"45518c5b-a81e-437c-978d-9b6f5026c8ef",
// name(pin):"test",
// version(pin):1,
// device(pin):"test device",
// link(pin):"099cf36f215b4e14218446973bf1cd0e677193418673c11c4a60a5623b51299fda1a4643c7a830b724857c2efdc6959d06f9fd641ffa4acd",
// status(pin):"active",
// userId(pin):"03b7146a-a242-4ec6-8a4a-beb253a35f8f",
// userPId(pin):"sxTgCiaRd",
// createdAt(pin):"2023-03-31T09:53:39.170Z",
// updatedAt(pin):"2023-03-31T09:53:39.170Z",
//       }
//     })

    dispatch({type: FETCH_OTA_SUCCESS, payload: otas});
    return otas;
  } catch (error) {
    if (error.response.data.status === "failure" &&
     error.response.data.message === "Login Session Expired, Please login")
     {
       // // // console.log("IN DEVICE", error.response.data.message);
       // dispatch(logout());
     }
    dispatch({type: FETCH_OTA_FAILED})

  }
}

// Redux action to make the API call
export const addOTAs = (formData) => async (dispatch) => {
  try {
    // Make the API request using the formData directly
    const res = await http.post(`${API_BASE_URL}${addOTA}`, formData);

    // Handle the API response here if needed

    return true;
  } catch (error) {
    if (
      error.response &&
      error.response.data &&
      error.response.data.status === "failure" &&
      error.response.data.message === "Login Session Expired, Please login"
    ) {
      // Handle session expiration error if necessary
      // For example, you can dispatch a logout action here
      // dispatch(logout());
    }

    return false;
  }
}
