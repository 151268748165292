import React,{useState, useEffect} from "react";
import {Spin, Menu, Dropdown, Button, DatePicker} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {fetchHistoryData} from "../../../redux/actions/history";
import {fetchCompareLeftData} from "../../../redux/actions/compare";
import {filterFromDate,filterToDate} from "../../../redux/actions/user";
import HistoryChart  from "../Charts/historyChart";
import CompareFilters from "./Filter";
import AverageGlucoseReport from "../../../Components/Reports/OverviewComps/AverageGlucoseReport";
import SensorUsageReport from "../../../Components/Reports/OverviewComps/SensorUsageReport";
import TimeInRangeReport from "../../../Components/Reports/OverviewComps/TimeInRangeReport";


import * as _ from "lodash";
import moment from "moment";



const HistoryCompLeft = () => {
    // const [dateFilter,setDateFilter]=useState();

    // const handleFilterDate=(date)=>{
    //     setDateFilter(date);
    // }
    const { RangePicker } = DatePicker;

      const userData = useSelector((state) => state.user);
      const [filterText, setFilterText] = useState("Choose Data Range");
      const [filter, setFilter] = useState("30 days");
      const [isLoading, setIsLoading] = useState(true);
      const [organizedData, setOrganizedData] = useState([]);
      const [daysDif, setDaysDif] = useState("");
      const {history, bg, sensorDetails} = useSelector((state) => {
        // // // console.log(state);
        return (state.compareLeft)});
        // // // console.log(data);
        // const [chartReadings, setChartReadings] = useState([]);
        let dataForChart=[];
        let count;
        let week;
        let days;
        let orgData = [];


    const [dateFilter,setDateFilter]=useState(false);
    const [timeFilter,setTimeFilter]=useState(false);
    const [eventFilter,setEventFilter]=useState(false);
    const [usageFilter,setUsageFilter]=useState(false);
    // overlay report stats end
  // const unit = useSelector((state)=> state.devices.device!== undefined?state.devices.device:null);
  const {unit, low, high} = useSelector((state) => state.settings.settings);

  const [tfHistoricalReadings, setTfHistoricalReadings] = useState([]);
  const [tfBgReadings, setTfBgReadings] = useState([]);
  const [tfAvgBgData, setTfAvgBgData] = useState([]);
  const [tfTimeRange, setTfTimeRange] = useState([]);
  const [showDate, setShowDate] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [todayFilter, setTodayFilter] = useState();
  const [weekFilter, setWeekFilter] = useState();
  const [monthFilter, setMonthFilter] = useState(true);
  const [rangFilter, setRangeFilter] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [dropMenu,setDropMenu]=useState(false);

  const dispatch = useDispatch();

  useEffect(() => {

    if (filter !== "" || startDate !== "") {
      fetchReadings();
    }
  }, []);
  useEffect(() => {
    // console.log("FILTER IN USE:", filter);
    if(filter !=="" && startDate === ""){
      // fetchFilterReadings();
    }
  }, [filter, startDate]);

  const transformHistoricalReadings = (data) => {
// console.log("Data: ", data);
let dataTrans = data.readings[0];
// console.log("dataTrans", dataTrans);
    let transformedData = [];
if(dataTrans && dataTrans.length>0){
    transformedData = dataTrans
        .map((reading) => {


              return {
                date: new Date(reading.time),
                reading: reading.value,
              };
        })
        .flat();

    let myArray = _.sortBy(transformedData.flat(), function(dateObj) {

      return new Date(dateObj.date);
    });

    return myArray;
  }
  };

    const fetchReadings = async () => {
      // // // console.log("IN fetch read");
        let data = {};
        if (filter) {
          data.time = filter;
          // // // console.log(data);
        }
        if (startDate) {

          data.start_date = startDate;
          data.end_date = endDate;
        }
        // if (unit !== null && unit.preferences && unit.preferences.unit !== null) {

          dispatch(fetchCompareLeftData(data));
        // }
        if (rangFilter === false){
        let start = findStartDate(filter);

        setFromDate(moment(start).format('D MMM YYYY , h:mm a').toString());
        setToDate(moment(findStartDate()).format('D MMM YYYY , h:mm a').toString());
      } else if (rangFilter === true) {

      }

      };

    function handleMenuClick(e) {
    switch (e.item.props.value) {
      case "today":
        break;
      case "week":
        break;
      case "month":
        break;
      case "range":
        break;
      default:
        break;
    }
  }

  const findStartDate = (filter) => {
    // console.log("FILTERS DAY", filter);

    let currentDate = new Date();

    if(filter === "30days" || filter === "30 days") {
      let start = moment().subtract(30, 'days');
      return new Date(start._d);
    }else if(filter==="7days" || filter === "7 days") {
      let start = moment().subtract(7, 'days');

      return new Date(start._d);


    } else if (filter === "15days" || filter === "15 days") {

      let start = moment().subtract(14, 'days');
      return new Date(start._d);

    } else if (filter === "today") {

      return new Date();

    } else{

    }

  }
  const findEndDate = () => {
    let currentDate = new Date();
    return (currentDate);
  }

  const fetchRangeReadings=()=>{
    setFilter("");
    if(startDate !=="" && endDate !==""){
      let a = moment(startDate);
      let b = moment(endDate);
      let dif = moment.duration(b.diff(a)).asDays();
      let finalDif = dif>1? dif + " Days": dif + " Day";
      // // // // console.log(typeof(finalDif));

      setDaysDif(finalDif);
    setIsLoading(true);

    setOrganizedData([]);


  let data = {};



    data.start_date = startDate;
    data.end_date = endDate;



    dispatch(fetchCompareLeftData(data));

    if (rangFilter === false){
    let start = findStartDate(filter);

    setFromDate(moment(start).format('D MMM YYYY , h:mm a').toString());
    setToDate(moment(findStartDate()).format('D MMM YYYY , h:mm a').toString());
    const fromDat = moment(start).format('D MMM YYYY , h:mm a').toString();
        const toDat = moment(findStartDate()).format('D MMM YYYY , h:mm a').toString();
        dispatch(filterFromDate(fromDat));
        dispatch(filterToDate(toDat));
  } else if (rangFilter === true) {

  setFromDate(moment(startDate).format('D MMM YYYY , h:mm a').toString());
  setToDate(moment(endDate).format('D MMM YYYY , h:mm a').toString());
}
  }
  }

  const fetchFilterReadings=(e)=>{
    // console.log("event", e.target);
    setStartDate("");
    setEndDate("");
    //setShowDate(false);
    setFilter(e.target.name);
    setFilterText(e.target.name);
    // setWeekFilter(true);
    // setTodayFilter(false);
    // setMonthFilter(false);
    setRangeFilter(false);
    setIsLoading(true);
    setDropMenu(false);


    if(filter !== e.target.name){
      setOrganizedData([]);
    }

  let data = {};

    data.time = e.target.name;



    dispatch(fetchCompareLeftData(data));

    if (rangFilter === false){
    let start = findStartDate(filter);

    setFromDate(moment(start).format('D MMM YYYY , h:mm a').toString());
    setToDate(moment(findStartDate()).format('D MMM YYYY , h:mm a').toString());
    const fromDat = moment(start).format('D MMM YYYY , h:mm a').toString();
        const toDat = moment(findStartDate()).format('D MMM YYYY , h:mm a').toString();
        dispatch(filterFromDate(fromDat));
        dispatch(filterToDate(toDat));
  } else if (rangFilter === true) {

}


  }

    return (
        <div className="container-fluid">
        <div className="row  mr-sm-0">
          <div className="col-12 mt-md-0 mt-2"  style={{ height: "100%" }}>
            <div className="bg-white rounded shadow " style={{ height: "100%" }}>
              {/* <HistoryFilter onSelectDays={handleFilterDate}/> */}


              <div className="sticky-top">
                  <div className="d-flex justify-content-between mb-2">
                    <div className="chart-heading mt-3 loginNew-HCP-panel">
                      <span className="bg-primary px-2 py-2 font-size2 font-weight-bold text-white">
                        History
                      </span>
                    </div>

                  </div>
                  <CompareFilters filter={filter} rangFilter={rangFilter} setRangeFilter={setRangeFilter}
                  setFilter={setFilter}
                   daysDif={daysDif} fromDate={fromDate} toDate={toDate}
                   startDate={startDate} fetchFilterReadings={fetchFilterReadings}
                   fetchRangeReadings={fetchRangeReadings} startDate={startDate}
                   endDate={endDate} dropMenu={dropMenu} setDropMenu={setDropMenu}
                   setStartDate={setStartDate} setEndDate={setEndDate} filterText={filterText} setFilterText={setFilterText} showDays="90" showRange={false}/>
                </div>
                </div>
              </div>
            </div>

            <div className="row  mr-sm-0  mt-2">
              <div className="col-12" style={{ height: "300px" }}>
                <div className="bg-white rounded shadow " style={{ height: "100%" }}>
                  <AverageGlucoseReport avg={bg} averageDeviation={bg.averageDeviation}/>
                </div>
              </div>

              <div className="col-12 timeRange  my-2" style={{ height: "300px" }} >
                <div className="bg-white rounded shadow " style={{ height: "100%" }}>
                  <TimeInRangeReport chartId="compareHistoryLeftChart" data={bg} unit={unit} low={low} high={high}/>
                </div>
              </div>

              {/*<div className="col-12 mt-2" style={{ height: "300px" }}>
                <div className="bg-white rounded shadow " style={{ height: "100%" }}>
                  <SensorUsageReport sensorDetails={sensorDetails}/>
                </div>
              </div>*/}
            </div>

        <div className="row  mr-sm-0  mt-2 justify-content-center">
          <div className=" col-md-12" style={{ height: "100%" }}>
            <div className="bg-white rounded shadow " style={{ height: "100%" }}>
              {/*<HistoryReport/>*/}
              <div className='h-100 d-flex flex-column justify-content-md-between justify-content-center px-3 pt-2'>
            <div className="d-flex flex-column justify-content-between  mt-5 pt-2 mb-4">
            {history && history.length > 0 && history.map((day, index)=> (
              <>
              <HistoryChart chartId={"HistoryReportLeft"+ index} data={transformHistoricalReadings(day)} startDate={day.dayStartDate} />

              </>
            ))}

            </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default HistoryCompLeft;
