import React, { useState,useEffect } from "react";
// import { LogoComponent as Logo } from "../../Assets";
import { default as Titles } from "../../utils/document-titles";
import { Spin, Form, Input } from "antd";
import { useDispatch } from "react-redux";
import  LoginBackground from "../../Assets/loginBackground.jpg";
import { Link, useNavigate } from "react-router-dom";
import { resetEmailConfirmReq } from "../../redux/actions/auth";
import GlucoRxLogo from "../../Assets/glucorxwhite.svg";
import ShowToast from "../../utils/show-toast";
import LoadingEmail from "./loading-email";
import {encrypt,decrypt} from "../../utils/encryption";
// import ShowToast from "../../utils/show-toast";

export default function ResetEmailConfirm() {
  // const [tokUrl, setTokUrl] = useState("");

  useEffect(()=>{
    handleSubmit();

  },[])
  // document.title = Titles.forgotPassword;

  const history = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");


  const dispatch = useDispatch();







  const handleSubmit = async () => {
    let newEmail = email;
    let tokenUrl = new URLSearchParams(window.location.search).get("token");
    if(tokenUrl === "" || tokenUrl === null){

      tokenUrl =localStorage.getItem("token");

    } else{


    }


    try {
      setIsLoading(true);
      localStorage.removeItem("token");
      localStorage.removeItem("pathname");
      const result=await dispatch(resetEmailConfirmReq(tokenUrl));

      history.push("/dashboard");

      ShowToast(result.data.message, "success");
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      history.push("/dashboard");
    }
    return true;


  };

  return (
    <div className='container-fluid d-flex flex-column forget-background ' >

    <div className="row justify-center align-item-center my-auto" >
        <div className="col-md-7 col-11 forget-field-box align-items-center py-5">
           <div className='d-flex justify-content-center '><img src={GlucoRxLogo} className='forget-gluco-logo '></img></div>

            {/*<div className="mt-3 text-white">
                <p className="text-center forget-text-font">
                <Link
              to={"/auth/login"}>
              Back to Sign In!
            </Link>
                </p>
            </div>*/}
        </div>


        </div>


</div>
  );
}
