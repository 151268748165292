import React,{useState} from 'react';
import {NavLink, useNavigate} from "react-router-dom";
import { useDispatch } from "react-redux";
import { register } from "../../redux/actions/auth";
import GlucoRxLogo from "../../Assets/Logo/GlucoRxLogo.svg";
import FooterEostre from "../../Assets/Logo/FooterEostre.svg";
import Playstore from "../../Assets/Logo/play-store.png";
import appstore from "../../Assets/Logo/app-store.png";


import countryList from './CountriesList';
var pattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-_]).{8,}$/;
const RegistrationNewComp = () => {
const dispatch = useDispatch();
const history = useNavigate();
// const [isLoading, setIsLoading] = useState(false);
const [firstName, setFirstName] = useState("");
const [lastName, setLastName] = useState("");
const [gender, setGender] = useState("");
const [dob, setDob] = useState();
const [email, setEmail] = useState("");
const [password, setPassword] = useState("");
const [confirmPassword, setConfirmPassword] = useState("");
const [address, setAddress] = useState("");
const [town, setTown] = useState("");
const [country, setCountry] = useState("");
const [postcode, setPostcode] = useState("");
const [phone, setPhone] = useState("");
const [diabteseType, setDiabteseType] = useState();
// const [matchPassword,setMatchPassword]=useState( false)
const [checked, setChecked] = useState(false);
const [error,setError]=useState("")
const [passwordShown, setPasswordShown] = useState(false);
const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
const   handleConfirmPasswordChange = (e) => {
setConfirmPassword(e.target.value);
// setMatchPassword(null)
};

const togglePasswordVisiblity = () => {
setPasswordShown(passwordShown ? false : true);
};
const toggleConfirmPasswordVisiblity = () => {
setConfirmPasswordShown(confirmPasswordShown ? false : true);
};
const handleSubmit = async (e) => {
  e.preventDefault()
  if(checked){
    let re;
    if(password !== "" ) {
      if(password.length < 8 && password===confirmPassword) {
        setError("Password must contain at least Eight characters!","error");
        return false;
      }
      if(password === firstName) {
        setError("Password must be different from Username!","error");
        return false;
      }
      re = /[0-9]/;
      if(!re.test(password)) {
        setError("Password must contain at least one number (0-9)!","error");
        return false;
      }
      re = /[a-z]/;
      if(!re.test(password)) {
        setError("Password must contain at least one lowercase letter (a-z)!","error");
        return false;
      }
      re = /[A-Z]/;
      if(!re.test(password)) {
        setError("Password must contain at least one uppercase letter (A-Z)!","error");
        return false;
      }
      re=/[~!@#$%^&*_\-+=?]/;
      if(!re.test(password)) {
        setError("Password must contain at least one special character (#?!@$%^&*-_)!","error");
      }
      if (password !== confirmPassword){
        setError("Password is not matched","error");
        return false;
      }
    }
    // alert("Everything is valid")
  }
  else{
    setError("Terms and Condition Not checked","error")
  }
  try {
    // setIsLoading(true);
    e.preventDefault();
    await dispatch(register({

    firstName:firstName,
    lastName:lastName,
    email:email,
    password:password,
    gender:gender,
    dateOfBirth:dob,
    address:address,
    phone: phone,
    postcode:postcode,
    town:town,
    country:country,
    diabetesType:diabteseType }));
      // setIsLoading(false);
     history("/auth/login");
    } catch (err) {
      // setIsLoading(false);
    }
};
return (
<div>
   <div className="">
      <div className='container-fluid d-flex flex-column justify-content-between registrationNew-bg'>
         <div className="row  justify-content-md-between  justify-content-center px-md-4 px-0  " >
            <div className="col-md-4 col-11  mt-5">
               <div className="text-md-left text-center ">
                  <img src={GlucoRxLogo} width="200px" alt="Glucorx Logo"></img>
               </div>
               <div className="text-md-left text-center my-5  text-uppercase">
                  <h1 className='registrationNew-heading-1'><span className="font-weight-bold">CREATE A
                     VOYAGER ACCOUNT</span>
                  </h1>
               </div>
               <div className="text-md-left text-center mt-5">
                  <h3 className="font-weight-bold text-uppercase login-account-heading2">Create a GlucoRx Account here
                     to use our apps & access data software.
                     It only takes a few minutes.
                  </h3>
               </div>
            </div>
            <div className="col-md-6 col-sm-6 col-11 registrationNew-fieldBox py-3 ">
               <form onSubmit={handleSubmit}>
                  <div className="container ">
                     <div className="row justify-content-center text-white">
                        <div className="col-md-11">
                           <h2 className="font-weight-bold font-blue-dark registrationNew-welcome">Welcome</h2>
                           <p className="font-weight-bold font-blue-dark registrationNew-register">Register to Continue Login</p>
                        </div>
                        <div className="col-md-11 mt-1">
                           <div className="row justify-content-between">
                              <div className='col-md-6'>
                                 <div className="form-group">
                                    <input
                                       type="text"
                                       className="registrationNew-form-control"
                                       placeholder="First Name"
                                       name="firstName"
                                       id="formGroupExampleInput"
                                       value={firstName}
                                       onChange={(e) => setFirstName(e.target.value)}
                                    required
                                    />
                                 </div>
                              </div>
                              <div className='col-md-6'>
                                 <div className="form-group">
                                    <input
                                       type="text"
                                       className="registrationNew-form-control"
                                       placeholder="Last Name"
                                       name="lastName"
                                       id="formGroupExampleInput"
                                       value={lastName}
                                       onChange={(e) => setLastName(e.target.value)}
                                    required
                                    />
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-11 ">
                           <div className="row justify-content-between">
                              <div className='col-md-6'>
                                 <div className="form-group">
                                    <div className="styled-select form-group">
                                       <select
                                          value={gender}
                                          onChange={(e) =>
                                          setGender(e.target.value)}
                                          name="gender"
                                          placeholder="Select gender"
                                          className="registrationNew-form-control"
                                          >
                                          <option value="Select">Select Gender</option>
                                          <option value="Male">Male</option>
                                          <option value="Female">Female</option>
                                          <option value="Female">Other</option>
                                       </select>
                                    </div>
                                 </div>
                              </div>
                              <div className='col-md-6'>
                                 <div className="form-group">
                                    <input
                                       type="date"
                                       className="registrationNew-form-control"
                                       placeholder="DOB"
                                       name="dob"
                                       id="formGroupExampleInput"
                                       value={dob}
                                       onChange={(e) => setDob(e.target.value)}
                                    required
                                    />
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-11 ">
                           <div className="row justify-content-between">
                              <div className='col-md-6'>
                                 <div className="form-group">
                                    <div className="styled-select form-group">
                                    <input
                                       type="number"
                                       className="registrationNew-form-control"
                                       placeholder="Postcode"
                                       name="postcode"
                                       id="postcode"
                                       value={postcode}
                                       onChange={(e) => setPostcode(e.target.value)}
                                    required
                                    />
                                    </div>
                                 </div>
                              </div>
                              <div className='col-md-6'>
                                 <div className="form-group">
                                    <input
                                       type="tel"
                                       className="registrationNew-form-control"
                                       placeholder="Phone Number"
                                       name="dob"
                                       id="phone"
                                       value={phone}
                                       onChange={(e) => setPhone(e.target.value)}
                                    required
                                    />
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-11 ">
                           <div className="row justify-content-between">
                              <div className='col-md-12'>
                                 <div className="form-group">
                                    <input
                                       type="text"
                                       className="registrationNew-form-control"
                                       placeholder="Address"
                                       name="address"
                                       id="formGroupExampleInput"
                                       value={address}
                                       onChange={(e) => setAddress(e.target.value)}
                                    required
                                    />
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-11 ">
                           <div className="row justify-content-between">
                              <div className='col-md-12'>
                                 <div className="styled-select form-group">
                                    <select
                                       // value={diabteseType}
                                       onChange={(e) =>
                                       setDiabteseType(e.target.value=== "Type 1"? 1: 2)}
                                       name="d-type"
                                       placeholder="Select Diabtese type"
                                       className="registrationNew-form-control"
                                       >
                                       <option value="Select">Select Diabetes Type</option>
                                       <option value="type 1">Type 1</option>
                                       <option value="type 2">Type 2</option>
                                    </select>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-11 ">
                           <div className="row justify-content-between">
                              <div className='col-md-12'>
                                 <div className="styled-select form-group">
                                    <select
                                       value={country}
                                       onChange={(e) =>
                                       setCountry(e.target.value)}
                                       name="country"
                                       placeholder="Select Country"
                                       className="registrationNew-form-control"
                                       >
                                       <option value="Select">Select Country</option>
                                       {countryList.map((option, index) => (
                                       <option key={index} value={option}>{option}</option>
                                       ))}
                                    </select>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-11 ">
                           <div className="row justify-content-between">
                              <div className='col-md-6'>
                                 <div className="form-group">
                                    <input
                                       type="text"
                                       name="town"
                                       className="registrationNew-form-control"
                                       placeholder="Town"
                                       id="formGroupExampleInput"
                                       value={town}
                                       onChange={(e) => setTown(e.target.value)}
                                    required
                                    />
                                 </div>
                              </div>
                              <div className='col-md-6'>
                                 <div className="form-group">
                                    <input
                                       type="email"
                                       name="email"
                                       className="registrationNew-form-control"
                                       placeholder="Email"
                                       id="formGroupExampleInput"
                                       value={email}
                                       onChange={(e) => setEmail(e.target.value)}
                                    required
                                    />
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div>
                        </div>
                        <div className="col-md-11 ">
                           <div className="row justify-content-between">
                              <div className='col-md-6'>
                                 <div className="form-group">
                                    <div className="pass-wrapper">
                                       <input
                                       type={passwordShown ? "text" : "password"}
                                       name="password"
                                       className="registrationNew-form-control"
                                       placeholder="Password"
                                       id="formGroupExampleInput"
                                       value={password}
                                       onChange={(e) => setPassword(e.target.value)}
                                       required
                                       />
                                       {passwordShown===true?<i className="fa fa-eye" onClick={togglePasswordVisiblity}></i>
                                          :<i className="fas fa-eye-slash" onClick={togglePasswordVisiblity}></i>}
                                       {!pattern.test(password)?
                                       <div className="invalid-feedback d-block">
                                          {error===""?"":error}

                                       </div>
                                       :null}
                                    </div>
                                 </div>
                              </div>
                              <div className='col-md-6'>
                                 <div className="form-group">
                                    <div className="pass-wrapper">
                                       <input
                                       type={confirmPasswordShown ? "text" : "password"}
                                       name="cpassword"
                                       className={`registrationNew-form-control ${password===confirmPassword ? null:'error-color-input' }`}
                                       placeholder="Confirm Password"
                                       id="formGroupExampleInput"
                                       value={confirmPassword}
                                       onChange={(e) => handleConfirmPasswordChange(e)}
                                       required
                                       />
                                       {passwordShown===true?<i className="fa fa-eye" onClick={toggleConfirmPasswordVisiblity}></i>
                                       :<i className="fas fa-eye-slash" onClick={toggleConfirmPasswordVisiblity}></i>}
                                    </div>
                                 </div>
                              </div>
                              {/*
                              <div className="col-12">
                                 {!pattern.test(password)?
                                 <div className="invalid-feedback d-block">
                                    {error===""?"":error}
                                 </div>
                                 :null}
                              </div>
                              */}
                           </div>
                        </div>
                        <div className="col-md-11">
                           <div className='row'>
                              <div className="col-md-12">
                                 <div className="form-check form-check-inline">
                                    <input
                                       checked={checked}
                                       onChange={() => setChecked(!checked)}
                                    className=" my_checkbox"
                                    type="checkbox"
                                    id="inlineCheckbox1"
                                    value="option1"
                                    />
                                    <label
                                       className="form-check-label ml-2 registrationNew-terms "
                                       htmlFor="inlineCheckbox1"
                                       >
                                    By checking this box, you agree to the terms of use
                                    and privacy policy.
                                    </label>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-11 mt-2 ">
                           <div className='row'>
                              <div className="col-md-6">
                                 <button
                                    type="submit"
                                    className="registerNew-btn btn-primary "
                                    >
                                 Register
                                 </button>
                              </div>
                              <div className="col-md-6">
                                 <NavLink to="/">
                                    <button
                                       type="submit"
                                       className="registerNew-login-btn btn-success  mt-md-0 mt-3"
                                       >
                                    Already Registerd
                                    </button>
                                 </NavLink>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </form>
            </div>
         </div>
         <div className='row justify-content-between align-items-center registrationNew-footer'>
            <div className='col-md-3 col-11'>
               <img src={FooterEostre} width="150px" alt="footer image"></img>
            </div>
            <div className='col-md-5  col-11'>
               <div className="container-fluid">
                  <div className='row justify-content-center'>
                     <div className='col-12 text-right'>
                        <img src={appstore} width="100px" className='mr-2' alt="app store image"></img>
                        <img src={Playstore} width="100px" alt="Play store Image"></img>
                     </div>
                  </div>
                  <div className='row justify-content-center'>
                     <div className='col-12'>
                        <div className='text-md-right text-center registrationNew-footer-text'>
                           <NavLink to="/">
                              2020 Eostre, Inc. All Rights Reserved.
                           </NavLink>
                           <span className="footer-vertical-line">
                           </span>
                           <NavLink to="/">
                              Privacy Policy.
                           </NavLink>
                           <span className="footer-vertical-line">
                           </span>
                           <NavLink to="/">
                              Terms of Use.
                           </NavLink>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
);
};
export default RegistrationNewComp;
