export const FETCH_USER_START = "FETCH_USER_START";
export const FETCH_USER_FAILED = "FETCH_USER_FAILED";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";

export const SAVE_USER_START = "SAVE_USER_START";
export const SAVE_USER_FAILED = "SAVE_USER_FAILED";
export const SAVE_USER_SUCCESS = "SAVE_USER_SUCCESS";

export const UPDATE_ROLE = "UPDATE_ROLE";

export const UPDATE_USER_START = "UPDATE_USER_START";
export const UPDATE_USER_FAILED = "UPDATE_USER_FAILED";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";



export const SAVE_FROM_DATE_SUCCESS = "SAVE_FROM_DATE_SUCCESS";
export const SAVE_TO_DATE_SUCCESS = "SAVE_TO_DATE_SUCCESS";
