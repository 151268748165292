import React from 'react';
import HistoryCompLeft from './CompareHistoryLeft';
import HistoryCompRight from './CompareHistoryRight';


const CompareHistory = () => {
    return (
        <div className='container-fluid'>
            <div className= "row mw-100 m-auto">
              <div className="col-12 col-md-6">
              <HistoryCompLeft/>
              </div>
              <div className="col-12 col-md-6">
              <HistoryCompRight/>

              </div>

            </div>


        </div>
    );
};

export default CompareHistory;
