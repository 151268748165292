// import React, { useEffect,useState } from "react";
// import { Spin, DatePicker } from "antd";
// import moment from "moment";
// import { Link, useNavigate } from "react-router-dom";
// // import {fetchExportData} from "../../../redux/actions/export";
// import { useSelector, useDispatch } from "react-redux";
// import {encrypt,decrypt} from "../../utils/encryption";
// // const WEIGHT = require("../../../assets/GRx Weight Scale-2.jpeg");
// // const BP = require("../../../assets/blood_pressure_meter.png");
// // const TEMP = require("../../../assets/GRx Forehead Thermometer Pro.jpg");
// // const PO = require("../../../assets/GRx Fingertip Pulse Oximeter.png");
// // const Nexus= require("../../../assets/products/GlucoRxNexusMeter.png");
// import ovrview from "../../Assets/Sidebar-Icons/overview.svg";
// import paterns from "../../Assets/Sidebar-Icons/patterns.svg";
// import trnds from "../../Assets/Sidebar-Icons/trends.svg";
// import ovrlays from "../../Assets/Sidebar-Icons/overlays.svg";
// import histry from "../../Assets/Sidebar-Icons/history.svg";
// import compre from "../../Assets/Sidebar-Icons/compare.svg";
// import statistcs from "../../Assets/Sidebar-Icons/statistics.svg";
// import Agp from "../../Assets/Sidebar-Icons/Agp.svg";
//
// const PDF =  require("../../Assets/pdf.png");
//
// const ExportData = () => {
//   const dispatch = useDispatch();
//   const history = useNavigate();
//   const device = useSelector((state) =>(state.devices.devices));
//   const { RangePicker } = DatePicker;
//   const [isLoading, setIsLoading] = useState(false);
//   const data = useSelector((state) => state);
//   const [endDate, setStartDate] = useState("");
//   const [startDate, setEndDate] = useState("");
//   const [fromDate, setFromDate] = useState("");
//   const [toDate, setToDate] = useState("");
//   const [dropMenu, setDropMenu] = useState(false);
//   const [days, setDays] = useState("30days");
//   const [bg, setBg] = useState(true);
//   const [bp, setBp] = useState(false);
//   const [weight, setWeight] = useState(false);
//   const [temp,setTemp]=useState(false);
//   const [spo2,setSpo2]=useState(false);
//   const [overview,setOverview]=useState(false);
//   const [patterns,setPatterns]=useState(false);
//   const [trends,setTrends]=useState(false);
//   const [overlay,setOverlay]=useState(false);
//   const [historyTab,setHistoryTab]=useState(false);
//   const [compare,setCompare]=useState(false);
//   const [statistics,setStatistics]=useState(false);
//   const [agp,setAgp]=useState(false);
//   const [showButton,setShowButton]=useState(false);
//
//
//
//
//
//
//
//
//
//    //Fetch Export Data
//   //  useEffect(()=>{
//   //   handleExportData()
//   //   },[])
//
//   const handleExportData=async()=>{
//     if(overview === true||
//     patterns=== true ||
//     trends=== true ||
//     overlay === true||
//     historyTab === true||
//     compare === true||
//     statistics === true||
//     agp === true){
//       let data={time:days,start:startDate,end:endDate,
//         overview:overview,patterns:patterns,
//         trends:trends,overlay:overlay,historyTab:historyTab,compare:compare,
//        statistics:statistics,agp:agp,}
//        data=JSON.stringify(data);
//               data=encrypt(data);
//               localStorage.setItem("export",data);
//               document.getElementById('closebtn').click();
//              history({
//                           pathname:"/export-data",
//
//                         });
//     }
//
//     // //("data=",data)
//     // try {
//     //   setIsLoading(true);
//     //   let response= await dispatch( fetchExportData(data))
//     //         setIsLoading(false);
//     //         data=JSON.stringify(data)
//     //         data=encrypt(data)
//     //         localStorage.setItem("export",data)
//     //        history({
//     //           pathname:"/export-data",
//
//     //         });
//     //         //("printing")
//     // } catch (error) {
//     //     setIsLoading(false);
//
//     // }
//
//   }
//
//
//
//   //Handle Filters
//   const handleDaysFilter = (d) => {
//     setDays(d);
//     setStartDate("");
//     setEndDate("");
//   };
//   //Handle Date Picker
//   const handleDateChange = (val) => {
//     if (val && val.length > 0) {
//       setStartDate(val[1].format("YYYY-MM-DD").toString());
//       setEndDate(val[0].format("YYYY-MM-DD").toString());
//       setToDate(val[1].format("YYYY-MM-DD").toString());
//       setFromDate(val[0].format("YYYY-MM-DD").toString());
//       setDays("")
//     }
//
//   };
//
//
//   return (
//     <div className="col-12">
//       <button
//         type="button"
//         className="p-0 pdf-btn"
//         data-toggle="modal"
//         data-target="#exportModal"
//         style={{background: "none"}}
//       >
//       <img src={PDF} className="pdf-img" style={{width:"25px"}} alt="PDF Icon"/>
//       <p className="mt-1">Export</p>
//
//         {/* <i className="fas fa-file-export"></i>*/}
//       </button>
//
//
//       <div
//         className="modal fade"
//         id="exportModal"
//         tabindex="-1"
//         role="dialog"
//         aria-labelledby="exampleModalLabel"
//         // aria-hidden="true"
//       >
//         <div className="modal-dialog" role="document">
//           <div className="modal-content">
//             <div className="modal-header">
//               <h5 className="modal-title" id="exampleModalLabel">
//                 Export
//               </h5>
//               <button
//                 type="button"
//                 className="close"
//                 data-dismiss="modal"
//                 aria-label="Close"
//                 id="closebtn"
//               >
//                 <span aria-hidden="true">&times;</span>
//               </button>
//             </div>
//
//             <div className="modal-body">
//               <div className="container">
//                 <div className="d-flex flex-row justify-content-center">
//                   <button
//                     className={
//                       startDate === "" && days === "7days"
//                         ? "bg-primary text-white rounded px-2 mr-2"
//                         : " bg-light rounded px-2 mr-2"
//                     }
//                     onClick={() => {
//                       handleDaysFilter("7days");
//                     }}
//                   >
//                     7 Days
//                   </button>
//                   <button
//                     className={
//                       startDate === "" && days === "15days"
//                         ? "bg-primary text-white rounded px-2 mr-2"
//                         : " bg-light rounded px-2 mr-2"
//                     }
//                     onClick={() => {
//                       handleDaysFilter("15days");
//                     }}
//                   >
//                     15 days
//                   </button>
//                   <button
//                     className={
//                       startDate === "" && days === "30days"
//                         ? "bg-primary text-white rounded px-2 mr-2"
//                         : " bg-light rounded px-2 mr-2"
//                     }
//                     onClick={() => {
//                       handleDaysFilter("30days");
//                     }}
//                   >
//                     30 Days
//                   </button>
//                   <button
//                     className={
//                       startDate === "" && days === "90days"
//                         ? "bg-primary text-white rounded px-2 mr-2"
//                         : " bg-light rounded px-2 mr-2"
//                     }
//                     onClick={() => {
//                       handleDaysFilter("90days");
//                     }}
//                   >
//                     90 Days
//                   </button>
//                   <button
//                     className={
//                       startDate !== ""
//                         ? "bg-primary text-white rounded px-2 dropdown"
//                         : "bg-light rounded px-2 dropdown"
//                     }
//                     onClick={() => {
//                       setDropMenu(!dropMenu);
//                     }}
//                   >
//                     Range
//                   </button>
//                 </div>
//                 <div className="row text-center mt-3 justify-content-center">
//                   <div className="col-12">
//                     <div className={dropMenu ? " d-block" : " d-none"}>
//                       <RangePicker
//                         defaultValue={
//                           startDate && [moment(startDate), moment(endDate)]
//                         }
//                         onChange={(val) => {
//                           handleDateChange(val);
//                           setShowButton(!showButton);
//                         }}
//                       />
//                     </div>
//                   </div>
//                 </div>
//                 <div className='row justify-content-center align-items-center my-5'>
//
//                   <div className='col-12'>
//                      <div className='row justify-content-center pdf-device'>
//                         <div className='col-3'>
//                        <button className={
//                       overview === true
//                       ? " btnImgColor-hover bg-primary text-white rounded px-3 mr-2 py-3"
//                       : " btnImgColor rounded px-3 mr-2 py-3"
//                     } onClick={()=>{
//                         setOverview(!overview);
//
//
//                       }}>
//                          <div className="example example-cover row">
//                          <div className="col-12">
//                          <img src={ovrview} size="20px"></img>
//                          </div>
//                          <div className="col-12">
//
//                           <span className="d-none d-md-inline">Overview</span>
//                           </div>
//                         </div>
//                       </button>
//                         </div>
//
//
//
//                         <div className='col-3'>
//                           <button className={
//                          overlay === true
//                          ? " btnImgColor-hover bg-primary text-white rounded px-3 mr-2 py-3"
//                          : " btnImgColor rounded px-3 mr-2 py-3"
//                        } onClick={()=>{
//                            setOverlay(!overlay)
//                          }}>
//                             <div className="example example-cover row">
//                             <div className="col-12">
//                             <img src={ovrlays} size="20px"></img>
//                             </div>
//                             <div className="col-12">
//
//                              <span className="d-none d-md-inline">Overlays</span>
//                              </div>
//                            </div>
//                          </button>
//                         </div>
//
//                         <div className='col-3'>
//                          <button className={
//                         historyTab === true
//                         ? " btnImgColor-hover bg-primary text-white rounded px-3 mr-2 py-3"
//                         : " btnImgColor rounded px-3 mr-2 py-3"
//                       } onClick={()=>{
//                           setHistoryTab(!historyTab)
//                         }}>
//                            <div className="example example-cover row">
//                            <div className="col-12">
//                            <img src={histry} size="20px"></img>
//                            </div>
//                            <div className="col-12">
//
//                             <span className="d-none d-md-inline">History</span>
//                             </div>
//                           </div>
//                         </button>
//                         </div>
//
//                         <div className='col-3'>
//                           <button className={
//                          compare === true
//                          ? " btnImgColor-hover bg-primary text-white rounded px-3 mr-2 py-3"
//                          : " btnImgColor rounded px-3 mr-2 py-3"
//                        } onClick={()=>{
//                            setCompare(!compare)
//                          }}>
//                             <div className="example example-cover row">
//                             <div className="col-12">
//                             <img src={compre} size="20px"></img>
//                             </div>
//                             <div className="col-12">
//
//                              <span className="d-none d-md-inline">Compare</span>
//                              </div>
//                            </div>
//                          </button>
//                         </div>
//
//
//
//                         <div className='col-3'>
//                         <button className={
//                        agp === true
//                        ? " btnImgColor-hover bg-primary text-white rounded px-3 mr-2 py-3"
//                        : " btnImgColor rounded px-3 mr-2 py-3"
//                      } onClick={()=>{
//                          setAgp(!agp)
//                        }}>
//                           <div className="example example-cover row">
//                           <div className="col-12">
//                           <img src={Agp} size="20px"></img>
//                           </div>
//                           <div className="col-12">
//
//                            <span className="d-none d-md-inline">Ogp</span>
//                            </div>
//                          </div>
//                        </button>
//                        </div>
//                      </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="modal-footer">
//               {
//             overview === true||
//             patterns=== true ||
//             trends=== true ||
//             overlay === true||
//             historyTab === true||
//             compare === true||
//             statistics === true||
//             agp === true
//               ?
//                 <div>
//                 <Spin spinning={isLoading}>
//                 <button type="button" className="btn btn-primary" onClick={handleExportData}>
//                 Export
//                 </button>
//                 </Spin>
//                 </div>:null}
//
//
//       </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };
//
// export default ExportData;


// New version
import React, { useState } from "react";
import { DatePicker } from "antd";
import moment from "moment";
import { useNavigate } from "react-router-dom";
// import {fetchExportData} from "../../../redux/actions/export";
import { useSelector, useDispatch } from "react-redux";
// import {encrypt,decrypt} from "../../utils/encryption";
import {fetchBgDataForCsv, fetchSensorData} from "../../redux/actions/blood-glucose";
const PDF =  require("../../Assets/pdf.png");



// const PDF =  require("../../Assets/pdf.png");

const ExportData = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const user = useSelector((state) => {
    if(state.user && state.user.userDetails){
      return state.user.userDetails;
    }});
  // const device = useSelector((state) =>(state.devices.devices));
  const { RangePicker } = DatePicker;
  // const [isLoading, setIsLoading] = useState(false);
  // const data = useSelector((state) => state);
  const [endDate, setStartDate] = useState("");
  const [startDate, setEndDate] = useState("");
  // const [repoUrl, setRepoUrl] = useState("");

  // const [fromDate, setFromDate] = useState("");
  // const [toDate, setToDate] = useState("");
  const [dropMenu, setDropMenu] = useState(false);
  const [days, setDays] = useState("");

  const [showButton,setShowButton]=useState(false);
  // const [exportableDays,setExportableDays]=useState(false);
  const [allowedToShow, setAllowedToShow]=useState(false);

  const safari = useSelector((state) => {
    return state.auth.safari;
    });


  // useEffect(() => {
  //
  //   if (days !== "" || startDate !== "") {
  //     fetchReadings();
  //   }
  // }, [days, startDate]);
  const fetchReadings = async () => {


    let duration = {};
    if (days !== "") {
      let data_from = findStartDate(days);
      let data_to = findEndDate();
      duration.timeFrom = data_from;
      duration.timeTo = data_to;


      let startRep = data_from;
      startRep = moment(startRep).format("yyyy-MM-DD");
      let endRep = data_to;
      endRep = moment(endRep).format("yyyy-MM-DD");
      let eml = user && user.email ?user.email: null;
      if(eml !== "" && eml !== null){

        eml = eml.replace("@","%40");

      }
      let uuid = user && user.uuid ?user.uuid: null;
      let gender = user && user.gender && user.gender !== null? user.gender: "";


      let nam = user && user.name ?user.name: null;
      let repUrl = `https://report.grxaidex.co.uk/en/aidex?id=${uuid}&device=""&start=${startRep}&end=${endRep}&gender=${gender}&email=${eml}&name=${nam}`

        // setRepoUrl(repUrl);
        duration.assesmentUrl = repUrl;


    }
    if (startDate) {

      duration.timeFrom = new Date(startDate);
      duration.timeTo = new Date (endDate);


      let startRep = new Date(startDate);
      startRep = moment(startRep).format("yyyy-MM-DD");
      let endRep = new Date (endDate);
      endRep = moment(endRep).format("yyyy-MM-DD");
      let eml = user && user.email ?user.email: null;
      if(eml !== "" && eml !== null){

        eml = eml.replace("@","%40");

      }
      let uuid = user && user.uuid ?user.uuid: null;
      let gender = user && user.gender && user.gender !== null? user.gender: "";


      let nam = user && user.name ?user.name: null;
      let repUrl = `https://report.grxaidex.co.uk/en/aidex?id=${uuid}&device=""&start=${startRep}&end=${endRep}&gender=${gender}&email=${eml}&name=${nam}`

        // setRepoUrl(repUrl);

        duration.assesmentUrl = repUrl;
    }
    // // // console.log("DURATION", duration);
     await dispatch(fetchBgDataForCsv(duration));
    // // // console.log("REs Expo",response);
    // if(response.data && response.data>0){
      // setOrganizedData(response.data);
      // setCalculatedValues(response.calculation);
      // setCsvButton(true);
    // }

  };
  const fetchReadingsForCsv = async () => {


    let duration = {};
    if (days !== "") {
      let data_from = findStartDate(days);
      let data_to = findEndDate();
      duration.start_date = moment(data_from).format('YYYY-MM-DD').toString();
      duration.end_date = moment(data_to).format('YYYY-MM-DD').toString();


    }
    if (startDate) {

      duration.start_date =  moment(startDate).format('D MMM YYYY , h:mm a').toString();
      duration.end_date =  moment(endDate).format('D MMM YYYY , h:mm a').toString();




    }
    const response = await dispatch(fetchBgDataForCsv(duration));
    console.log("response", response);
    if(response && response.historicalReadings && response.historicalReadings.length>0){
      // setOrganizedData(response.data);
      // setCalculatedValues(response.calculation);
      // setCsvButton(true);
      convert(response.historicalReadings);

    }
      // const response = await dispatch(fetchExportCgmData(duration));
      // if(response.status === 200){
      //   convert();
      // }
  //   if (rangFilter === false){
  //   let start = findStartDate(filter);
  //
  //   setFromDate(moment(start).format('D MMM YYYY , h:mm a').toString());
  //   setToDate(moment(findStartDate()).format('D MMM YYYY , h:mm a').toString());
  // } else if (rangFilter === true) {
  //
  // }
  };





  const findStartDate = (filter) => {

    // let currentDate = new Date();

    if(filter === "30days") {
      let start = moment().subtract(30, 'days');
      return new Date(start._d);
    }else if(filter==="7days") {

      let start = moment().subtract(7, 'days');

      return new Date(start._d);


    } else if (filter === "15days") {


      let start = moment().subtract(14, 'days');
      return new Date(start._d);

    } else if (filter === "90days") {

      let start = moment().subtract(90, 'days');
      return new Date(start._d);

    } else if(filter === "24hours"){

      let start = moment().add(-24, 'hours');
      return new Date(start._d);

    }
    else if(filter === "12hours"){
      let start = moment().add(-12, 'hours');
      return new Date(start._d);
    }
    else if(filter === "6hours"){
      let start = moment().add(-6, 'hours');
      return new Date(start._d);
    } else if (filter === "today") {

      return new Date();

    } else{

    }

  }
  const findEndDate = () => {
    // let currentDate = new Date();
    return (new Date());
  }

   //Fetch Export Data
  //  useEffect(()=>{
  //   handleExportData()
  //   },[])

  const handleExportData=async()=>{
    // if(exportableDays){
      fetchReadings();
      // let data={time:days,start:startDate,end:endDate}
      //  // data=JSON.stringify(data);
      //  //        data=encrypt(data);
      //         localStorage.setItem("export",data);
              document.getElementById('closebtn').click();

              history.push({
                          pathname:"/export-data",

                        });
    // }

    // //("data=",data)
    // try {
    //   setIsLoading(true);
    //   let response= await dispatch( fetchExportData(data))
    //         setIsLoading(false);
    //         data=JSON.stringify(data)
    //         data=encrypt(data)
    //         localStorage.setItem("export",data)
    //         history.push({
    //           pathname:"/export-data",

    //         });
    //         //("printing")
    // } catch (error) {
    //     setIsLoading(false);

    // }

  }



  //Handle Filters
  const handleDaysFilter = (d) => {
    setDays(d);
    setStartDate("");
    setEndDate("");
    setAllowedToShow(true);
    // if(d === "30days" || d === "90days"){
    //   setExportableDays(false);
    // }else {
    //   setExportableDays(true);
    //
    // }

  };
  //Handle Date Picker
  const handleDateChange = (val) => {
    // setCsvButton(false);

    if (val && val.length > 0) {

      setStartDate(val[1].format("YYYY-MM-DD").toString());
      setEndDate(val[0].format("YYYY-MM-DD").toString());
      // setToDate(val[1].format("YYYY-MM-DD").toString());
      // setFromDate(val[0].format("YYYY-MM-DD").toString());

    }else {
      setStartDate("");
      setEndDate("");
      // setToDate("");
      // setFromDate("");
      setAllowedToShow(false);
    }

  };
  const checkDates = ()=>{

    if(startDate !== "" && endDate !== "") {
      setDays("")
      setAllowedToShow(true)
      // let a = moment(startDate);
      // let b = moment(endDate);
      // let daysDif=b.diff(a, "days");
      // if(daysDif < 15){
      //   setExportableDays(true);
      // }else {
      //   setExportableDays(false);
      //
      // }
    }
  }
  const convert = (dataToConvert) => {
    console.log("dataToConvert", dataToConvert);
      // e.preventDefault();

      // "\uFEFF"+
    // const response = await  fetchReadings();
    // if (response && response.data && response.data.length>0){
      let dataNew =[];
      dataToConvert && dataToConvert.length>0 && dataToConvert.map((obj, index)=>{
        console.log(obj);

        let object = {"SN": index+1,
         "time": obj.time, "glucose": obj.value}

        dataNew.push(object);
        return 0;

      })

      const parsedJson = dataNew;
      if(parsedJson && parsedJson.length <1){
        return;
      }
      if (
        !Array.isArray(parsedJson) ||
        !parsedJson.every((p) => typeof p === "object" && p !== null)
      ) {
        return;
      }
      const heading = Object.keys(parsedJson[0]).join(",");
      const space = "\n"
      const body = parsedJson.map((j) => Object.values(j).join(",")).join("\n");
      // setCsv(`${heading}${space}${body}`);
      let csvData = heading + space + body;

      if(safari){
        download(csvData,"GlucoRx Modus CGM Record", 'text/csv;charset=utf-8;');

      }else{
      download("\uFEFF"+csvData,"GlucoRx Modus CGM Record", 'text/csv;charset=utf-8;');
      }

    };

    // Function to download data to a file
  const download=( processedData, filename, type)=> {
    let data = processedData;

      var file = new Blob([data], {type: type});
      if (window.navigator.msSaveOrOpenBlob) // IE10+
          window.navigator.msSaveOrOpenBlob(file, filename);
      else { // Others
          var a = document.createElement("a"),
                  url = URL.createObjectURL(file);
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          setTimeout(function() {
              document.body.removeChild(a);
              window.URL.revokeObjectURL(url);
          }, 0);
      }
  }

  return (
    <div className="row m-auto">
      <button
        type="button"
        className="p-0 pdf-btn"
        data-toggle="modal"
        data-target="#exportModal"
        style={{background: "none"}}
          // onClick={()=>history.push({
          //             pathname:"/export-data",
          //
          //           })}
      >
      <img src={PDF} className="pdf-img" style={{width:"25px"}} alt="PDF Icon"/>
      <p className="mt-1">Export</p>

        {/* <i className="fas fa-file-export"></i>*/}
      </button>


      <div
        className="modal fade"
        id="exportModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        // aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Export
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                id="closebtn"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <div className="container">
                <div className="d-flex flex-row justify-content-center">
                  <button
                    className={
                      startDate === "" && days === "7days"
                        ? "bg-common text-white rounded px-2 mr-2"
                        : " bg-light rounded px-2 mr-2"
                    }
                    onClick={() => {
                      handleDaysFilter("7days");
                      setDropMenu(false);
                      // setCsvButton(false);
                      // setOrganizedData([]);


                    }}
                  >
                    7 Days
                  </button>
                  <button
                    className={
                      startDate === "" && days === "15days"
                        ? "bg-common text-white rounded px-2 mr-2"
                        : " bg-light rounded px-2 mr-2"
                    }
                    onClick={() => {
                      handleDaysFilter("15days");
                      setDropMenu(false);
                      // setCsvButton(false);
                      // setOrganizedData([]);



                    }}
                  >
                    15 days
                  </button>
                  <button
                    className={
                      startDate === "" && days === "30days"
                        ? "bg-common text-white rounded px-2 mr-2"
                        : " bg-light rounded px-2 mr-2"
                    }
                    onClick={() => {
                      handleDaysFilter("30days");
                      setDropMenu(false);
                      // setCsvButton(false);
                      // setOrganizedData([]);



                    }}
                  >
                    30 Days
                  </button>
                  <button
                    className={
                      startDate === "" && days === "90days"
                        ? "bg-common text-white rounded px-2 mr-2"
                        : " bg-light rounded px-2 mr-2"
                    }
                    onClick={() => {
                      handleDaysFilter("90days");
                      setDropMenu(false);
                      // setCsvButton(false);
                      // setOrganizedData([]);



                    }}
                  >
                    90 Days
                  </button>
                  <button
                    className={
                      startDate !== ""
                        ? "bg-common text-white rounded px-2 dropdown"
                        : "bg-light rounded px-2 dropdown"
                    }
                    onClick={(e) => {
                      setDropMenu(!dropMenu);
                      setAllowedToShow(false);
                      setDays("");
                      // setCsvButton(false);
                      // setOrganizedData([]);




                    }}
                  >
                    Range
                  </button>
                </div>
                <div className="row text-center mt-3 justify-content-center">
                  <div className="col-12">
                    <div className={dropMenu ? " d-block" : " d-none"}>
                      <RangePicker
                        defaultValue={
                          startDate && [moment(startDate), moment(endDate)]
                        }
                        onChange={(val) => {
                          handleDateChange(val);
                          setShowButton(!showButton);
                        }}
                      />
                      <button className="bg-common text-white rounded px-2 ml-3" onClick={()=>{checkDates()}}>Go</button>
                    </div>
                  </div>
                </div>
                <div className='row justify-content-center align-items-center my-5'>
                {allowedToShow?
                  <div className='col-12'>
                     <div className='row justify-content-center pdf-device'>
                     {/*exportableDays?*/}

                      {/*  :null/}*/}
                        <div className='col-6 m-auto'>
                       <button className= "rounded px-3 mr-2 py-3"
                       // disabled={!csvButton}
                       onClick={()=>fetchReadingsForCsv()}>


                          <span className="">Export CSV</span>
                      </button>
                        </div>

                        {/*<div className='col-3'>
                            <button className={
                           patterns === true
                           ? " btnImgColor-hover bg-common text-white rounded px-3 mr-2 py-3"
                           : " btnImgColor rounded px-3 mr-2 py-3"
                         } onClick={()=>{
                             setPatterns(!patterns)
                           }}>
                              <div className="example example-cover row">
                              <div className="col-12">
                              <img src={paterns} size="20px"></img>
                              </div>
                              <div className="col-12">

                               <span className="d-none d-md-inline">Patterns</span>
                               </div>
                             </div>
                           </button>
                        </div>

                        <div className='col-3'>
                           <button className={
                          trends === true
                          ? " btnImgColor-hover bg-common text-white rounded px-3 mr-2 py-3"
                          : " btnImgColor rounded px-3 mr-2 py-3"
                        } onClick={()=>{
                            setTrends(!trends)
                          }}>
                             <div className="example example-cover row">
                             <div className="col-12">
                             <img src={trnds} size="20px"></img>
                             </div>
                             <div className="col-12">

                              <span className="d-none d-md-inline">Trends</span>
                              </div>
                            </div>
                          </button>
                        </div>

                        <div className='col-3'>
                          <button className={
                         overlay === true
                         ? " btnImgColor-hover bg-common text-white rounded px-3 mr-2 py-3"
                         : " btnImgColor rounded px-3 mr-2 py-3"
                       } onClick={()=>{
                           setOverlay(!overlay)
                         }}>
                            <div className="example example-cover row">
                            <div className="col-12">
                            <img src={ovrlays} size="20px"></img>
                            </div>
                            <div className="col-12">

                             <span className="d-none d-md-inline">Overlays</span>
                             </div>
                           </div>
                         </button>
                        </div>

                        <div className='col-3'>
                         <button className={
                        historyTab === true
                        ? " btnImgColor-hover bg-common text-white rounded px-3 mr-2 py-3"
                        : " btnImgColor rounded px-3 mr-2 py-3"
                      } onClick={()=>{
                          setHistoryTab(!historyTab)
                        }}>
                           <div className="example example-cover row">
                           <div className="col-12">
                           <img src={histry} size="20px"></img>
                           </div>
                           <div className="col-12">

                            <span className="d-none d-md-inline">History</span>
                            </div>
                          </div>
                        </button>
                        </div>

                        <div className='col-3'>
                          <button className={
                         compare === true
                         ? " btnImgColor-hover bg-common text-white rounded px-3 mr-2 py-3"
                         : " btnImgColor rounded px-3 mr-2 py-3"
                       } onClick={()=>{
                           setCompare(!compare)
                         }}>
                            <div className="example example-cover row">
                            <div className="col-12">
                            <img src={compre} size="20px"></img>
                            </div>
                            <div className="col-12">

                             <span className="d-none d-md-inline">Compare</span>
                             </div>
                           </div>
                         </button>
                        </div>

                        <div className='col-3'>
                         <button className={
                        statistics === true
                        ? " btnImgColor-hover bg-common text-white rounded px-3 mr-2 py-3"
                        : " btnImgColor rounded px-3 mr-2 py-3"
                      } onClick={()=>{
                          setStatistics(!statistics)
                        }}>
                           <div className="example example-cover row">
                           <div className="col-12">
                           <img src={statistcs} size="20px"></img>
                           </div>
                           <div className="col-12">

                            <span className="d-none d-md-inline">Statistics</span>
                            </div>
                          </div>
                        </button>
                        </div>

                        <div className='col-3'>
                        <button className={
                       agp === true
                       ? " btnImgColor-hover bg-common text-white rounded px-3 mr-2 py-3"
                       : " btnImgColor rounded px-3 mr-2 py-3"
                     } onClick={()=>{
                         setAgp(!agp)
                       }}>
                          <div className="example example-cover row">
                          <div className="col-12">
                          <img src={Agp} size="20px"></img>
                          </div>
                          <div className="col-12">

                           <span className="d-none d-md-inline">Agp</span>
                           </div>
                         </div>
                       </button>
                       </div>*/}
                     </div>
                  </div>
                  :null }
                </div>
              </div>
            </div>
            <div className="modal-footer">
              {/* exportableDays
              ?
                <div>
                <Spin spinning={isLoading}>
                <button type="button" className="btn btn-primary" onClick={handleExportData}>
                Export
                </button>
                </Spin>
                </div>:null*/}


      </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExportData;
