import React,{useEffect,useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {updateUserRole} from "../../redux/actions/user";
import DocumentTitles from "../../utils/document-titles";



const RoleModal = (props) => {
  document.title = DocumentTitles.dashboard;
  const [editModal, setEditModal] = useState(true);
  const [showSave, setShowSave] = useState(false);
  const [selectedRole, setSelectedRole] = useState([]);
  const {user} = useSelector((state)=> {return state.user.userDetails});
  const {role} = useSelector((state)=> {return state.user.userDetails.user});

  const dispatch = useDispatch();
  // // console.log("Roles :", role);



  const handleRole =(event)=>{
    // // console.log(event.target.name);
    if(event.target.name=== "56"){
      let mulRol = [5,6];
      setSelectedRole(mulRol);

    } else {
      let val = parseInt(event.target.name);
      // // console.log("val", val);
      let singRol = [val]
      setSelectedRole(singRol);

    }


      setShowSave(true);



  }
  const updateRole=()=>{
    // // console.log("selectedRole", selectedRole);
    let rol = selectedRole;
    let usr = {



    address:user.address,
    app:user.app,
    clinicalId:user.clinicalId,
    country:user.country,
    dateOfBirth:user.dateOfBirth,
    diabetesType: user.diabetesType,
    email: user.email,
    firstName: user.firstName,
    gender:user.gender,
    hmac: user.hmac,
    lastName:user.lastName,
    phone:user.phone,
    picture:user.picture,
    postcode:user.postcode,
    role: rol,
    status:user.status,
    subjectId:user.subjectId,
    town:user.town,
    low:user.low,
    high: user.high,
    unit:user.unit,
};
dispatch(updateUserRole({user:usr}));
setEditModal(false);

  }
    return (
      <div className="row">
      <div className="col-12">
      </div>
      <div className={editModal? "modal d-block fading" : "modal d-none"}>
          <div className="modal-dialog modal-dialog-centered modal-confirm">
            <div className="modal-content">
              <div className="modal-header flex-column">
                <h4 className="modal-heading w-100 text-black">Choose Dashboard</h4>
                {/*<button type="button" className="close" onClick={()=>{setEditModal(false)}} >&times;</button>*/}
              </div>
              <div className="modal-body text-center">

              {role.includes(0)?

              <button name="0" type="button" onClick={(event)=>{handleRole(event)}} className={selectedRole.includes(0)=== true?"active btn btn-primary ml-3 role-btn":"btn btn-secondary ml-3 role-btn"}>Admin</button>
              :null}


              {role.includes(1)?

              <button name="1" type="button" onClick={(event)=>{handleRole(event)}} className={selectedRole.includes(1)=== true?"active btn btn-primary ml-3 role-btn":"btn btn-secondary ml-3 role-btn"}>Admin</button>
              :null}

              {role.includes(4)?

              <button name="4" type="button" onClick={(event)=>{handleRole(event)}} className={selectedRole.includes(4)=== true?"active btn btn-primary ml-3 role-btn":"btn btn-secondary ml-3 role-btn"}>Patient</button>
              :null}
              {role.includes(5) === true && role.includes(6) === false && role.includes(1) === false?

              <button name="5" type="button" onClick={(event)=>{handleRole(event)}} className={selectedRole.includes(5)=== true?"active btn btn-primary ml-3 role-btn":"btn btn-secondary ml-3 role-btn"}>Admin</button>
              :null}
              { role.includes(5) === true && role.includes(6) === true && role.includes(1) === false?

              <button name="56" type="button" onClick={(event)=>{handleRole(event)}} className={selectedRole.includes(5)=== true?"active btn btn-primary ml-3 role-btn":"btn btn-secondary ml-3 role-btn"}>Admin</button>
              :null}

              { role.includes(5) === false && role.includes(6) === true && role.includes(1) === false?

              <button name="6" type="button" onClick={(event)=>{handleRole(event)}} className={selectedRole.includes(6)=== true?"active btn btn-primary ml-3 role-btn":"btn btn-secondary ml-3 role-btn"}>Admin</button>
              :null}


              </div>
              <div className="modal-footer justify-content-center">
                {showSave?<button type="button" className="btn btn-secondary" onClick={()=>updateRole()}>Select</button>: null}

              </div>
            </div>
          </div>

      </div>
      </div>

    );
};

export default RoleModal;
