import React, { Component } from "react";
import RegistrationComp from "../Components/Auth/RegitrationComp";

class RegistrationPage extends Component {
  render() {
    return (
      <div>
       <RegistrationComp/>
      </div>
    );
  }
}

export default RegistrationPage;
