
import React, { useEffect } from "react";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


am4core.useTheme(am4themes_animated);
// Themes end
function TimeInRangeExpo(props) {
  // let chart;
  useEffect(() => {


    let chart = am4core.create(props.chartId, am4charts.PieChart);
    chart.hiddenState.properties.opacity = 0;
    chart.data = [{
        "country": "Lithuania",
        "litres": 501.9
      }, {
        "country": "Czech Republic",
        "litres": 301.9
      }, {
        "country": "Ireland",
        "litres": 201.1
      }, {
        "country": "Germany",
        "litres": 165.8
      }, {
        "country": "Australia",
        "litres": 139.9
      }, {
        "country": "Austria",
        "litres": 128.3
      }, {
        "country": "UK",
        "litres": 99,
        "hidden": true
      }, {
        "country": "Belgium",
        "litres": 60,
        "hidden": true
      }, {
        "country": "The Netherlands",
        "litres": 50,
        "hidden": true
      }];

   // Add and configure Series
var pieSeries = chart.series.push(new am4charts.PieSeries());
pieSeries.dataFields.value = "litres";
pieSeries.dataFields.category = "country";
pieSeries.dataFields.hidden = "hidden";

// Let's cut a hole in our Pie chart the size of 40% the radius
chart.innerRadius = am4core.percent(40);

// Disable ticks and labels
pieSeries.labels.template.disabled = true;
pieSeries.ticks.template.disabled = true;

// Disable tooltips
pieSeries.slices.template.tooltipText = "";

// Add a legend
chart.legend = new am4charts.Legend();
chart.legend.position = "right";
chart.legend.maxWidth = 120;
var marker = chart.legend.markers.template.children.getIndex(0);
marker.cornerRadius(12, 12, 12, 12);
marker.strokeWidth = 2;
marker.strokeOpacity = 1;
marker.stroke = am4core.color("#ccc");


}, []);

  return (<div className="">

        <div id={props.chartId} style={{ width: "100%", minHeight: "200px" }}></div>
  </div>
  );
}
export default TimeInRangeExpo;
