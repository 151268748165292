import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";

function GeographicChart(props) {
  const { countriesPercentage } = useSelector((state) => state.analytics);
  console.log("countriesPercentage", countriesPercentage);
  useEffect(() => {

    // Your data
    const data = [
      { country: 'Pakistan', totalUsers: 188, percentage: '75.81%' },
      { country: 'United Kingdom', totalUsers: 35, percentage: '14.11%' },
      { country: 'United States', totalUsers: 24, percentage: '9.68%' }
    ];

    // Create map instance
    var chart = am4core.create("map", am4maps.MapChart);

    // Set map definition
    chart.geodata = am4geodata_worldLow;

    // Set projection
    chart.projection = new am4maps.projections.Miller();

    // Create map polygon series
    var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());

    // Make map load polygon (like country names) data from GeoJSON
    polygonSeries.useGeodata = true;

    // Add custom data for highlighted countries
    polygonSeries.data =countriesPercentage && countriesPercentage.length>0 && countriesPercentage.map(item => ({
      id: countryToId(item.country),
      name: item.country,
      value: item.totalUsers
    }));

    // Configure series
    var polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.tooltipText = "{name}: {value} users";
    polygonTemplate.fill = am4core.color("#0d53b8");

    // Create hover state and set alternative fill color
    var hs = polygonTemplate.states.create("hover");
    hs.properties.fill = am4core.color("#367B25");

    // Highlight specific countries
    polygonTemplate.propertyFields.fill = "fill";

    // Add color to highlighted countries
    polygonSeries.data.forEach(country => {
      country.fill = am4core.color("#ff0000"); // Highlight color
    });

    return () => {
      chart.dispose();
    };
  }, [countriesPercentage]);

  // Helper function to convert country name to ISO 3166-1 alpha-2 code
  const countryToId = (country) => {
    const map = {
      "Afghanistan": "AF",
          "Albania": "AL",
          "Algeria": "DZ",
          "Andorra": "AD",
          "Angola": "AO",
          "Antigua and Barbuda": "AG",
          "Argentina": "AR",
          "Armenia": "AM",
          "Australia": "AU",
          "Austria": "AT",
          "Azerbaijan": "AZ",
          "Bahamas": "BS",
          "Bahrain": "BH",
          "Bangladesh": "BD",
          "Barbados": "BB",
          "Belarus": "BY",
          "Belgium": "BE",
          "Belize": "BZ",
          "Benin": "BJ",
          "Bhutan": "BT",
          "Bolivia": "BO",
          "Bosnia and Herzegovina": "BA",
          "Botswana": "BW",
          "Brazil": "BR",
          "Brunei Darussalam": "BN",
          "Bulgaria": "BG",
          "Burkina Faso": "BF",
          "Burundi": "BI",
          "Cabo Verde": "CV",
          "Cambodia": "KH",
          "Cameroon": "CM",
          "Canada": "CA",
          "Central African Republic": "CF",
          "Chad": "TD",
          "Chile": "CL",
          "China": "CN",
          "Colombia": "CO",
          "Comoros": "KM",
          "Congo": "CG",
          "Costa Rica": "CR",
          "Croatia": "HR",
          "Cuba": "CU",
          "Cyprus": "CY",
          "Czechia": "CZ",
          "Denmark": "DK",
          "Djibouti": "DJ",
          "Dominica": "DM",
          "Dominican Republic": "DO",
          "Ecuador": "EC",
          "Egypt": "EG",
          "El Salvador": "SV",
          "Equatorial Guinea": "GQ",
          "Eritrea": "ER",
          "Estonia": "EE",
          "Eswatini": "SZ",
          "Ethiopia": "ET",
          "Fiji": "FJ",
          "Finland": "FI",
          "France": "FR",
          "Gabon": "GA",
          "Gambia": "GM",
          "Georgia": "GE",
          "Germany": "DE",
          "Ghana": "GH",
          "Greece": "GR",
          "Grenada": "GD",
          "Guatemala": "GT",
          "Guinea": "GN",
          "Guinea-Bissau": "GW",
          "Guyana": "GY",
          "Haiti": "HT",
          "Honduras": "HN",
          "Hungary": "HU",
          "Iceland": "IS",
          "India": "IN",
          "Indonesia": "ID",
          "Iran": "IR",
          "Iraq": "IQ",
          "Ireland": "IE",
          "Israel": "IL",
          "Italy": "IT",
          "Jamaica": "JM",
          "Japan": "JP",
          "Jordan": "JO",
          "Kazakhstan": "KZ",
          "Kenya": "KE",
          "Kiribati": "KI",
          "Kuwait": "KW",
          "Kyrgyzstan": "KG",
          "Lao People's Democratic Republic": "LA",
          "Latvia": "LV",
          "Lebanon": "LB",
          "Lesotho": "LS",
          "Liberia": "LR",
          "Libya": "LY",
          "Liechtenstein": "LI",
          "Lithuania": "LT",
          "Luxembourg": "LU",
          "Madagascar": "MG",
          "Malawi": "MW",
          "Malaysia": "MY",
          "Maldives": "MV",
          "Mali": "ML",
          "Malta": "MT",
          "Marshall Islands": "MH",
          "Mauritania": "MR",
          "Mauritius": "MU",
          "Mexico": "MX",
          "Micronesia (Federated States of)": "FM",
          "Moldova (Republic of)": "MD",
          "Monaco": "MC",
          "Mongolia": "MN",
          "Montenegro": "ME",
          "Morocco": "MA",
          "Mozambique": "MZ",
          "Myanmar": "MM",
          "Namibia": "NA",
          "Nauru": "NR",
          "Nepal": "NP",
          "Netherlands": "NL",
          "New Zealand": "NZ",
          "Nicaragua": "NI",
          "Niger": "NE",
          "Nigeria": "NG",
          "North Macedonia": "MK",
          "Norway": "NO",
          "Oman": "OM",
          "Pakistan": "PK",
          "Palau": "PW",
          "Panama": "PA",
          "Papua New Guinea": "PG",
          "Paraguay": "PY",
          "Peru": "PE",
          "Philippines": "PH",
          "Poland": "PL",
          "Portugal": "PT",
          "Qatar": "QA",
          "Republic of Korea": "KR",
          "Republic of Moldova": "MD",
          "Romania": "RO",
          "Russian Federation": "RU",
          "Rwanda": "RW",
          "Saint Kitts and Nevis": "KN",
          "Saint Lucia": "LC",
          "Saint Vincent and the Grenadines": "VC",
          "Samoa": "WS",
          "San Marino": "SM",
          "Sao Tome and Principe": "ST",
          "Saudi Arabia": "SA",
          "Senegal": "SN",
          "Serbia": "RS",
          "Seychelles": "SC",
          "Sierra Leone": "SL",
          "Singapore": "SG",
          "Slovakia": "SK",
          "Slovenia": "SI",
          "Solomon Islands": "SB",
          "Somalia": "SO",
          "South Africa": "ZA",
          "Spain": "ES",
          "Sri Lanka": "LK",
          "Sudan": "SD",
          "Suriname": "SR",
          "Sweden": "SE",
          "Switzerland": "CH",
          "Syrian Arab Republic": "SY",
          "Tajikistan": "TJ",
          "Thailand": "TH",
          "Timor-Leste": "TL",
          "Togo": "TG",
          "Tonga": "TO",
          "Trinidad and Tobago": "TT",
          "Tunisia": "TN",
          "Turkey": "TR",
          "Turkmenistan": "TM",
          "Tuvalu": "TV",
          "Uganda": "UG",
          "Ukraine": "UA",
          "United Arab Emirates": "AE",
          "United Kingdom": "GB",
          "United Republic of Tanzania": "TZ",
          "United States": "US",
          "Uruguay": "UY",
          "Uzbekistan": "UZ",
          "Vanuatu": "VU",
          "Venezuela (Bolivarian Republic of)": "VE",
          "Viet Nam": "VN",
          "Yemen": "YE",
          "Zambia": "ZM",
          "Zimbabwe": "ZW"
    };
    return map[country];
  };

  return (
    <div id="map" style={{ height: "94%" }}></div>
  );
}

export default GeographicChart;
