import React from 'react';
import ResendVerificationEmail from '../Components/Auth/ResendVerificationEmail';

const ResendVerificationEmailPage = () => {
    return (
        <div>
            <ResendVerificationEmail/>
        </div>
    );
};

export default ResendVerificationEmailPage;