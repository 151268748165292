import React from 'react';
import { useDispatch, useSelector} from "react-redux";
import CompareComp from '../Components/Reports/CompareComp';


const ComparePage = () => {
    const userData = useSelector((state) => state.user);

    return (



            <div className="row h-100 bg-light rounded mx-1 pl-md-2 pl-0 py-2">

        <main className="col  bg-faded ">
       <CompareComp/>
         </main>
    </div>

    );
};

export default ComparePage;
