import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
 import { useNavigate, useLocation } from "react-router-dom";

import { Tabs } from "antd";
import CompareOverlay from "./Compare/CompareOverlay";
import CompareHistory from "./Compare/CompareHistory";




const CompareComp = () => {
const { TabPane } = Tabs;
    return (
      <div className="container-fluid bg-white rounded shadow ">



        <div className="row mw-100 m-auto">
        <div className="col-12">
        <div className="row  mr-sm-0 sticky-top">
          <div className="col-12 mt-md-0 mt-2"  style={{ height: "100%" }}>
            <div className="" style={{ height: "100%" }}>
              {/*<OverlayFilter/>*/}
              <div className="sticky-top">
                <div className="d-flex justify-content-between mb-2" style={{height:"80px"}}>
                  <div className="chart-heading mt-3 loginNew-HCP-panel">
                    <span className="bg-primary px-2 py-2 font-size2 font-weight-bold text-white" >
                      Compare
                    </span>

                  </div>

                </div>

              </div>
              </div>
            </div>
          </div>
        </div>
          <div className="col-12">
          <div className="bg-white p-3 rounded-lg">

            <Tabs type="card">

              <TabPane tab="Overlay" key="1">
                <CompareOverlay />
              </TabPane>
              <TabPane tab="History" key="2">
                <CompareHistory />
              </TabPane>

            </Tabs>

          </div>
          </div>

        </div>
      </div>
    );
};

export default CompareComp;
