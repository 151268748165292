import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
// import { sendFeedback } from "../../redux/actions/feedback";
import { default as Titles } from "../../utils/document-titles";

function Feedback({ user, dispatch }) {
  const [state, setState] = useState({
    name: "",
    email: "",
    feedback: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    document.title = Titles.contactUs;

    if (user && user.userDetails !== null) {
      setState((prevState) => ({
        ...prevState,
        name: user.name,
        email: user.email,
      }));
    }
  }, [user]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmitForm = async (event) => {
    event.preventDefault();

    let res = await dispatch(({ message: state.feedback }));

    if (res.status === 200) {
      document.getElementById("feedback").value = "";
    }
  };

  return (
    <div className="container my-5 h-75 feedback">
      <form autoComplete="off" className="h-100" onSubmit={handleSubmitForm}>
        <span className="text-center mb-4 mt-5 "></span>
        <fieldset className="h-100">
          <div className="row h-100">
            <div className="col-12 col-md-8 m-auto">
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <div className="input-group ">
                      <input
                        name="name"
                        placeholder="Full Name"
                        className="form-control input-md msg"
                        type="text"
                        value={state.name}
                        required
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <div className="input-group ">
                      <input
                        name="email"
                        placeholder="Email"
                        className="form-control input-md msg"
                        type="email"
                        defaultValue={state.email}
                        required
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <div className="input-group ">
                      <textarea
                        name="feedback"
                        id="feedback"
                        rows="16"
                        placeholder="Enter Your Valuable Feedback Here ..."
                        className="form-control feedback"
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                </div>
                <button type="submit" className="add-info-btn mx-1">
                  Send Feedback
                </button>
              </div>
            </div>
          </div>
        </fieldset>
      </form>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user.userDetails,
  };
}

export default connect(mapStateToProps)(Feedback);
