import React, { useState, useEffect, useTransition } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCliniciansSubjects, getCliniciansSubjectsFailed, removeFromClinicalTrial } from "../../../redux/actions/admin/admin";
import Loading from "../../Common/Loading";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { default as Titles } from "../../../utils/document-titles";

// import {encrypt,decrypt} from "../../../utils/encryption";



const CliniciansSubjects = (props) => {
  document.title = Titles.trialsSubjects;


  const dispatch = useDispatch();
  const history = useNavigate();
  const location = useLocation();
  const { sId } = useParams();
  let shortId = sId;
  shortId = shortId.replace(":", "");
  // const [role, setRole] = useState("patient");
  const { userDetails } = useSelector((state) => { return state.user });

  const list = useSelector((state) => { return (state.admin.cliniciansSubjects) });
  const [isPending, startTransition] = useTransition();



  const [toDelete, setToDelete] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);




  const [subToStop, setSubToStop] = useState("");






  useEffect(() => {
    fetchClinicians();

    return () => {
      console.log('Component will unmount');
      // Call your cleanup function here
      dispatch(getCliniciansSubjectsFailed());
    };
  }, []);


  const fetchClinicians = async () => {


    startTransition(() => {
      dispatch(getCliniciansSubjects(shortId));
    });

  };

  const saveId = async (id, subId) => {

    history(`/admin/CliniciansSessionsData/:${id}/:${subId}/:${shortId}`);



  };

  const removingSubjectFromClinicalTrial = async () => {
    let id = toDelete;
    let result = await dispatch(removeFromClinicalTrial(id));
    if (result === true) {
      fetchClinicians();
      setDeleteModal(false);


    }
  }

  if (userDetails &&
    userDetails.user && (userDetails.user.role.includes(6) === true || userDetails.user.role.includes(1) === true || userDetails.user.role.includes(0) === true)) {

    return (
      <div className="container-fluid patient-list">
        <div className="row p-2 px-3">
          <div className="col-12">
            <div className="row justify-content-between pl-3">
              <div className="col-12">
                <h1 className="pt-3 pb-3 font-weight-bold chart-heading">Subject's Details</h1>
                <h3 className="pt-3 pb-3 font-weight-bold chart-heading">You are viewing the subjects of  "{shortId}".</h3>
              </div>
              <div className="col-12">
                <table className="table table-hover" id="task-table">
                  <thead>
                    <tr>
                      <th>Subject ID</th>
                      <th>P-ID</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  {isPending ? <Loading /> :
                    <tbody>
                      {list !== null && list.length > 0 && list.map((row, index) => (
                        <tr>
                          <td>{row.subjectId}</td>
                          <td>{row.pId}</td>
                          <td>{row.clinicalId === "stopped" ? <span style={{ backgroundColor: "rgba(0,0,0,.35)", padding: "2%", borderRadius: "10px" }}>Stopped</span> : <span>In Progress</span>}</td>
                          <td>
                            <button
                              type="button"
                              className=""
                              title="View"
                              onClick={(event) => {
                                saveId(row._id, row.subjectId);
                              }}
                            >
                              <span className="">
                                <i className="fa fa-eye" aria-hidden="true"></i>
                              </span>
                            </button>
                            {row.clinicalId !== "stopped" ?
                              <button
                                type="button"
                                className="ml-2"
                                title="Stop Clinical Trial"

                                onClick={(event) => {
                                  setSubToStop(row.subjectId);
                                  setToDelete(row.subjectId);
                                  setDeleteModal(true);
                                }}
                              >
                                <span className="" >
                                  <i className="fa fa-times" aria-hidden="true"></i>

                                </span>
                              </button>
                              : null}
                          </td>
                        </tr>
                      ))}
                    </tbody>}
                </table>
              </div>
              <div
                tabIndex="-1"
                id="myModal-delete"
                className={`modal  ${deleteModal ? "d-block fading" : " d-none"
                  }`}
              >
                <div className="modal-dialog modal-dialog-centered modal-confirm">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="icon-box mr-2">
                        <i className="fas fa-trash-alt text-danger"></i>
                      </div>
                      <h4 className="modal-heading w-100 text-black">
                        Are you sure?
                      </h4>
                      <button
                        type="button"
                        className="close"
                        onClick={() => setDeleteModal(false)}
                      >
                        &times;
                      </button>
                    </div>
                    <div className="modal-body">
                      <p>
                        Do you really want to stop this "{subToStop}" subject? This
                        process cannot be undone.
                      </p>
                    </div>
                    <div className="modal-footer justify-content-center">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => setDeleteModal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => {
                          removingSubjectFromClinicalTrial();
                        }}
                      >
                        Stop
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  } else if(userDetails &&
    userDetails.user && (userDetails.user.role.includes(6) !== true || userDetails.user.role.includes(1) !== true || userDetails.user.role.includes(0) !== true)){
<h1 className="text-center" style={{ height: "85vh" }}>Page not found.</h1>
  }

  return (
    <div className="text-center" style={{ height: "85vh" }}>
      <Loading />
    </div>

  );
};

export default CliniciansSubjects;
