import React,{useEffect} from "react";
import { NavLink } from "react-router-dom";
import overview from "../../Assets/Sidebar-Icons/overview.svg";
import patterns from "../../Assets/Sidebar-Icons/patterns.svg";
import trends from "../../Assets/Sidebar-Icons/trends.svg";
import overlays from "../../Assets/Sidebar-Icons/overlays.svg";
import history from "../../Assets/Sidebar-Icons/history.svg";
import compare from "../../Assets/Sidebar-Icons/compare.svg";
import statistics from "../../Assets/Sidebar-Icons/statistics.svg";
import Agp from "../../Assets/Sidebar-Icons/Agp.svg";
import { useLocation } from 'react-router-dom'


const Sidebar = () => {
    const location = useLocation();

  return (

    <nav className="navbar sticky-top navbar-expand navbar-primary bg-primary flex-md-column flex-row align-items-start py-2 px-2 side-bar">
    <div className="collapse navbar-collapse align-items-start w-100">
        <ul className="flex-md-column flex-row navbar-nav w-100 justify-content-between text-white">

            <li className="nav-item">
                <NavLink to="/Overview"  className={location.pathname==="/Overview" || location.pathname==="/dashboard" ? "is-active nav-link  pt-2 pb-2" : "nav-link  pt-2 pb-2"} ><img src={overview} size="20px" alt="sidenav-icon" /> <span className="d-none d-md-inline  ml-3">Overview</span></NavLink>
            </li>
            {/*<li className="nav-item">
                <NavLink to="/Pattern"  className={location.pathname==="/Pattern"?"is-active nav-link  pt-2 pb-2" : "nav-link  pt-2 pb-2"} ><img src={patterns} size="20px" alt="sidenav-icon" /> <span className="d-none d-md-inline ml-3">Patterns</span></NavLink>
            </li>
            <li className="nav-item">
                <NavLink to="/Trend" className={location.pathname==="/Trend"?"is-active nav-link  pt-2 pb-2" : "nav-link  pt-2 pb-2"} ><img src={trends} size="20px" alt="sidenav-icon" /> <span className="d-none d-md-inline ml-3">Trends</span></NavLink>
            </li>*/}
            <li className="nav-item">
                <NavLink to="/Overlay" className={location.pathname==='/Overlay'?"is-active nav-link  pt-2 pb-2" : "nav-link  pt-2 pb-2"} ><img src={overlays} size="20px" alt="sidenav-icon" /> <span className="d-none d-md-inline ml-3">Overlays</span></NavLink>
            </li>
            <li className="nav-item">
                <NavLink to="/History" className={location.pathname==="/History"?"is-active nav-link  pt-2 pb-2" : "nav-link  pt-2 pb-2"} ><img src={history} size="20px" alt="sidenav-icon" /><span className="d-none d-md-inline ml-3">History</span></NavLink>
            </li>
            <li className="nav-item">
                <NavLink to="/Compare" className={location.pathname==="/Compare"?"is-active nav-link  pt-2 pb-2" : "nav-link  pt-2 pb-2"} ><img src={compare} size="20px" alt="sidenav-icon" /> <span className="d-none d-md-inline ml-3">Compare</span></NavLink>
            </li>
            {/*<li className="nav-item">
                <NavLink to="/Statistics" className={location.pathname==="/Statistics"?"is-active nav-link  pt-2 pb-2" : "nav-link  pt-2 pb-2"} ><img src={statistics} size="20px" alt="sidenav-icon" /><span className="d-none d-md-inline ml-3">Statistics</span></NavLink>
            </li>*/}
            <li className="nav-item">
                <NavLink to="/Ogp" className={location.pathname==="/Ogp"?"is-active nav-link  pt-2 pb-2" : "nav-link  pt-2 pb-2"} ><img src={Agp} size="20px" alt="sidenav-icon"/><span className="d-none d-md-inline ml-3">Ogp</span></NavLink>
            </li>

            {/*<li className="nav-item">
                <NavLink to="/cgm" className={location.pathname==="/cgm"?"is-active nav-link  pt-2 pb-2" : "nav-link  pt-2 pb-2"} ><img src={Agp} size="20px" alt="sidenav-icon"/><span className="d-none d-md-inline ml-3">CGM Report</span></NavLink>
            </li>*/}

        </ul>
    </div>
</nav>
  );
};

export default Sidebar;
