





import React, { useEffect, useState } from "react";
import {useSelector} from "react-redux";
import moment from "moment";
import Loading from '../../Common/Loading';
import * as _ from "lodash";


import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


am4core.useTheme(am4themes_animated);

function TestHistory(props) {
  console.log("PROPS HISTORY :", props);

  const [showGraph, setShowGraph]=useState(false);
  const {user, settings} = useSelector((state)=>{return state});

  useEffect(() => {

let chart = am4core.create(props.chartId, am4charts.XYChart);

let dataForChart =[
    {
        "date": "2024-01-31T07:35:43.368Z",
        "reading": 4.32
    },
    {
        "date": "2024-01-31T07:36:43.368Z",
        "reading": 4.28
    },
    {
        "date": "2024-01-31T07:37:43.368Z",
        "reading": 4.27
    },
    {
        "date": "2024-01-31T07:38:43.368Z",
        "reading": 4.32
    },
    {
        "date": "2024-01-31T07:39:43.368Z",
        "reading": 4.22
    },
    {
        "date": "2024-01-31T07:40:43.368Z",
        "reading": 4.31
    },
    {
        "date": "2024-01-31T07:41:43.368Z",
        "reading": 4.19
    },
    {
        "date": "2024-01-31T07:42:43.368Z",
        "reading": 4.19
    },
    {
        "date": "2024-01-31T07:43:43.368Z",
        "reading": 4.26
    },
    {
        "date": "2024-01-31T07:44:43.368Z",
        "reading": 4.35
    },
    {
        "date": "2024-01-31T07:45:43.368Z",
        "reading": 4.25
    },
    {
        "date": "2024-01-31T07:46:43.368Z",
        "reading": 4.2
    },
    {
        "date": "2024-01-31T07:47:43.368Z",
        "reading": 4.29
    },
    {
        "date": "2024-01-31T07:48:43.368Z",
        "reading": 4.34
    },
    {
        "date": "2024-01-31T07:49:43.368Z",
        "reading": 4.14
    },
    {
        "date": "2024-01-31T07:50:43.368Z",
        "reading": 4.14
    },
    {
        "date": "2024-01-31T07:51:43.368Z",
        "reading": 4.16
    },
    {
        "date": "2024-01-31T07:52:43.368Z",
        "reading": 4.36
    },
    {
        "date": "2024-01-31T07:53:43.368Z",
        "reading": 4.17
    },
    {
        "date": "2024-01-31T07:54:43.368Z",
        "reading": 4.33
    },
    {
        "date": "2024-01-31T07:55:43.368Z",
        "reading": 4.28
    },
    {
        "date": "2024-01-31T07:56:43.368Z",
        "reading": 4.24
    },
    {
        "date": "2024-01-31T07:57:43.368Z",
        "reading": 4.32
    },
    {
        "date": "2024-01-31T07:58:43.368Z",
        "reading": 4.26
    },
    {
        "date": "2024-01-31T07:59:43.368Z",
        "reading": 4.19
    },
    {
        "date": "2024-01-31T08:00:43.368Z",
        "reading": 4.19
    },
    {
        "date": "2024-01-31T08:01:43.368Z",
        "reading": 4.14
    },
    {
        "date": "2024-01-31T08:02:43.368Z",
        "reading": 4.28
    },
    {
        "date": "2024-01-31T08:03:43.368Z",
        "reading": 4.15
    },
    {
        "date": "2024-01-31T08:04:43.368Z",
        "reading": 4.34
    },
    {
        "date": "2024-01-31T08:05:43.368Z",
        "reading": 4.29
    },
    {
        "date": "2024-01-31T08:06:43.368Z",
        "reading": 4.22
    },
    {
        "date": "2024-01-31T08:07:43.368Z",
        "reading": 4.24
    },
    {
        "date": "2024-01-31T08:08:43.368Z",
        "reading": 4.14
    },
    {
        "date": "2024-01-31T08:09:43.368Z",
        "reading": 4.33
    },
    {
        "date": "2024-01-31T08:10:43.368Z",
        "reading": 4.35
    },
    {
        "date": "2024-01-31T08:11:43.368Z",
        "reading": 4.15
    },
    {
        "date": "2024-01-31T08:12:43.368Z",
        "reading": 4.11
    },
    {
        "date": "2024-01-31T08:13:43.368Z",
        "reading": 4.26
    },
    {
        "date": "2024-01-31T08:14:43.368Z",
        "reading": 4.28
    },
    {
        "date": "2024-01-31T08:15:43.368Z",
        "reading": 4.21
    },
    {
        "date": "2024-01-31T08:16:43.368Z",
        "reading": 4.35
    },
    {
        "date": "2024-01-31T08:17:43.368Z",
        "reading": 4.16
    },
    {
        "date": "2024-01-31T08:18:43.368Z",
        "reading": 4.11
    },
    {
        "date": "2024-01-31T08:19:43.368Z",
        "reading": 4.28
    }
];
 dataForChart = dataForChart.map(record => {
  // let color= record.glucose>=user.userDetails.user.high? "#f38f20": record.glucose<= user.userDetails.user.low ? "#d61a00": "#2a93fc";
// console.log("Settings", settings);
  return {
    time: new Date(record.date),
    glucose: record.reading,
    time2: moment(record.date).format("HH:mm"),
    // color: color
  }
});
console.log("dataForTestChart", dataForChart);
// dataForChart = transformHistoricalReadings(dataForChart);
chart.data = dataForChart;

// let dataLength = chart.data.length - 1;
// let startTime = chart.data && chart.data.length>0?chart.data[0].time: null;
// let endTime = chart.data && chart.data.length>0?chart.data[dataLength].time:null;

chart.dateFormatter.dateFormat = "yyyy-MM-dd";
  // Create axes
var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
dateAxis.startLocation = 0.5;
// dateAxis.endLocation = 0.5;
dateAxis.dateFormatter = new am4core.DateFormatter();
dateAxis.dateFormatter.dateFormat = "YYYY-dd";
dateAxis.cursorTooltipEnabled = false;

// dateAxis.zoomToDates(new Date(startTime), new Date(endTime));



// Create value axis
var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
valueAxis.min = 0;
valueAxis.title.text = user.userDetails && user.userDetails.user && user.userDetails.user.unit === 0?"mmol/L":"mgdl";
valueAxis.cursorTooltipEnabled = false;





// chart.tapToActivate = true;
// chart.tapTimeout = 5000;
// chart.dragGrip.disabled = false;
// Create axes
// var dateAxis = chart.xAxes.push(new am4charts.DateAxis());

// dateAxis.renderer.grid.template.location = 0;
// dateAxis.renderer.minGridDistance = 30;


// Create series

let lineSeries = chart.series.push(new am4charts.LineSeries());
lineSeries.dataFields.valueY = "glucose";
lineSeries.dataFields.dateX = "time";
// lineSeries.showTooltipOn = "always"
// lineSeries.tooltipText = "X: {valueX} / Y: {valueY}";

// lineSeries.name = "Sales";
lineSeries.strokeWidth = 0;
lineSeries.fillOpacity = 0;

lineSeries.sequencedInterpolation = false;

let bullet = lineSeries.bullets.push(new am4core.Circle());
    bullet.stroke = am4core.color("#fff");
    bullet.radius = 4;
    bullet.propertyFields.fill = "color";

    // bullet.fill = ("#2a93fc");
    bullet.tooltipText = "Date: {dateX} \n Time: {time2} \n Reading: {valueY} mmol/L";;

// lineSeries.stacked = true;

// var fillModifier = new am4core.LinearGradientModifier();
// fillModifier.opacities = [1, 0];
// fillModifier.offsets = [0, 1];
// fillModifier.gradient.rotation = 90;
// lineSeries.segments.template.fillModifier = fillModifier;
// bullet.adapter.add("fill", (fill, target)=> {
//   if (target.dataItem) {
//     for(let i = 0; i < chart.data.length; i++) {
//       if (
//         target.dataItem.valueY <= 4
//       ) {
//         return am4core.color("yellow");
//       } else if(target.dataItem.valueY >= 12){
//         return am4core.color("#ff008dd9");
//
//       }else{
//         return am4core.color("#2a93fc");
//
//       }
//     }
//   }
//   return fill;
// });

chart.scrollbarX = new am4core.Scrollbar();
chart.scrollbarX.parent = chart.bottomAxesContainer;
chart.scrollbarX.thumb.minWidth = 5;
chart.scrollbarX.startGrip.disabled = true;
chart.scrollbarX.endGrip.disabled = true;

let range = valueAxis.axisRanges.create();
range.value = 0;
range.endValue = user.userDetails && user.userDetails.user && user.userDetails.user.low?user.userDetails.user.low:0;
range.axisFill.fill = am4core.color("#ff7dc561");
range.axisFill.fillOpacity = 0.2;
range.grid.strokeOpacity = 0;

let range1 = valueAxis.axisRanges.create();
range1.value = user.userDetails && user.userDetails.user && user.userDetails.user.high?user.userDetails.user.high:0;
range1.endValue = 30;
range1.axisFill.fill = am4core.color("#ffff008c");
range1.axisFill.fillOpacity = 0.2;
range1.grid.strokeOpacity = 0;

chart.cursor = new am4charts.XYCursor();
// chart.cursor.behavior= 'panXY';

chart.events.on('ready', () => {
  setShowGraph(true);
});




return () => {
      chart.dispose();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
},[props.data, props.chartId]);
// let startDate=moment(new Date(props.data.weekStartDate)).format('DD-MM-YYYY');
// let endDate=moment(new Date(props.data.weekStartDate)).add(1, 'days').format('DD-MM-YYYY').toString();
  return (
<div>
    <div className={showGraph?"d-none":" w-100 p-5"}>                    <Loading/>
    </div>
    <div className={showGraph?"":"d-none"}>

  {/*<p className="pb-3">From: {startDate}  To: {endDate}</p>*/}

        <div id={props.chartId} style={{ width: "100%", minHeight: "500px" }}></div>
  </div>
  </div>

  );
}

export default TestHistory;
