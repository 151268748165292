import React, { useState,useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { resetPassword } from "../../redux/actions/auth";
import ShowToast from "../../utils/show-toast";
import GlucoRxLogo from "../../Assets/Logo/GlucoRxLogo.svg";


var pattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-_]).{8,}$/;
const ResetPassword = () => {
  useEffect(()=>{
    if(localStorage.getItem("EostreAuthToken")) {
      localStorage.removeItem("EostreAuthToken");

    }
  })
  const token = new URLSearchParams(window.location.search).get("token");
const history = useNavigate();

const [isLoading, setIsLoading] = useState(false);
const [password, setPassword] = useState("");
const [cnfPassword, setCnfPassword] = useState("");
const [matchPassword,setMatchPassword]=useState( null);
const [passwordShown, setPasswordShown] = useState(false);
const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
const [error, setError] = useState(false);


const dispatch = useDispatch();

const comparePassword = () => {
  if (password === cnfPassword) {
    setMatchPassword(true)

  } else {
    setMatchPassword(false)
  }
}
const togglePasswordVisiblity = () => {
  setPasswordShown(passwordShown ? false : true);
};

const toggleConfirmPasswordVisiblity = () => {
  setConfirmPasswordShown(confirmPasswordShown ? false : true);
};


const handleSubmit = async (e) => {
  e.preventDefault();
  if(password===cnfPassword){

  let re;
        if(password != "" ) {
          if(password.length < 8 && password===cnfPassword) {
            ShowToast("Password should contain minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character.","error");
            setError("Password must contain at least Eight characters!");

            return false;
          }

          re = /[0-9]/;
          if(!re.test(password)) {
            ShowToast("Password should contain minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character.","error");

            setError("Password must contain at least one number (0-9)!");


            return false;
          }
          re = /[a-z]/;
          if(!re.test(password)) {
            ShowToast("Password should contain minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character.","error");

            setError("Password must contain at least one lowercase letter (a-z)!");


            return false;
          }
          re = /[A-Z]/;
          if(!re.test(password)) {
            ShowToast("Password should contain minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character.","error");

            setError("Password must contain at least one uppercase letter (A-Z)!");


            return false;
          }
          re=/[~!@#$%^&*_\-+=?]/;
          if(!re.test(password)) {
            ShowToast("Password should contain minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character.","error");

            setError("Password must contain at least one special character (#?!@$%^&*-_)!");


            return false;
          }
          if (password !== cnfPassword){
            ShowToast("Passwords do not match","error");
            setError("Passwords do not match");

            return false;
          }
        }
  try {
    setIsLoading(true);

    if (!token) {
      setIsLoading(false);
      return;
    }
    const result=await dispatch(resetPassword({ password,token }));

   history("/auth/login");

    ShowToast(result.data.message, "success");
    setIsLoading(false);
  } catch (err) {
    setIsLoading(false);
  }
  return true;
}
else{
  ShowToast("Passwords do not match", "error");
}
};

    return (
        <div className='container-fluid d-flex flex-column forget-background ' >

        <div className="row justify-content-center align-item-center my-auto" >
            <div className="col-md-7 col-11 forget-field-box align-items-center py-5">
               <div className='d-flex justify-content-center '><img src={GlucoRxLogo} className='forget-gluco-logo '></img></div>
               <p className=' text-white text-center forget-text-font mt-3'>Type a new password</p>
               <form onSubmit={handleSubmit}>
                <div className="row justify-content-center align-items-center my-3 text-white">
                    <div className="col-md-10 col-10">
                        <input
                          type="password"
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          className="forget-form-control"
                          id="formGroupExampleInput"
                          required
                        />
                    </div>
                </div>
                <div className="row justify-content-center align-items-center my-3 text-white">
                <div className="col-md-10 col-10">
                    <input
                      type="password"
                      placeholder="Confirm Password"
                      value={cnfPassword}
                      onChange={(e) => setCnfPassword(e.target.value)}
                      className={`forget-form-control ${password!==cnfPassword ? 'error-color-input' : null}`}
                      id="formGroupExampleInput"
                      required
                    />
                </div>

            </div>


                <div className="row justify-content-center align-items-center my-3 text-white">
                    <div className="col-md-10 col-10">
                {/* <Spin spinning={isLoading}> */}

                      <button
                            type="submit"
                            className="forget-submit-btn btn-primary "
                          >
                            Reset Password

                        </button>
                        {/* </Spin> */}
                    </div>
                </div>
                </form>
                <div className="mt-3 text-primary">
                    <p className="text-center forget-text-font">
                    <Link
                  to={"/LoginNew"}>
                  Back to Sign In!
                </Link>
                    </p>
                </div>
            </div>


            </div>


    </div>
    );
};

export default ResetPassword;
