import { Pattern } from "@amcharts/amcharts4/core";
import React from "react";
import { useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import Navbar from "../Components/Common/Navbar";
import Sidebar from "../Components/Common/Sidebar";
import HistoryComp from "../Components/Reports/HistoryComp";


const  PatternPage = () => {
  const history = useNavigate();
  const userData = useSelector((state) => state.user);
  // // console.log("userDATA :", userData);
  // if(userData.userDetails && userData.userDetails.user && userData.userDetails.user.role.length>0){
  //  history("/dashboard");
  // }

    return (


        <HistoryComp/>

  );
};

export default  PatternPage;
