import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";

import moment from "moment";

const AllFilters = ({ filter, setFilter, daysDif,
  fetchFilterReadings, fetchRangeReadings, startDate, endDate,
  dropMenu, setDropMenu, setStartDate, setEndDate, rangFilter,
  setRangeFilter, filterText, setFilterText, showDays, showRange, loader }) => {

  const { fromDate, toDate } = useSelector((state) => state.user);
  useEffect(() => {
    // console.log("Start", startDate);
    // console.log("end", endDate);
    // console.log("diff", daysDif);
    // console.log("filter", filter);
    // console.log("fromDate", fromDate);
    // console.log("toDate", toDate);



  }, [filter, rangFilter])

  return (
    <div className="row justify-content-center mt-3 p-3">
      <div className={showRange === true ?"col-md-4 col-12 overview-border": "col-md-6 col-12 overview-border"}>
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="font-weight-bold text-black">{filter !== "" ? filter : daysDif !== "" && daysDif !== "0 Day" ? daysDif : daysDif === "0 Day" ? "1 Day" : filterText}</h3>
          <div className="bg-light p-3">{fromDate ? fromDate.toString() : 0} - {toDate ? toDate : 0}</div>
        </div>
      </div>
      <div className={showRange === true?"col-md-8 col-12 mt-md-0 mt-2 overview-border d-flex justify-content-around align-items-center":"col-md-6 col-12 mt-md-0 mt-2 overview-border d-flex justify-content-around align-items-center"}>
        <div className="row w-100">
          <div className={showRange === true ? "col-6" : "col-10 d-flex justify-content-around"}>
            <button
              className={
                startDate === "" && filter === "7 days"
                  ? "bg-common text-white rounded p-2 mr-2"
                  : " bg-light rounded p-2 mr-2"
              }
              name="7 days"
              id="7days"
              onClick={(e) => {
                // loader(true);
                fetchFilterReadings(e)

              }
              }>
              7 days
            </button>
            <button className={
              startDate === "" && filter === "15 days"
                ? "bg-common text-white rounded p-2 mr-2"
                : " bg-light rounded p-2 mr-2"
            }
              name="15 days"
              id="15days"
              onClick={
                (e) => {
                  fetchFilterReadings(e)


                }
              }
            >15 days</button>
            <button className={
              startDate === "" && (filter === "30 days" || filter === "30days")
                ? "bg-common text-white rounded p-2 mr-2"
                : " bg-light rounded p-2 mr-2"
            }
              name="30 days"
              id="30days"
              onClick={
                (e) => {
                  fetchFilterReadings(e)


                }
              }

            >30 days</button>
            {showDays === "90" ? null :
              <button className={
                startDate === "" && filter === "90 days"
                  ? "bg-common text-white rounded p-2 mr-2"
                  : " bg-light rounded p-2 mr-2"
              }
                name="90 days"
                id="90days"
                onClick={
                  (e) => {
                    fetchFilterReadings(e)

                  }
                }

              >90 days</button>}
            {showRange === true ?
              <button type="button"
                className={rangFilter ? "bg-common text-white rounded p-2 mr-2" : "bg-light rounded p-2 mr-2"} onClick={
                  () => {
                    setFilterText("Range");

                    setRangeFilter(!rangFilter);
                    setDropMenu(!dropMenu);

                  }
                }>
                RANGE
              </button> : null}

          </div>
          {showRange === true ?
            <div className="col-6 d-flex">
              {dropMenu === true ?
                <div className="d-flex text-center w-100">

                  <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
                    <div className='d-flex flex-row mb-md-0 mb-2'>
                      <input type="date" className="m-0"
                        onChange={(e) => setStartDate(moment(e.target.value).format("YYYY-MM-DD").toString())}
                        onKeyDown={(e) => e.preventDefault()}
                      />
                      <input type="date"
                        disabled={startDate === "" ? true : false}
                        className="ml-1 mt-0 mb-0 mr-0"
                        onChange={(e) => setEndDate(moment(e.target.value).format("YYYY-MM-DD").toString())}
                        min={moment(startDate).format("YYYY-MM-DD")}
                        max={moment().format("YYYY-MM-DD")}
                        onKeyDown={(e) => e.preventDefault()}
                      />
                    </div>
                    <button
                      className="bg-common text-white rounded p-2 ml-2"
                      disabled={endDate === "" ? true : false}

                      onClick={() => { setFilter(""); fetchRangeReadings(); }}
                    >Go</button>
                  </div>

                </div>

                : null}
            </div> : null}
        </div>


      </div>
    </div>
  );
};

export default AllFilters;
