import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { Spin } from "antd";


 import { useNavigate, useLocation } from "react-router-dom";
// import {encrypt,decrypt} from "../../../utils/encryption";

import { saveUser } from "../../redux/actions/user";


import moment from "moment";

// import UsersMangement from "../../common/nav/usersmanagement.js";

const MultipleRoles = (props) => {
  const dispatch = useDispatch();
  const[roleOptions, setRoleOptions] = useState();
  const[roleNew, setRoleNew] = useState();


  const { userDetails, selectedUser } = useSelector((state) => {return state.user});
  useEffect(() => {


    }, [userDetails]);

const saveSelectedUser=()=>{
  // e.preventDefault();
let data = {
  firstName:userDetails.user.firstName,
  lastName:userDetails.user.lastName,
  status:userDetails.user.status,
  email:userDetails.user.email,
  gender:userDetails.user.gender,
  dateOfBirth:userDetails.user.dateOfBirth,
  postcode:userDetails.user.postcode,
  address:userDetails.user.address,
  country:userDetails.user.country,
  town:userDetails.user.town,
  phone:userDetails.user.phone,
  role:[parseInt(roleNew)],
  diabetesType:userDetails.user.diabetesType,
  app:userDetails.user.app,
}
  dispatch(saveUser(data));

}
const handleChangeRole =(e)=> {
  setRoleNew(e.target.value);
}
    return (
      <div>
        <div className="">
          <div className="container-fluid login-background  d-flex flex-column">
            <div className="row  justify-content-md-between  justify-content-center  ml-md-3 ml-lg-0">
              <div className="col-md-3 col-11  mt-5">
                {/* <div className="text-md-left text-center ml-md-3 ml-0">
                  <img src={voyagerLogo} width="200px"></img>
                </div> */}
                <div className="text-md-left text-center text-uppercase login-account-heading1 text-uppercase">
                  <p>Choose a role you want to continue</p>
                </div>
                <div className="text-md-left text-center login-account-heading2 mt-n4 text-white">
                  <p></p>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 col-11 login-account-field-box mr-md-5 mr-0 mt-5 p-5">
                <form>
                  <div className="container mt-5">
                    <div className="row justify-content-center text-white">
                    <div className="col-12">
                    <label htmlFor="apps">Choose a Role &nbsp; </label>

                   <select name="apps" id="apps" style={{color:"black"}} onChange={(e)=>handleChangeRole(e)}>
                   <option value="">Select</option>

                     {userDetails && userDetails.user && userDetails.user.role.includes(1)?<option value="1">Admin</option>:null}
                    {userDetails && userDetails.user && userDetails.user.role.includes(2)?<option value="2">Clinician</option>:null}
                  {userDetails && userDetails.user && userDetails.user.role.includes(3)?   <option value="3">Engineer</option>:null}
                  {userDetails && userDetails.user && userDetails.user.role.includes(4)?  <option value="4">Patient</option>:null}



                   </select>
                    </div>
                    <div className="col-md-8 mt-2 ">
                      <button
                        type="button"
                        className="login-btn btn-primary "
                        onClick={()=>saveSelectedUser()}
                      >
                        Save
                      </button>
                    </div>



                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default MultipleRoles;
