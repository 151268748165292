import * as actions from "../actionTypes";

const initialState = {
  // veryHighEventsPercentage: null,
  // highEventsPercentage: null,
  // inRangeEventsPercentage: null,
  // lowEventsPercentage:null,
  // veryLowEventsPercentage: null,
  // stdDeviation: null,
  history:[],
  isLoading: false,
 // averageGlucose:null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_HISTORY_START:
      return{...state,
      isLoading: true}
      break
    case actions.FETCH_HISTORY_SUCCESS:
      return {
        ...state,
        history: action.payLoad,
        isLoading: false
      }
      break

    case actions.FETCH_HISTORY_FAILED:
      return{
        ...state,
        isLoading: false
      }
      break

    default:
      return state;
  }
}
