import React, { useEffect, useState } from "react";
// import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Loading from '../../Common/Loading';
import insulinIcon from "../../../Assets/charts/insulin.svg";
import exerciseIcon from "../../../Assets/charts/exercise.svg";
import dietIcon from "../../../Assets/charts/diet.svg";
import medicineIcon from "../../../Assets/charts/medicine.svg";
import moment from "moment";
import * as _ from "lodash";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


am4core.useTheme(am4themes_animated);
// Themes end
function OverlayTestChart(props) {
  console.log("PROPS BG:", props);
  const [showGraph, setShowGraph] = useState(false);
  const [count, setCount] = useState(props.unit);

  const { user, settings } = useSelector((state) => { return state });
  // console.log("PROPS BG:", props);
  // let chart;
  // const location = useLocation();
  // // // // // console.log("props.",props);
  useEffect(() => {
    let chart = am4core.create(props.chartId, am4charts.XYChart);

    const transformHistoricalReadings = (data) => {

      let transformedData = [];

      transformedData = data && data.length > 0 && data.map((reading) => {


        return {
          date: new Date(reading.time),
          reading: reading.value,
        };
      });
      let myArray = _.sortBy(transformedData, function (dateObj) {

        return new Date(dateObj.date);
      });
      // console.log("myArray", myArray);

      return myArray;
    };



    // Add data
    // chart.data = props.data;
    let thursdaySeriesData;
    // = props.thursday;
    // // // // console.log("thursdaySeriesData", thursdaySeriesData);
    if (props.thursday && props.thursday.length > 0) {
      thursdaySeriesData = transformHistoricalReadings(props.thursday);

      thursdaySeriesData = thursdaySeriesData.map((record) => {
        const color = record.reading >= user.userDetails.user.high ? "#f38f20" : record.reading <= user.userDetails.user.low ? "#d61a00" : "#2a93fc";
        return {
          time: new Date(record.date),
          glucose: count === 1 ? (18.01 * record.reading) : record.reading,
          time2: moment(record.date).format("HH:mm"),
          color: color,
        }
      });
    }
    // console.log("thursdaySeriesData", thursdaySeriesData);
    let saturdaySeriesData;
    if (props.saturday && props.saturday.length > 0) {
      saturdaySeriesData = transformHistoricalReadings(props.saturday);

      saturdaySeriesData = saturdaySeriesData.map((record) => {
        const color = record.reading >= user.userDetails.user.high ? "#f38f20" : record.reading <= user.userDetails.user.low ? "#d61a00" : "#2a93fc";
        return {
          time: new Date(record.date),
          glucose: count === 1 ? (18.01 * record.reading) : record.reading,
          time2: moment(record.date).format("HH:mm"),
          color: color,
        }
      });
    } else {
      // console.log("Saturday");
    }
    // // // console.log("saturdaySeriesData", saturdaySeriesData);
    let sundaySeriesData;
    if (props.sunday && props.sunday.length > 0) {
      sundaySeriesData = transformHistoricalReadings(props.sunday);

      sundaySeriesData = sundaySeriesData.map((record) => {
        const color = record.reading >= user.userDetails.user.high ? "#f38f20" : record.reading <= user.userDetails.user.low ? "#d61a00" : "#2a93fc";
        return {
          time: new Date(record.date),
          glucose: count === 1 ? (18.01 * record.reading) : record.reading,
          time2: moment(record.date).format("HH:mm"),
          color: color,
        }
      });
    }


    let mondaySeriesData;
    if (props.monday && props.monday.length > 0) {
      mondaySeriesData = transformHistoricalReadings(props.monday);

      mondaySeriesData = mondaySeriesData.map((record) => {
        const color = record.reading >= user.userDetails.user.high ? "#f38f20" : record.reading <= user.userDetails.user.low ? "#d61a00" : "#2a93fc";
        return {
          time: new Date(record.date),
          glucose: count === 1 ? (18.01 * record.reading) : record.reading,
          time2: moment(record.date).format("HH:mm"),
          color: color,
        }
      });
    }

    let tuesdaySeriesData;
    if (props.tuesday && props.tuesday.length > 0) {
      tuesdaySeriesData = transformHistoricalReadings(props.tuesday);

      tuesdaySeriesData = tuesdaySeriesData.map((record) => {
        const color = record.reading >= user.userDetails.user.high ? "#f38f20" : record.reading <= user.userDetails.user.low ? "#d61a00" : "#2a93fc";
        return {
          time: new Date(record.date),
          glucose: count === 1 ? (18.01 * record.reading) : record.reading,
          time2: moment(record.date).format("HH:mm"),
          color: color,
        }
      });
    }

    let wednesdaySeriesData;
    if (props.wednesday && props.wednesday.length > 0) {
      wednesdaySeriesData = transformHistoricalReadings(props.wednesday);

      wednesdaySeriesData = wednesdaySeriesData.map((record) => {
        const color = record.reading >= user.userDetails.user.high ? "#f38f20" : record.reading <= user.userDetails.user.low ? "#d61a00" : "#2a93fc";
        return {
          time: new Date(record.date),
          glucose: count === 1 ? (18.01 * record.reading) : record.reading,
          time2: moment(record.date).format("HH:mm"),
          color: color,
        }
      });
    }
    let fridaySeriesData;
    if (props.friday && props.friday.length > 0) {
      fridaySeriesData = transformHistoricalReadings(props.friday);

      fridaySeriesData = fridaySeriesData.map((record) => {
        const color = record.reading >= user.userDetails.user.high ? "#f38f20" : record.reading <= user.userDetails.user.low ? "#d61a00" : "#2a93fc";
        return {
          time: new Date(record.date),
          glucose: count === 1 ? (18.01 * record.reading) : record.reading,
          time2: moment(record.date).format("HH:mm"),
          color: color,
        }
      });
    }




    chart.dateFormatter.dateFormat = "dd-MM-yyyy";
    // Create axes
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    // dateAxis.startLocation = 0.5;
    dateAxis.dateFormatter = new am4core.DateFormatter();
    dateAxis.dateFormatter.dateFormat = "dd-YYYY";
    dateAxis.cursorTooltipEnabled = false;

    // Set up drill-down
    // dateAxis.renderer.labels.template.events.on("hit",(ev)=> {
    //   console.log("EV", ev);
    //   var start = ev.target.dataItem.date;
    //   var end = new Date(start);
    //   end.setDay(end.getDay() + 1);
    //   dateAxis.zoomToDates(start, end);
    // })

    // Create value axis
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.title.text = user.userDetails && user.userDetails.user && user.userDetails.user.unit === 0 ? "mmol/L" : "mmol/L";
    // valueAxis.title.text = count === 0?"mmol/L":"mmol/L";

    valueAxis.cursorTooltipEnabled = false;

    let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis2.renderer.opposite = true;
    // valueAxis2.syncWithAxis = valueAxis;
    valueAxis2.hidden = true;
    valueAxis2.cursorTooltipEnabled = false;



    // console.log("mondaySeriesData", mondaySeriesData);
    //
    const hasData =
      (mondaySeriesData !== undefined && mondaySeriesData.length > 0) ||
      (tuesdaySeriesData !== undefined && tuesdaySeriesData.length > 0) ||
      (wednesdaySeriesData !== undefined && wednesdaySeriesData.length > 0) ||
      (thursdaySeriesData !== undefined && thursdaySeriesData.length > 0) ||
      (fridaySeriesData !== undefined && fridaySeriesData.length > 0) ||
      (saturdaySeriesData !== undefined && saturdaySeriesData.length > 0) ||
      (sundaySeriesData !== undefined && sundaySeriesData.length > 0);
    chart.data = hasData;
    // console.log("chart.data", chart.data);


    // for monday Series
    let mondaySeries = chart.series.push(new am4charts.LineSeries());
    mondaySeries.dataFields.valueY = "glucose";
    mondaySeries.dataFields.dateX = "time";
    mondaySeries.strokeWidth = 4;
    mondaySeries.fillOpacity = 0;
    mondaySeries.name = "Monday";
    // mondaySeries.sequencedInterpolation = false;
    mondaySeries.data = mondaySeriesData;
    // sundaySeries.data= sundaySeriesData;
    let segmentMonday = mondaySeries.segments.template;
    segmentMonday.interactionsEnabled = true;

    let hoverStateMonday = segmentMonday.states.create("hover");
    hoverStateMonday.properties.strokeWidth = 3;

    let dimmedMonday = segmentMonday.states.create("dimmed");
    dimmedMonday.properties.stroke = am4core.color("#dadada");

    segmentMonday.events.on("over", function (event) {
      processOver(event.target.parent.parent.parent);
    });

    segmentMonday.events.on("out", function (event) {
      processOut(event.target.parent.parent.parent);
    });


    // mondaySeries.yAxis = valueAxis2;
    let mondayBullet = mondaySeries.bullets.push(new am4core.Circle());
    mondayBullet.stroke = am4core.color("#fff");
    mondayBullet.radius = 4;
    mondayBullet.propertyFields.fill = "color";
    mondayBullet.tooltipText = count === 0 ? "Date: {dateX} \n Time: {time2} \n Reading: {valueY} mmol/L" : "Date: {dateX} \n Time: {time2} \n Reading: {valueY} mmol/L";


    // for tuesday Series
    let tuesdaySeries = chart.series.push(new am4charts.LineSeries());
    tuesdaySeries.dataFields.valueY = "glucose";
    tuesdaySeries.dataFields.dateX = "time";
    tuesdaySeries.strokeWidth = 4;
    tuesdaySeries.fillOpacity = 0;
    tuesdaySeries.name = "Tuesday";
    tuesdaySeries.sequencedInterpolation = false;
    tuesdaySeries.data = tuesdaySeriesData;
    let segmentTuesday = tuesdaySeries.segments.template;
    segmentTuesday.interactionsEnabled = true;

    let hoverStateTuesday = segmentTuesday.states.create("hover");
    hoverStateTuesday.properties.strokeWidth = 3;

    let dimmedTuesday = segmentTuesday.states.create("dimmed");
    dimmedTuesday.properties.stroke = am4core.color("#dadada");

    segmentTuesday.events.on("over", function (event) {
      processOver(event.target.parent.parent.parent);
    });

    segmentTuesday.events.on("out", function (event) {
      processOut(event.target.parent.parent.parent);
    });

    // tuesdaySeries.yAxis = valueAxis2;
    let tuesdayBullet = tuesdaySeries.bullets.push(new am4core.Circle());
    tuesdayBullet.stroke = am4core.color("#fff");
    tuesdayBullet.radius = 4;
    tuesdayBullet.propertyFields.fill = "color";
    tuesdayBullet.tooltipText = count === 0 ? "Date: {dateX} \n Time: {time2} \n Reading: {valueY} mmol/L" : "Date: {dateX} \n Time: {time2} \n Reading: {valueY} mmol/L";



    // for wednesday Series
    let wednesdaySeries = chart.series.push(new am4charts.LineSeries());
    wednesdaySeries.dataFields.valueY = "glucose";
    wednesdaySeries.dataFields.dateX = "time";
    wednesdaySeries.strokeWidth = 4;
    wednesdaySeries.fillOpacity = 0;
    wednesdaySeries.name = "Wednesday";
    wednesdaySeries.sequencedInterpolation = false;
    wednesdaySeries.data = wednesdaySeriesData;
    let segmentWednesday = wednesdaySeries.segments.template;
    segmentWednesday.interactionsEnabled = true;

    let hoverStateWednesday = segmentWednesday.states.create("hover");
    hoverStateWednesday.properties.strokeWidth = 3;

    let dimmedWednesday = segmentWednesday.states.create("dimmed");
    dimmedWednesday.properties.stroke = am4core.color("#dadada");

    segmentWednesday.events.on("over", function (event) {
      processOver(event.target.parent.parent.parent);
    });

    segmentWednesday.events.on("out", function (event) {
      processOut(event.target.parent.parent.parent);
    });

    // wednesdaySeries.yAxis = valueAxis2;
    let wednesdayBullet = wednesdaySeries.bullets.push(new am4core.Circle());
    wednesdayBullet.stroke = am4core.color("#fff");
    wednesdayBullet.radius = 4;
    wednesdayBullet.propertyFields.fill = "color";
    wednesdayBullet.tooltipText = count === 0 ? "Date: {dateX} \n Time: {time2} \n Reading: {valueY} mmol/L" : "Date: {dateX} \n Time: {time2} \n Reading: {valueY} mmol/L";



    // for thursday Series
    let thursdaySeries = chart.series.push(new am4charts.LineSeries());
    thursdaySeries.dataFields.valueY = "glucose";
    thursdaySeries.dataFields.dateX = "time";
    thursdaySeries.strokeWidth = 4;
    thursdaySeries.fillOpacity = 0;
    thursdaySeries.name = "Thursday";
    thursdaySeries.sequencedInterpolation = false;
    thursdaySeries.data = thursdaySeriesData;
    let segmentThursday = thursdaySeries.segments.template;
    segmentThursday.interactionsEnabled = true;

    let hoverStateThursday = segmentThursday.states.create("hover");
    hoverStateThursday.properties.strokeWidth = 3;

    let dimmedThursday = segmentThursday.states.create("dimmed");
    dimmedThursday.properties.stroke = am4core.color("#dadada");

    segmentThursday.events.on("over", function (event) {
      processOver(event.target.parent.parent.parent);
    });

    segmentThursday.events.on("out", function (event) {
      processOut(event.target.parent.parent.parent);
    });

    // thursdaySeries.yAxis = valueAxis2;
    let thursdayBullet = thursdaySeries.bullets.push(new am4core.Circle());
    thursdayBullet.stroke = am4core.color("#fff");
    thursdayBullet.radius = 4;
    thursdayBullet.propertyFields.fill = "color";
    thursdayBullet.tooltipText = count === 0 ? "Date: {dateX} \n Time: {time2} \n Reading: {valueY} mmol/L" : "Date: {dateX} \n Time: {time2} \n Reading: {valueY} mmol/L";


    // Create series for friday

    let fridaySeries = chart.series.push(new am4charts.LineSeries());
    fridaySeries.dataFields.valueY = "glucose";
    fridaySeries.dataFields.dateX = "time";
    fridaySeries.strokeWidth = 4;
    fridaySeries.fillOpacity = 0;
    fridaySeries.name = "Friday";
    fridaySeries.sequencedInterpolation = false;
    fridaySeries.data = fridaySeriesData;
    let segmentFriday = fridaySeries.segments.template;
    segmentFriday.interactionsEnabled = true;

    let hoverStateFriday = segmentFriday.states.create("hover");
    hoverStateFriday.properties.strokeWidth = 3;

    let dimmedFriday = segmentFriday.states.create("dimmed");
    dimmedFriday.properties.stroke = am4core.color("#dadada");

    segmentFriday.events.on("over", function (event) {
      processOver(event.target.parent.parent.parent);
    });

    segmentFriday.events.on("out", function (event) {
      processOut(event.target.parent.parent.parent);
    });
    let bullet = fridaySeries.bullets.push(new am4core.Circle());
    bullet.stroke = am4core.color("#fff");
    bullet.radius = 4;
    bullet.propertyFields.fill = "color";
    // bullet.tooltipText = user.userDetails && user.userDetails.user && user.userDetails.user.unit === 0?"Date: {dateX} \n Time: {time2} \n Reading: {valueY} mmol/L":"Date: {dateX} \n Time: {time2} \n Reading: {valueY} mmol/L";

    bullet.tooltipText = count === 0 ? "Date: {dateX} \n Time: {time2} \n Reading: {valueY} mmol/L" : "Date: {dateX} \n Time: {time2} \n Reading: {valueY} mmol/L";


    // for saturday Series
    let saturdaySeries = chart.series.push(new am4charts.LineSeries());
    saturdaySeries.dataFields.valueY = "glucose";
    saturdaySeries.dataFields.dateX = "time";
    saturdaySeries.strokeWidth = 4;
    saturdaySeries.fillOpacity = 0;
    saturdaySeries.name = "Saturday";
    saturdaySeries.sequencedInterpolation = false;
    saturdaySeries.data = saturdaySeriesData;
    let segmentSaturday = saturdaySeries.segments.template;
    segmentSaturday.interactionsEnabled = true;

    let hoverStateSaturday = segmentSaturday.states.create("hover");
    hoverStateSaturday.properties.strokeWidth = 3;

    let dimmedSaturday = segmentSaturday.states.create("dimmed");
    dimmedSaturday.properties.stroke = am4core.color("#dadada");

    segmentSaturday.events.on("over", function (event) {
      processOver(event.target.parent.parent.parent);
    });

    segmentSaturday.events.on("out", function (event) {
      processOut(event.target.parent.parent.parent);
    });

    // saturdaySeries.yAxis = valueAxis2;
    let saturdayBullet = saturdaySeries.bullets.push(new am4core.Circle());
    saturdayBullet.stroke = am4core.color("#fff");
    saturdayBullet.radius = 4;
    saturdayBullet.propertyFields.fill = "color";
    saturdayBullet.tooltipText = count === 0 ? "Date: {dateX} \n Time: {time2} \n Reading: {valueY} mmol/L" : "Date: {dateX} \n Time: {time2} \n Reading: {valueY} mmol/L";

    // for sunday Series
    let sundaySeries = chart.series.push(new am4charts.LineSeries());
    sundaySeries.dataFields.valueY = "glucose";
    sundaySeries.dataFields.dateX = "time";
    sundaySeries.strokeWidth = 4;
    sundaySeries.fillOpacity = 0;
    sundaySeries.name = "Sunday";
    // sundaySeries.sequencedInterpolation = false;
    sundaySeries.data = sundaySeriesData;
    let segment = sundaySeries.segments.template;
    segment.interactionsEnabled = true;

    let hoverState = segment.states.create("hover");
    hoverState.properties.strokeWidth = 3;

    let dimmed = segment.states.create("dimmed");
    dimmed.properties.stroke = am4core.color("#dadada");

    segment.events.on("over", function (event) {
      processOver(event.target.parent.parent.parent);
    });

    segment.events.on("out", function (event) {
      processOut(event.target.parent.parent.parent);
    });

    // sundaySeries.yAxis = valueAxis2;
    let sundayBullet = sundaySeries.bullets.push(new am4core.Circle());
    sundayBullet.stroke = am4core.color("#fff");
    sundayBullet.radius = 4;
    sundayBullet.propertyFields.fill = "color";
    sundayBullet.tooltipText = count === 0 ? "Date: {dateX} \n Time: {time2} \n Reading: {valueY} mmol/L" : "Date: {dateX} \n Time: {time2} \n Reading: {valueY} mmol/L";


    // console.log("sundayBullet :",sundayBullet);
    // let  segmentBullet = sundayBullet.properties.template;
    // 	segmentBullet.interactionsEnabled = true;
    //
    // 	let  hoverStateBullet = segmentBullet.states.create("hover");
    // 	hoverStateBullet.properties.strokeWidth = 3;
    //
    // 	let  dimmedBullet = segmentBullet.states.create("dimmed");
    // 	dimmedBullet.properties.stroke = am4core.color("#dadada");
    //
    // 	segmentBullet.events.on("over", function(event) {
    // 		processOver(event.target.parent.parent.parent);
    // 	});
    //
    // 	segmentBullet.events.on("out", function(event) {
    // 		processOut(event.target.parent.parent.parent);
    // 	});


    /* Create legend and enable default markers */


    const processOver = (hoveredSeries) => {
      // console.log("hoveredSeries", hoveredSeries);
      // hoveredSeries.toFront()

      hoveredSeries.segments.each((segment) => {
        segment.setState("hover");
      })

      hoveredSeries.legendDataItem.marker.setState("default");
      hoveredSeries.legendDataItem.label.setState("default");

      chart.series.each((series) => {
        if (series != hoveredSeries) {
          // console.log("Series", series);
          series.segments.each((segment) => {
            // console.log("SEGMENT", segment);

            segment.setState("dimmed");
          })
          // series.bulletsContainer.setState("dimmed");
          // console.log("bulletsContainer", series.bulletsContainer);
          series.bulletsContainer.children.each((bullet) => {
            bullet.radius = 1; // Adjust the default radius as needed
          });
          // console.log("bulletsContainer after", series.bulletsContainer);

          series.legendDataItem.marker.setState("dimmed");
          series.legendDataItem.label.setState("dimmed");
        }
      });
    }

    const processOut = () => {
      chart.series.each((series) => {
        series.segments.each((segment) => {
          segment.setState("default", 2000);
        })
        // series.bulletsContainer.setState("default");
        series.bulletsContainer.children.each((bullet) => {
          bullet.radius = 4; // Adjust the default radius as needed

          // setTimeout(()=>{
          //   bullet.radius = 4; // Adjust the default radius as needed
          //
          // },[1000])
        });

        series.legendDataItem.marker.setState("default");
        series.legendDataItem.label.setState("default");
      });
    }


    chart.legend = new am4charts.Legend();
    // chart.legend.position = "right";
    chart.legend.scrollable = true;

    // setTimeout(function() {
    //   chart.legend.markers.getIndex(0).opacity = 0.3;
    // }, 3000)
    chart.legend.markers.template.states.create("dimmed").properties.opacity = 0.3;
    chart.legend.labels.template.states.create("dimmed").properties.opacity = 0.3;

    chart.legend.itemContainers.template.events.on("over", function (event) {
      processOver(event.target.dataItem.dataContext);
    })

    chart.legend.itemContainers.template.events.on("out", function (event) {
      processOut(event.target.dataItem.dataContext);
    })
    // chart.legend.useDefaultMarker = true;

    /* Remove square from marker template */
    // let marker = chart.legend.markers.template;
    // marker.disposeChildren();
    // marker.width = 40;
    // marker.height = 40;

    // // Add event listener to legend item click event
    // chart.legend.itemContainers.template.events.on("hit", function(event) {
    //   // Get the clicked legend item
    //    var legendItem = event.target.dataItem;
    //
    //    // Check if legendItem is defined and has a valid dataItem
    //    if (legendItem && legendItem.dataItem) {
    //        // If the series is currently visible, hide it
    //        if (legendItem.dataItem.visible) {
    //            legendItem.defaultState.properties.visible = false;
    //        } else {
    //            // If the series is currently hidden, keep it hidden
    //            legendItem.defaultState.properties.visible = false;
    //        }
    //
    //        // Apply changes
    //        chart.legend.data = chart.data;
    //    }
    //     // Identify the clicked series
    //     var clickedSeries = event.target.dataItem.dataContext;
    //
    //     // Check if the series has data and is not hidden
    //     if (clickedSeries.dataItems.length > 0 && !clickedSeries.isHidden) {
    //         // Get the visible range of the series
    //         var startDate = clickedSeries.dataItems.getIndex(0).dateX;
    //         var endDate = clickedSeries.dataItems.getIndex(clickedSeries.dataItems.length - 1).dateX;
    //
    //         // Check if the series has data
    //         if (startDate && endDate) {
    //             // Zoom into the visible range of the series on the x-axis
    //             chart.xAxes.getIndex(0).zoomToDates(startDate, endDate);
    //         }
    //     }
    // });

    chart.events.on("beforedatavalidated", (ev) => {
      if (chart.data !== true) {
        let noData = chart.plotContainer.createChild(am4core.Label);
        noData.text = "No data available";
        noData.align = "center";
        noData.valign = "middle";
        noData.fontSize = 20;
        noData.dy = -50; // Adjust the vertical position as needed
      }
    });

    chart.scrollbarX = new am4core.Scrollbar();
    chart.scrollbarX.parent = chart.bottomAxesContainer;

    chart.scrollbarX.thumb.minWidth = 5;
    chart.scrollbarX.startGrip.disabled = true;
    chart.scrollbarX.endGrip.disabled = true;
    // chart.scrollbarX.startGrip.width=10;
    // chart.scrollbarX.endGrip.width=10;

    let range = valueAxis.axisRanges.create();
    range.value = 0;
    range.endValue = user.userDetails && user.userDetails.user && user.userDetails.user.low ? user.userDetails.user.low : 0;
    // // // // console.log("user.userDetails.low", user.userDetails.low);
    range.axisFill.fill = am4core.color("#ff7dc561");
    range.axisFill.fillOpacity = 0.2;
    range.grid.strokeOpacity = 0;

    let range1 = valueAxis.axisRanges.create();
    range1.value = user.userDetails && user.userDetails.user && user.userDetails.user.high ? user.userDetails.user.high : 0;
    range1.endValue = 30;
    range1.axisFill.fill = am4core.color("#ffff008c");
    range1.axisFill.fillOpacity = 0.2;
    range1.grid.strokeOpacity = 0;

    chart.cursor = new am4charts.XYCursor();


    // chart.cursor.behavior= 'panXY';

    chart.events.on('ready', () => {
      setShowGraph(true);
    });

    return () => {
      chart.dispose();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, props.friday, props.exercise, props.medicine, props.thursday, settings,]);
  let startDate = moment(new Date(props.startDate)).format('DD-MM-YYYY HH:MM');
  let endDate = moment(new Date(props.endDate)).format('DD-MM-YYYY HH:MM');
  const setToCurrentDateIfNeeded = (inputDate) => {
    const currentDate = moment();

    // Parse inputDate using Moment.js
    let parsedInputDate = moment(inputDate);

    // Check if the parsedInputDate is greater than the current date
    if (parsedInputDate.isAfter(currentDate)) {
      // Set the date to the current date
      parsedInputDate = currentDate;
    }

    // Return the result as a formatted string (adjust the format as needed)
    return parsedInputDate; // You can modify the format as per your requirement
  }
  return (<div className="">
    <div className={showGraph ? "d-none" : " w-100"}>                    <Loading />
    </div>
    <p className="pb-3 ml-4">From: {startDate}  To: {endDate}</p>
    <div id={props.chartId} className={showGraph ? "" : "d-none"} style={{ width: "100%", minHeight: "500px" }}></div>
  </div>
  );
}
export default OverlayTestChart;
