import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
 import { useNavigate, useLocation } from "react-router-dom";
// import OverlayChart from "../Charts/OverlayChart";
import OverlayTestChart from "../Charts/overlayTestChart";
import CompareFilters from "./Filter";
import AverageGlucoseReport from "../../../Components/Reports/OverviewComps/AverageGlucoseReport";
import SensorUsageReport from "../../../Components/Reports/OverviewComps/SensorUsageReport";
import TimeInRangeReport from "../../../Components/Reports/OverviewComps/TimeInRangeReport";



import {fetchOverlayData} from "../../../redux/actions/overlay";
import {fetchCompareLeftData} from "../../../redux/actions/compare";
import {filterFromDate,filterToDate} from "../../../redux/actions/user";


import * as _ from "lodash";
import moment from "moment";
import {Spin, Menu, Dropdown, Button, DatePicker} from "antd";


const CompareOverlayLeft = () => {
  const { RangePicker } = DatePicker;
  const history = useNavigate();

  const userData = useSelector((state) => state.user);
  // // console.log("userDATA :", userData);
  if(userData.userDetails && userData.userDetails.user && userData.userDetails.user.role.length>1){
   history("/dashboard");
  }

  const {overlay, bg, sensorDetails} = useSelector((state) => {

    return (state.compareLeft)});

  // const unit = useSelector((state)=> state.devices.device!== undefined?state.devices.device:null);
    const {unit, low, high} = useSelector((state) => state.settings.settings);
  const [filterText, setFilterText] = useState("Choose Data Range");
  const [filter, setFilter] = useState("30 days");
  const [isLoading, setIsLoading] = useState(true);
  const [organizedData, setOrganizedData] = useState([]);
  const [daysDif, setDaysDif] = useState("");
  const [chartData, setChartData] = useState([]);
  const [avgDevi, setAvgDevi] = useState();

  const [showDate, setShowDate] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [todayFilter, setTodayFilter] = useState();
  const [weekFilter, setWeekFilter] = useState();
  const [monthFilter, setMonthFilter] = useState(true);
  const [rangFilter, setRangeFilter] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [dropMenu,setDropMenu]=useState(false);






  const dispatch = useDispatch();

  useEffect(() => {

    if (filter !== "" || startDate !== "") {
      // console.log("FILTER", filter);
      fetchReadings();
    }
  }, []);

  useEffect(() => {
    // console.log("FILTER IN USE:", filter);

  }, [filter, startDate]);



  const fetchReadings = async () => {
    let data = {};
    if (filter) {
      // console.log("FILER IN IF", filter);
      data.time = filter;

    } else {
      // console.log("FILTER IN ELSE", filter);
    }
    if (startDate) {

      data.start_date = startDate;
      data.end_date = endDate;
    }


      let ress =await dispatch(fetchCompareLeftData(data));
// console.log("RESS :", ress);
setChartData(ress);
if (rangFilter === false){
let start = findStartDate(filter);

setFromDate(moment(start).format('D MMM YYYY , h:mm a').toString());
setToDate(moment(findStartDate()).format('D MMM YYYY , h:mm a').toString());
const fromDat = moment(start).format('D MMM YYYY , h:mm a').toString();
    const toDat = moment(findStartDate()).format('D MMM YYYY , h:mm a').toString();
    dispatch(filterFromDate(fromDat));
    dispatch(filterToDate(toDat));
} else if (rangFilter === true) {

  }

  };




  const findStartDate = (filter) => {
    // console.log("FILTERS DAY", filter);

    let currentDate = new Date();

    if(filter === "30days" || filter === "30 days") {
      let start = moment().subtract(30, 'days');
      return new Date(start._d);
    }else if(filter==="7days" || filter === "7 days") {
      let start = moment().subtract(7, 'days');

      return new Date(start._d);


    } else if (filter === "15days" || filter === "15 days") {

      let start = moment().subtract(14, 'days');
      return new Date(start._d);

    } else if (filter === "90days" || filter === "90 days") {

      let start = moment().subtract(90, 'days');
      return new Date(start._d);

    } else if (filter === "today") {

      return new Date();

    } else{

    }

  }
  const findEndDate = () => {
    let currentDate = new Date();
    return (currentDate);
  }

  const fetchRangeReadings=async()=>{
    setFilter("");
    if(startDate !=="" && endDate !==""){
      let a = moment(startDate);
      let b = moment(endDate);
      let dif = moment.duration(b.diff(a)).asDays();
      let finalDif = dif>1? dif + " Days": dif + " Day";
      // // // // console.log(typeof(finalDif));

      setDaysDif(finalDif);
    setIsLoading(true);

    setOrganizedData([]);

  let data = {};



    data.start_date = startDate;
    data.end_date = endDate;



    let ress =await dispatch(fetchCompareLeftData(data));
// console.log("RESS :", ress);
setChartData(ress.overlay);

if (rangFilter === false){
let start = findStartDate(filter);

setFromDate(moment(start).format('D MMM YYYY , h:mm a').toString());
setToDate(moment(findStartDate()).format('D MMM YYYY , h:mm a').toString());
const fromDat = moment(start).format('D MMM YYYY , h:mm a').toString();
    const toDat = moment(findStartDate()).format('D MMM YYYY , h:mm a').toString();
    dispatch(filterFromDate(fromDat));
    dispatch(filterToDate(toDat));
} else if (rangFilter === true) {

  setFromDate(moment(startDate).format('D MMM YYYY , h:mm a').toString());
  setToDate(moment(endDate).format('D MMM YYYY , h:mm a').toString());
  }
  }
  }

  const fetchFilterReadings=async(e)=>{
    // console.log("event", e.target);
    setStartDate("");
    setEndDate("");
    //setShowDate(false);
    setFilter(e.target.name);
    setFilterText(e.target.name);
    // setWeekFilter(true);
    // setTodayFilter(false);
    // setMonthFilter(false);
    setRangeFilter(false);
    setIsLoading(true);
    setDropMenu(false);


    if(filter !== e.target.name){
      setOrganizedData([]);
    }

  let data = {};

    data.time = e.target.name;



    let ress =await dispatch(fetchCompareLeftData(data));
console.log("RESS :", ress);
setChartData(ress);

if (rangFilter === false){
let start = findStartDate(filter);

setFromDate(moment(start).format('D MMM YYYY , h:mm a').toString());
setToDate(moment(findStartDate()).format('D MMM YYYY , h:mm a').toString());
const fromDat = moment(start).format('D MMM YYYY , h:mm a').toString();
    const toDat = moment(findStartDate()).format('D MMM YYYY , h:mm a').toString();
    dispatch(filterFromDate(fromDat));
    dispatch(filterToDate(toDat));
} else if (rangFilter === true) {

  }

  // const calculateAverageDeviation = (data) => {
  //   // Step 1: Extract values from the data
  //   const values = data.map(item => item.value);
    
  //   // Step 2: Calculate the mean
  //   const mean = values.reduce((sum, value) => sum + value, 0) / values.length;
    
  //   // Step 3: Calculate the sum of absolute deviations
  //   const sumOfAbsoluteDeviations = values.reduce((sum, value) => sum + Math.abs(value - mean), 0);
    
  //   // Step 4: Calculate the average deviation
  //   const averageDeviation = sumOfAbsoluteDeviations / values.length;
    
  //   return averageDeviation;
  // }


  }
    return (
      <div className="container-fluid">
      <div className="row  mr-sm-0">
        <div className="col-12 mt-md-0 mt-2"  style={{ height: "100%" }}>
          <div className="bg-white rounded shadow " style={{ height: "100%" }}>
            {/*<OverlayFilter/>*/}
            <div className="sticky-top">
                <div className="d-flex justify-content-between mb-2">
                  <div className="chart-heading mt-3 loginNew-HCP-panel">
                    <span className="bg-primary px-2 py-2 font-size2 font-weight-bold text-white">
                      Overlay
                    </span>
                  </div>

                </div>

              <CompareFilters filter={filter} rangFilter={rangFilter} setRangeFilter={setRangeFilter}
              setFilter={setFilter}
               daysDif={daysDif} fromDate={fromDate} toDate={toDate}
               startDate={startDate} fetchFilterReadings={fetchFilterReadings}
               fetchRangeReadings={fetchRangeReadings} startDate={startDate}
               endDate={endDate} dropMenu={dropMenu} setDropMenu={setDropMenu}
               setStartDate={setStartDate} setEndDate={setEndDate} filterText={filterText} setFilterText={setFilterText} showDays="" showRange={true}/>
            </div>
            </div>
          </div>
        </div>
        <div className="row  mr-sm-0  mt-2">
          <div className="col-12" style={{ height: "300px" }}>
            <div className="bg-white rounded shadow " style={{ height: "100%" }}>
              <AverageGlucoseReport avg={bg} averageDeviation={bg.averageDeviation}/>
            </div>
          </div>

          <div className="col-12 timeRange  my-2" style={{ height: "300px" }} >
            <div className="bg-white rounded shadow " style={{ height: "100%" }}>
              <TimeInRangeReport chartId="overviewChart" data={bg} unit={unit} low={low} high={high}/>
            </div>
          </div>

          {/*<div className="col-12 mt-2" style={{ height: "300px" }}>
            <div className="bg-white rounded shadow " style={{ height: "100%" }}>
              <SensorUsageReport sensorDetails={sensorDetails}/>
            </div>
          </div>*/}
        </div>

      <div className="row  mr-sm-0  mt-2 justify-content-center">
        <div className=" col-md-12" style={{ height: "100%" }}>
          <div className="bg-white rounded shadow " style={{ height: "100%" }}>
        {/*<OverlayReport/>*/}
        <div className='h-100 d-flex flex-column justify-content-md-between justify-content-center px-3 pt-2'>




        {/* <div>
          <i className="fas fa-ellipsis-h"></i>
        </div> */}



      <div className="d-flex flex-column justify-content-between pt-2 mb-4">
      {overlay && overlay !== "" && overlay !== null && overlay !== undefined && overlay.length !== 0 &&
            overlay.map((week, index)=> (
              <div className="w-100" key={index+1}>
                {week && week.readings && week.readings.length>0?
                  <OverlayTestChart chartId={"overlay-left"+ index}  unit={unit}
                  monday={week.readings[0].monday}
                  tuesday={week.readings[0].tuesday}
                  wednesday={week.readings[0].wednesday}
                  thursday={week.readings[0].thursday}
                  friday={week.readings[0].friday}
                  saturday={week.readings[0].saturday}
                  sunday={week.readings[0].sunday}

                  startDate={week.weekStartDate}
                  endDate={week.weekEndDate}/>:null}
              </div>
            ))}


      </div>
      </div>
          </div>
        </div>
      </div>

      </div>
    );
};

export default CompareOverlayLeft;
