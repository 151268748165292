import * as actions from "../actionTypes";
import { API_BASE_URL, fetchSettingsUrl, updateSettingsUrl } from "../../utils/urls";

import http from "../../utils/http-service";
import {encrypt,decrypt} from "../../utils/encryption";


import ShowToast from "../../utils/show-toast";



const fetchSettingsStart = () => {
  return { type: actions.FETCH_SETTINGS_START };
};

const fetchSettingsFailed = () => {
  return { type: actions.FETCH_SETTINGS_FAILED };
};

const fetchSettingsSuccess = (pay) => {
  // // // console.log("PayLoad", pay.settings);
  let payload = pay.settings;
  return { type: actions.FETCH_SETTINGS_SUCCESS, payload:payload };
};

export const fetchSettings = () => async (dispatch) => {
  try {
    dispatch(fetchSettingsStart());

    const result = await http.get(`${API_BASE_URL}${fetchSettingsUrl}`);

    if (result.status === 200) {
      let {
        data: { data },
      } = result;
      data = await decrypt(data);
      data = JSON.parse(data);

      dispatch(fetchSettingsSuccess({ settings: data.settings }));
    }

    return result;
  } catch (error) {
    dispatch(fetchSettingsFailed());
    const settings ={
      
        low: 3.1,
        high: 9.1,
        unit:0,
    }
    dispatch(fetchSettingsSuccess({ settings: settings }));
    // throw error;
  }
};

const updateSettingsStart = () => {
  return { type: actions.UPDATE_SETTINGS_START };
};

const updateSettingsFailed = () => {
  return { type: actions.UPDATE_SETTINGS_FAILED };
};

const updateSettingsSuccess = (payload) => {
  return { type: actions.UPDATE_SETTINGS_SUCCESS, payload };
};

export const updateSetting = (data) => async (dispatch) => {
  console.log("Setting Data", data);


  try {
    dispatch(updateSettingsStart());
    let encData = data;
        encData = JSON.stringify(encData);
     encData = await encrypt(encData);

    const result = await http.put(`${API_BASE_URL}${updateSettingsUrl}`, {data:encData});

    if (result.status === 200 || result.status === "success") {
      const {
        data: { data },
      } = result;

      dispatch(updateSettingsSuccess({ settings: data }));
      dispatch(fetchSettings());

    }
    ShowToast(result.data.message,"success");
    return result;
  } catch (error) {
    dispatch(updateSettingsFailed());
    ShowToast(error.message,"error");

    // throw error;
  }
};
