import React from 'react';
import Navbar from "../Components/Common/Navbar";
import Sidebar from "../Components/Common/Sidebar";
import SupportComp from '../Components/Support/SupportComp';
import Maintainance from "./maintainance";

const SupportPage = () => {
    return (
        <div>
             <div className="container-fluid h-100 bg-white">

    <div className="row h-100 bg-light rounded mx-1 pl-md-2 pl-0 py-2">

        <main className="col  bg-faded ">
        <SupportComp/>
         </main>
    </div>
</div>
        </div>
    );
};

export default SupportPage;
