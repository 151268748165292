import React from 'react';

const CitiesModal = (props) => {
    console.log("PROPS IN CITIES", props);
    return (
        <div>
            <button type="button" className="btn btn-primary" data-toggle="modal" data-target={`#topCitiesModal-${props.index}`}>
                {props.country}
            </button>

            <div className="modal fade" id={`topCitiesModal-${props.index}`} tabIndex="-1" role="dialog" aria-labelledby={`topCitiesModalLabel-${props.index}`} aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id={`topCitiesModalLabel-${props.index}`}>Top Users Cities</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>City</th>
                                        <th>Users</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.data && props.data.length > 0 && props.data.map((cityData, index) => (
                                        <tr key={index}>
                                            <td>{cityData.city}</td>
                                            <td>{cityData.users}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CitiesModal;
