import React, { useEffect, useState,useRef } from "react";
 import { useNavigate, useLocation } from "react-router-dom";
// import countryList from "../auth/CountriesList";
// import ShowToast from "../../utils/show-toast";
import { Spin } from "antd";
import { useDispatch } from "react-redux";
// import { register } from "../../redux/actions/auth";
// import { decrypt } from "../../utils/encryption";
// import countryList from "./CountriesList";

// import moment from "moment";
import { fetchSessionsParams, updateSessionsParams} from "../../../redux/actions/admin/admin";


export const useMountEffect = (fun) => useEffect(fun, []);

// Gneral Focus Hook
const UseFocus = () => {
	const htmlElRef = useRef(null)
	const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}

	return [ htmlElRef,  setFocus ]
}

const AddClinicalAdmin = () => {
  // const data = useSelector((state) => state.user.userDetails);

  const history = useNavigate();
  const [input1Ref, setInput1Focus] = UseFocus();
  const [isLoading, setIsLoading] = useState(false);
  const [ovsRate, setOvsRate] = useState("");
  const [intermissionTime, setIntermissionTime] = useState();
  const [pointSampling, setPointSampling] = useState("");
  const [safeGuardVoltage, setSafeGuardVoltage] = useState("");
  const [measuringPeriod, setMeasuringPeriod] = useState("");
  const [noOfPoints, setNoOfPoints] = useState("");
  const [samplingPointDelay, setSamplingPointDelay] = useState("");
  const [noOfMeasurement, setNoOfMeasurement] = useState("");
  const [email, setEmail] = useState("");
  const [idAvail, setIdAvail] = useState(null);


  const dispatch = useDispatch();

useEffect(() => {
fetchingSessionsParams();
// eslint-disable-next-line react-hooks/exhaustive-deps
},[])


// getting paramas
const fetchingSessionsParams=async()=>{
  let result = await dispatch(fetchSessionsParams());


  if(result.status === 200) {
    // // console.log("RESULT", result);
    let data = result.data.data;
    setOvsRate(data.ovsRate);
    setIntermissionTime(data.intermissionTime);
    setPointSampling(data.pointSampling);
    setSafeGuardVoltage(data.safeGuardVoltage);
    setMeasuringPeriod(data.measuringPeriod);
    setNoOfPoints(data.noOfPoints);
    setSamplingPointDelay(data.samplingPointDelay);
    setNoOfMeasurement(data.noOfMeasurement);
    setEmail(data.email);

    // updateFormat();

  }
}
// const updateFormat=()=>{
//   // let element = document.getElementById('dob');
//   // // // // console.log("ELEMENT", element);
//     // element.setAttribute(
//     //     "data-date",
//     //     moment(element.value, "YYYY-MM-DD")
//     //     .format( this.getAttribute("data-date-format") )
//     // )
//
// }

  const handleUserUpdate = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);

      let result = await dispatch(
        updateSessionsParams({
          ovsRate:ovsRate,
          intermissionTime:intermissionTime,
          pointSampling:pointSampling,
          safeGuardVoltage:safeGuardVoltage,
          measuringPeriod:measuringPeriod,
          noOfPoints:noOfPoints,
          samplingPointDelay:samplingPointDelay,
          noOfMeasurement:noOfMeasurement,
          email:email,

        })
      );
      setIsLoading(false);



      // ShowToast(result.data.message, "success");
    } catch (err) {
      ////(err.response)

      // ShowToast(err.response.data.message, "error");
      setIsLoading(false);
    }
  };
  const validate=(val)=> {
    // // console.log("Val", val);
  let v1 = document.getElementById("name");


  let v2 = document.getElementById("authorisation");
  let v3 = document.getElementById("dob");
  let v4 = document.getElementById("mob");
  let v5 = document.getElementById("address_line_1");
  let v6 = document.getElementById("address_line_2");
  let v7 = document.getElementById("postalcode");
  let v8 = document.getElementById("countrycode");
  let v9 = document.getElementById("email");



  // let v6 = document.getElementById("ans");

  let flag1 = true;
  let flag2 = true;
  let flag3 = true;
  let flag4 = true;
  let flag5 = true;
  let flag6 = true;
  let flag7 = true;
  let flag8 = true;
  let flag9 = true;



  if(val===1) {
  if(v1.value === "") {
  v1.style.borderColor = "#b78181";
  flag1 = false;
  }
  else {
  v1.style.borderColor = "#1f3c90";
  flag1 = true;
  }
  }

  if(val===2) {
  if(v2.value === "") {
  v2.style.borderColor = "#b78181";
  flag2 = false;
  }
  else {
  v2.style.borderColor = "#1f3c90";
  flag2 = true;
  }
  }
  if(val===3) {
  if(v3.value === "") {
  v3.style.borderColor = "#b78181";
  flag3 = false;
  }
  else {
  v3.style.borderColor = "#1f3c90";
  flag3 = true;
  }
  }
  if(val===4) {
  if(v4.value === "") {
  v4.style.borderColor = "#b78181";
  flag4 = false;
  }
  else {
  v4.style.borderColor = "#1f3c90";
  flag4 = true;
  }
  }
  if(val===5) {
  if(v5.value === "") {
  v5.style.borderColor = "#b78181";
  flag5 = false;
  }
  else {
  v5.style.borderColor = "#1f3c90";
  flag5 = true;
  }
  }
  if(val===6) {
  if(v6.value === "") {
  v6.style.borderColor = "#b78181";
  flag6 = false;
  }
  else {
  v6.style.borderColor = "#1f3c90";
  flag6 = true;
  }
  }
  if(val===7) {
  if(v7.value === "") {
  v7.style.borderColor = "#b78181";
  flag7 = false;
  }
  else {
  v7.style.borderColor = "#1f3c90";
  flag7 = true;
  }
  }
  if(val===8) {
  if(v8.value === "") {
  v8.style.borderColor = "#b78181";
  flag8 = false;
  }
  else {
  v8.style.borderColor = "#1f3c90";
  flag8 = true;
  }
  }
  if(val===9) {
    // // console.log("v9.val", v9);
  if(v9.value === "") {
  v9.style.borderColor = "#b78181";
  flag9 = false;
  }
  else {
  v9.style.borderColor = "#1f3c90";
  flag9 = true;
  }
  }

  let flag = flag1 && flag2 && flag3 && flag4 && flag5 && flag6 && flag7 && flag8 && flag9;

  return flag;
  }


  useMountEffect( setInput1Focus );

  const checkCliEmail = async(val) => {
    // // console.log("val", val);
    if(val!==""){
    let res = await dispatch(checkCliEmail(val));

    if(res.status && res.status === 200) {
      // // console.log("Res in Comp", res);
      setIdAvail(true);
    } else{
      setIdAvail(false);

    }

  }
  }

  return (
    <section className="row mw-100 ml-auto sessions-params mr-auto">

<div className="col-12 col-md-9 m-auto">
<form className="form-card" onSubmit={handleUserUpdate}>
<div className="card">
<div className="row justify-content-center pb-4 pt-2 text-left mw-100 m-auto">
<div className="col-12 p-3"><h4>Sessions Parameters</h4></div>

</div>

</div>
              <div className="card mt-3">
                                  <div className="row justify-content-center pb-4 pt-2 text-left">

                                  <div className="form-group col-12 col-md-5 flex-column d-flex">
                                  <label className="form-control-label">Email<span className="text-danger"> *</span></label>


                                  <input
                                  required
                                    // disabled={editMode ? false : true}
                                    defaultValue={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder="Enter Email"
                                    onBlur={(e)=>{validate(9);checkCliEmail(e.target.value)}}
                                    className="registration-form-control"


                                  />
                                  </div>



                                  <div className="form-group col-12 col-md-5 flex-column d-flex">
                                  <label className="form-control-label">ovsRate<span className="text-danger"> *</span></label>

                                  <input
                                  required
                                    // disabled={editMode ? false : true}
                                    defaultValue={ovsRate}
                                    onChange={(e) => setOvsRate(e.target.value)}
                                    type="number"
                                    id="authorisation"
                                    name="ovsRate"
                                    placeholder="Enter your name"
                                    onBlur={()=>validate(2)}
                                    className="registration-form-control"


                                  />
                                  </div>



                                  </div>
                                  <div className="row justify-content-center pb-4 pt-2 text-left">
                                  <div className="form-group col-sm-5 flex-column d-flex"> <label className="form-control-label">PointSampling<span className="text-danger"> *</span></label>
                                   <input
                                   required
                                     // disabled={editMode ? false : true}
                                     defaultValue={pointSampling}
                                     onChange={(e) => setPointSampling(e.target.value)}
                                     type="number"
                                     id="dob" name="dob"
                                     onBlur={()=>validate(3)}
                                     className="registration-datePicker"
                                     style={{maxHeight:"45px"}}



                                   />
                                   </div>
                                      <div className="form-group col-sm-5 flex-column d-flex"> <label className="form-control-label">Safe Guard Voltage<span className="text-danger"> *</span></label>
                                      <input
                                      required
                                        // disabled={editMode ? false : true}
                                        defaultValue={safeGuardVoltage}
                                        onChange={(e) => setSafeGuardVoltage(e.target.value)}
                                        type="number"
                                        className="registration-form-control"
                                        id="mob" name="mob"
                                        placeholder=""
                                        onBlur={()=>validate(4)}

                                      />
                                       </div>

                                  </div>
                                  <div className="row justify-content-center pb-4 pt-2 text-left">
                                  <div className="form-group col-sm-5 flex-column d-flex">
                                  <label className="form-control-label">Measuring Period<span className="text-danger"> *</span></label>
                                  <input
                                  required
                                    // disabled={editMode ? false : true}
                                    defaultValue={measuringPeriod}
                                    onChange={(e) => setMeasuringPeriod(e.target.value)}
                                    type="number"
                                    className="registration-form-control"
                                    id="address_line_1" name="address_line_1" placeholder="" onBlur={()=>validate(5)}


                                  />
                                  </div>
                                      <div className="form-group col-sm-5 flex-column d-flex">
                                      <label className="form-control-label">No Points <span className="text-danger"> *</span></label>
                                      <input
                                      required
                                      // disabled={editMode ? false : true}
                                      defaultValue={noOfPoints}
                                      onChange={(e) => setNoOfPoints(e.target.value)}
                                        type="number"
                                        className="registration-form-control"
                                        id="address_line_2" name="address_line_2" placeholder="" onBlur={()=>validate(6)}


                                      />
                                      </div>

                                  </div>
                                  <div className="row justify-content-center pb-4 pt-2 text-left">
                                  <div className="form-group col-sm-5 flex-column d-flex">
                                  <label className="form-control-label">Sampling Point Delay<span className="text-danger"> *</span></label>
                                  <input
                                  required
                                    // disabled={editMode ? false : true}
                                    defaultValue={samplingPointDelay}
                                    onChange={(e) => setSamplingPointDelay(e.target.value)}
                                    type="number"
                                    className="registration-form-control"
                                    id="postalcode" name="postalcode" placeholder="" onBlur={()=>validate(7)}

                                  />
                                   </div>

                                       <div className="form-group col-12 col-sm-5 flex-column d-flex">
                                       <label className="form-control-label">No Measurement<span className="text-danger"> *</span></label>
                                       <input
                                       required
                                         // disabled={editMode ? false : true}
                                         defaultValue={noOfMeasurement}
                                         onChange={(e) => setNoOfMeasurement(e.target.value)}
                                         type="number"
                                         className="registration-form-control"
                                         id="postalcode" name="postalcode" placeholder="" onBlur={()=>validate(7)}

                                       />

                                    </div>
                                    <div className="form-group col-sm-5 flex-column d-flex">
                                    <label className="form-control-label">IntermissionTime<span className="text-danger"> *</span></label>
                                    <input
                                    required
                                      // disabled={editMode ? false : true}
                                      defaultValue={intermissionTime}
                                      onChange={(e) => setIntermissionTime(e.target.value)}
                                      type="number"
                                      id="name"
                                      name="name"
                                      placeholder="Enter your name"
                                      onBlur={()=>validate(1)}
                                      className="registration-form-control"


                                    />
                                    </div>

                                  </div>

                          </div>
                          <div className="row justify-content-center">
                              {/*<div className="form-group col-sm-5 flex-column d-flex">
                              <button
                               className={editMode?"text-primary registration-btn btn-primary":"registration-btn btn-primary"}
                               type="button"
                                onClick={() => {
                                  setEditMode(!editMode);
                                }}
                              >
                                Edit Profile?
                              </button>
                              </div>*/}
                              <div className="form-group col-sm-6 flex-column d-flex mt-3">
                              <Spin spinning={isLoading}>
                                <button
                                  type="Submit"
                                  className={`registration-btn`}
                                >
                                  Submit
                                </button>
                              </Spin>
                              </div>
                          </div>
                          </form>

                          </div>

    </section>

  );
};

export default AddClinicalAdmin;
