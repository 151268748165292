import React from "react";
// import { useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom';

import GlucoRxLogo from "../../Assets/Logo/GlucoRxLogo.svg";


import LoadingIcon from "../../Assets/loading.gif";

// import { useLocation } from 'react-router-dom';


const LoadingEmail = () => {
  // const location = useLocation();

  return (
    <div className= "col-12 col-sm-6 col-md-4 m-auto text-center">
      <div className="row mw-100 m-auto loading">
        <div className="col-12">
        <img src={GlucoRxLogo} className="w-50 grx-logo" alt="Modus LOGO"/>

        </div>
        <div className="col-12">
          <img src={LoadingIcon} className="w-50 m-auto" alt="Loading Gif"/>
        </div>
        <div className="col-12">
        <p className="font-bold p-2">PLEASE WAIT ...</p>

        </div>

      </div>
    </div>
  );
};

export default LoadingEmail;
