import React ,{useEffect, useRef, useState} from "react";
import { BrowserRouter as Router, NavLink, useLocation } from "react-router-dom";
import {useSelector} from "react-redux";
import usersIcon from "../../Assets/Admin-sidebar/users.svg";
import engineerIcon from "../../Assets/Admin-sidebar/engineeer.svg";
import applicationIcon from "../../Assets/Admin-sidebar/application.svg";
import clinicalIcon from "../../Assets/Admin-sidebar/clinical.svg";
import otaIcon from "../../Assets/Admin-sidebar/ota.svg";
import notificationIcon from "../../Assets/Admin-sidebar/notification.svg";
import errorIcon from "../../Assets/Admin-sidebar/errors.svg";
import analyticsIcon from "../../Assets/Admin-sidebar/analytics.svg";
import { notification } from "antd";



const AdminSidebar = () => {
  const dropDownBtn = useRef(null);
    const location = useLocation();
    const {modus2} = useSelector((state) => {return (state.admin)});
    const [toggleBtn, setToggleBtn] = useState(false);
    let mod = modus2;
    useEffect(() => {
      // // console.log("modus2 sidebar", mod);
    }, [mod]);
    // // console.log("MODUS2", modus2);

    const dropDownFunc =(e, sib)=>{
      if(toggleBtn === true){
        setToggleBtn(false);
      } else {
        setToggleBtn(true);

      }
      // // console.log("Event", e);
    e.target.classList.toggle("is-active");
    // // console.log("dropDownBtn", dropDownBtn.current.nextElementSibling);
    let dropdownContent = dropDownBtn.current.nextElementSibling;
    // // console.log("dropdownContent", dropdownContent);
    if (dropdownContent.classList.contains("d-none")) {
      dropdownContent.classList.remove('d-none');

      dropdownContent.classList.add('d-flex');

    } else {
      dropdownContent.classList.remove('d-flex');

      dropdownContent.classList.add('d-none');

    }
  }

  return (

    <nav className="navbar sticky-top navbar-expand navbar-primary bg-primary flex-md-column flex-row align-items-start py-2 px-2">
    <div className="collapse navbar-collapse align-items-start w-100">
        <ul className="flex-md-column flex-row navbar-nav w-100 justify-content-between text-white">

            <li className="nav-item">
                <NavLink to="/admin/users"  className={location.pathname==="/admin/users" || location.pathname === "/dashboard"?"is-active nav-link  px-2" : "nav-link  px-2"} > <img src={usersIcon} alt="user management"/><span className="d-none d-md-inline  ml-3">User Management</span></NavLink>
            </li>
            <li className="nav-item">
                <NavLink to={`/admin/EngineerData/:modus`}  className={location.pathname.includes("/admin/EngineerData")?"is-active nav-link  px-2" : "nav-link  px-2"} > <img src={engineerIcon} alt="engineer"/><span className="d-none d-md-inline  ml-3">Engineer Data</span></NavLink>
            </li>
            <li className="nav-item">
                <NavLink to={`/admin/applicationLogs/:modus`}  className={location.pathname.includes("/admin/applicationLogs/")?"is-active nav-link  px-2" : "nav-link  px-2"} > <img src={applicationIcon} alt="application"/><span className="d-none d-md-inline  ml-3">Application logs</span></NavLink>
            </li>

            <li className="nav-item">
            {/* <img src={clinicalIcon} alt="clinical data"/> */}
            <button className={location.pathname==="/admin/Clinics"?"is-active nav-link text-left px-2 w-100 text-left admin-side-btn" : "nav-link w-100 px-2 text-left  bg-transparent admin-side-btn"} ref={dropDownBtn}  onClick={(e)=>dropDownFunc(e, )}><img  src={clinicalIcon} alt="clinical data"/> <span className="col-6 ml-2"> Clinical Data</span>
              <i className={ toggleBtn === true ?"fa fa-caret-down col-2": "fa fa-caret-up col-2"}></i>
            </button>
            <div className="dropdown-container d-none">
            <div className="row w-100 m-auto">
            <div className="col-12">
              <NavLink to="/admin/Clinics/List"  className={location.pathname==="/admin/Clinics/List"?"is-active nav-link  px-2" : "nav-link  px-2"} > <i className="fas fa-users-medical"></i><span className="d-none d-md-inline  ml-3">Clinical Trials</span></NavLink>
            </div>
            <div className="col-12">
              <NavLink to="/admin/Clinics/SessionsParams"  className={location.pathname==="/admin/Clinics/SessionsParams"?"is-active nav-link  px-2" : "nav-link  px-2"} > <i className="fas fa-users-medical"></i><span className="d-none d-md-inline  ml-3">Session Params</span></NavLink>
            </div>
            <div className="col-12">
              <NavLink to="/admin/Clinics/AllSubjects"  className={location.pathname==="/admin/Clinics/AllSubjects"?"is-active nav-link  px-2" : "nav-link  px-2"} > <i className="fas fa-users-medical"></i><span className="d-none d-md-inline  ml-3">All Subjects</span></NavLink>
            </div>
            </div>

            </div>
            </li>
            <li className="nav-item">
                <NavLink to="/admin/ota"  className={location.pathname==="/admin/ota"?"is-active nav-link  px-2" : "nav-link  px-2"} > <img src={otaIcon} alt="ota "/><span className="d-none d-md-inline  ml-3">OTA</span></NavLink>
            </li>
            <li className="nav-item">
                <NavLink to="/admin/Analytics"  className={location.pathname==="/admin/Analytics"?"is-active nav-link  px-2" : "nav-link  px-2"} > <img src={analyticsIcon} alt="analytics"/><span className="d-none d-md-inline  ml-3">Analytics</span></NavLink>
            </li>
            <li className="nav-item">
                <NavLink to="/admin/notifications"  className={location.pathname==="/admin/notifications"?"is-active nav-link  px-2" : "nav-link  px-2"} > <img src={notificationIcon} alt="notifications"/><span className="d-none d-md-inline  ml-3">Notifications</span></NavLink>
            </li>
            <li className="nav-item">
                <NavLink to="/admin/Errors"  className={location.pathname==="/admin/Errors"?"is-active nav-link  px-2" : "nav-link  px-2"} ><img src={errorIcon} alt="errors"/><span className="d-none d-md-inline  ml-3">Errors</span></NavLink>
            </li>



        </ul>
    </div>
</nav>
  );
};

export default AdminSidebar;
