import React from 'react';
import CompareOverlayLeft from './CompareOverlayLeft';
import CompareOverlayRight from './CompareOverlayRight';


const CompareOverlay = () => {
    return (
        <div className='container-fluid'>
            <div className= "row mw-100 m-auto">
              <div className="col-12 col-md-6">
              <CompareOverlayLeft/>
              </div>
              <div className="col-12 col-md-6">
              <CompareOverlayRight/>

              </div>

            </div>


        </div>
    );
};

export default CompareOverlay;
