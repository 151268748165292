import React, {useEffect} from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import '@amcharts/amcharts4/charts';
import moment from "moment";
function ActivityChart(props) {

// console.log("PROPS ACTI: ", props);
  useEffect(() => {
    // let x = am4core.create("chartdiv", am4charts.XYChart);
    //
    // // ...
    // chart.current = x;

    let chart = am4core.create("ActivityDiv", am4charts.XYChart);

// Add data
chart.data = props.data && props.data.length>0 && props.data.map((rec)=>{
  let obj = {
    time: moment(rec.time).format("DD-MM-YYYY"),
    steps: rec.value,
    conTime: moment(rec.time).format("HH:mm")
  }
  return obj;
});

// Create axes
let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
categoryAxis.dataFields.category = "time";
categoryAxis.renderer.grid.template.location = 0;
categoryAxis.renderer.minGridDistance = 30;

categoryAxis.renderer.labels.template.adapter.add("dy", function(dy, target) {
  if (target.dataItem && target.dataItem.index & 2 == 2) {
    return dy + 25;
  }
  return dy;
});

let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
valueAxis.title.text = "Steps";
// Create series
let series = chart.series.push(new am4charts.ColumnSeries());
series.dataFields.valueY = "steps";
series.dataFields.categoryX = "time";
series.name = "Activities";
series.columns.template.tooltipText = "{conTime}: [bold]{valueY}[/]";
series.columns.template.fillOpacity = .8;

let columnTemplate = series.columns.template;
columnTemplate.strokeWidth = 2;
columnTemplate.strokeOpacity = 1;



// Add legend
// chart.legend = new am4charts.Legend();

// Add cursor
// chart.cursor = new am4charts.XYCursor();

    return () => {
      chart.dispose();
    };
  }, [props.data]);

  return (
    <div id="ActivityDiv" style={{ width: "100%", height: "500px" }}></div>
  );
}

export default ActivityChart;
