import React, { useEffect, useState } from "react";
import {useSelector} from 'react-redux';

// import { useLocation } from 'react-router-dom';
import Loading from '../../Common/Loading';
import * as _ from 'lodash';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


am4core.useTheme(am4themes_animated);
// Themes end
function OgpChart(props) {
  const [showGraph, setShowGraph]=useState(false);
  const {user} = useSelector((state)=>{return state});
  // console.log("PROPS", props);

  // let chart;
  // const location = useLocation();

  useEffect(() => {


    let chart = am4core.create("AGP", am4charts.XYChart);

// Add data

let fineData=[];
let median=[];
let highlighted=[];
let quarter=[];
let data = props.data;

  data && data.length > 0 && data.forEach((record,index) => {


  let recordLength = record && record.length>0? record.length:null;


  let second= Math.ceil(recordLength * 0.25);

  let third= Math.ceil(recordLength * 0.75);

  let first= Math.ceil(recordLength * 0.10);

  let last= Math.ceil(recordLength * 0.90);

  let secQuarter = record && record.length>0?record[second-1].value:0;

  let thirdQuarter = record && record.length>0?record[third-1].value:0;

  let firstQuarter = record && record.length>0?record[first-1].value:0;

  let lastQuarter = record && record.length>0?record[last-1].value:0;

  let quarterObj = {
    x:index,
    startPoint:secQuarter,
    endPoint: thirdQuarter,
  }

  quarter.push(quarterObj);

  let upperLayer = {
    x:index,
    startPoint:firstQuarter,
    endPoint: lastQuarter,
}

  highlighted.push(upperLayer);

  if(record && record.length>0 && recordLength%2 === 0){
    let m1= record && record.length>0 ? record[(recordLength/2)-1]:null;
    let m2=record && record.length>0?record[recordLength/2]:null;
    


    let glucose = m1 && m1.value && m2 && m2.value?((m1.value + m2.value)/2):null;
    let organizedObj={
      x:index,
      glucose: _.round(glucose, 1).toFixed(1),
    }
    median.push(organizedObj);




  }else if(record && record.length>0 && recordLength%2 !== 0) {
    
    let indexForActualObj = Math.floor(record.length/2);
   
    let actualObj = record[indexForActualObj];


    let medObj = {
      x: index,
      time: new Date(actualObj.time),
      glucose: actualObj.value
    }
    median.push(medObj);
  } else {
    let medObj = {
      x: index,
      time: 0,
      glucose: 0,
    }
    median.push(medObj);
  }
  record.forEach((inner)=>{
    fineData.push({
      x: index,
      time: new Date(inner.time),
      glucose: inner.value
    });
  });
});
chart.data = fineData;
console.log("CHART DATA :", chart.data);

// let dataLength = chart.data.length - 1;
// let startTime = chart.data && chart.data.length>0?chart.data[0].time: null;
// let endTime = chart.data && chart.data.length>0?chart.data[dataLength].time:null;

// chart.dateFormatter.dateFormat = "dd-MM-yyyy";
  // Create axes
let xAxis = chart.xAxes.push(new am4charts.ValueAxis());
xAxis.min = 0;
xAxis.max = 23;

xAxis.renderer.minGridDistance = 50;
xAxis.adjustLabelPrecision = false;
// dateAxis.startLocation = 0.5;
// dateAxis.endLocation = 0.5;
// dateAxis.dateFormatter = new am4core.DateFormatter();
// dateAxis.dateFormatter.dateFormat = "dd-YYYY";
// dateAxis.groupData = true;
// dateAxis.groupCount = 3000;
// dateAxis.zoomToDates(new Date(startTime), new Date(endTime));

// chart.preloader.disabled = true;

// Create value axis
let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
valueAxis.min = 0;
valueAxis.title.text = user.userDetails && user.userDetails.user && user.userDetails.user.unit === 0?"mmol/L":"mgdl";

// Create series
// chart.data.forEach((obj, index)=>{

// let lineSeries = chart.series.push(new am4charts.LineSeries());
// lineSeries.dataFields.valueY = "glucose";
// lineSeries.dataFields.valueX = "x";
// lineSeries.strokeWidth = 44;
// lineSeries.fillOpacity = 0;
// lineSeries.sequencedInterpolation = false;
// lineSeries.defaultState.transitionDuration = 1000;
// lineSeries.tensionX = 0.9;
// lineSeries.data= chart.data;

// let bullet = lineSeries.bullets.push(new am4core.Circle());
//     bullet.stroke = am4core.color("#fff");
//     bullet.radius = 4;
//     bullet.fill = ("#2a93fc");
    // bullet.tooltipText = "{dateX}:  {valueY}";
  // })







  let lineSeries2 = chart.series.push(new am4charts.LineSeries());
  lineSeries2.dataFields.valueY = "glucose";
  lineSeries2.dataFields.valueX = "x";
  lineSeries2.strokeWidth = 2;
  lineSeries2.fillOpacity = 0;
  lineSeries2.stroke="#00007a";
  lineSeries2.fill="#00007a"
  lineSeries2.sequencedInterpolation = false;
  lineSeries2.defaultState.transitionDuration = 1000;
  lineSeries2.tensionX = 0.8;
  lineSeries2.data=median;
  lineSeries2.name= "50% (Median)";
  // lineSeries2.legendSettings.color="#00007a"



  let series3 = chart.series.push(new am4charts.LineSeries());
  series3.data=quarter;
  series3.dataFields.valueX = "x";
  series3.dataFields.openValueY = "startPoint";
  series3.dataFields.valueY = "endPoint";
  series3.tooltipText = "open: {openValueY.value} close: {valueY.value}";
  // series3.sequencedInterpolation = true;
  series3.fillOpacity = 0.3;
  series3.defaultState.transitionDuration = 1000;
  series3.tensionX = 0.8;
  series3.name= "25%-75%";
  series3.fill="#6771dc"



  let series = chart.series.push(new am4charts.LineSeries());
  series.data=highlighted;
  series.dataFields.valueX = "x";
  series.dataFields.openValueY = "startPoint";
  series.dataFields.valueY = "endPoint";
  series.tooltipText = "open: {openValueY.value} close: {valueY.value}";
  series.sequencedInterpolation = true;
  series.fillOpacity = 0.3;
  series.defaultState.transitionDuration = 1000;
  series.tensionX = 0.8;
  series.name= "10%-90%";
  series.fill="#6794dc";


  // let series2 = chart.series.push(new am4charts.LineSeries());
  // series2.data=highlighted;
  //
  // series2.dataFields.valueX = "x";
  // series2.dataFields.valueY = "startPoint";
  // series2.sequencedInterpolation = true;
  // series2.defaultState.transitionDuration = 1500;
  // series2.stroke = "#6794dc";
  // series2.tensionX = 0.8;









  // let series4 = chart.series.push(new am4charts.LineSeries());
  // series4.data=quarter;
  //
  // series4.dataFields.valueX = "x";
  // series4.dataFields.valueY = "startPoint";
  // // series4.sequencedInterpolation = true;
  // series4.defaultState.transitionDuration = 1500;
  // series4.stroke = "#8067dc";
  // series4.tensionX = 0.8;







chart.scrollbarX = new am4core.Scrollbar();
chart.scrollbarX.parent = chart.bottomAxesContainer;

chart.scrollbarX.thumb.minWidth = 5;
chart.scrollbarX.startGrip.disabled = true;
chart.scrollbarX.endGrip.disabled = true;

chart.scrollbarY = new am4core.Scrollbar();
chart.scrollbarY.parent = chart.rightAxesContainer;

chart.scrollbarY.thumb.minWidth = 5;
chart.scrollbarY.startGrip.disabled = true;
chart.scrollbarY.endGrip.disabled = true;
// chart.scrollbarX.startGrip.width=10;
// chart.scrollbarX.endGrip.width=10;

let range = valueAxis.axisRanges.create();
// range.value = user.userDetails.low;
range.value = props.low;

// range.endValue = user.userDetails.high;
range.endValue = props.high;

range.axisFill.fill = am4core.color("#1f3c9078");
range.axisFill.fillOpacity = 0.2;
range.grid.strokeOpacity = 0;

let range1 = valueAxis.axisRanges.create();
// range1.value = user.userDetails.high;
range1.value = props.high;

range1.endValue = 30;
range1.axisFill.fill = am4core.color("#ffff008c");
range1.axisFill.fillOpacity = 0.2;
range1.grid.strokeOpacity = 0;

chart.cursor = new am4charts.XYCursor();
chart.cursor.behavior= 'zoomXY';

chart.legend = new am4charts.Legend();
chart.legend.useDefaultMarker = true;

chart.events.on('ready', () => {
  setShowGraph(true);
});
return () => {
      chart.dispose();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [props.data, props.high, props.low]);

  return (<div className="w-100">
  <div className={showGraph?"d-none":" w-100"}>                    <Loading/>
</div>
        <div id="AGP" className={showGraph?"":"d-none"} style={{ width: "100%", minHeight: "550px" }}></div>
  </div>
  );
}
export default OgpChart;
