// import React, {useEffect} from 'react';
// import * as am4core from '@amcharts/amcharts4/core';
// import * as am4charts from '@amcharts/amcharts4/charts';
// import '@amcharts/amcharts4/charts';
// function UserActivenessChart(props) {

// // console.log("PROPS ACTI: ", props);
//   useEffect(() => {


// // Create chart instance
// let chart = am4core.create("userAct", am4charts.XYChart);

// // Export
// chart.exporting.menu = new am4core.ExportMenu();

// // Data for both series
// let data = [ 
//   {
//   "year": "Active",
//   "income": 30.6,
//   "expenses": 28.2,
//   "lineDash": "5,5",
// }, {
//   "year": "Non Active",
//   "income": 34.1,
//   "expenses": 32.9,
//   "strokeWidth": 1,
//   "columnDash": "5,5",
//   "fillOpacity": 0.2,
//   "additional": "(projection)"
// } ];

// /* Create axes */
// let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
// categoryAxis.dataFields.category = "year";
// categoryAxis.renderer.minGridDistance = 30;

// /* Create value axis */
// let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

// /* Create series */
// let columnSeries = chart.series.push(new am4charts.ColumnSeries());
// columnSeries.name = "Income";
// columnSeries.dataFields.valueY = "income";
// columnSeries.dataFields.categoryX = "year";

// columnSeries.columns.template.tooltipText = "[#fff font-size: 15px]{name} in {categoryX}:\n[/][#fff font-size: 20px]{valueY}[/] [#fff]{additional}[/]"
// columnSeries.columns.template.propertyFields.fillOpacity = "fillOpacity";
// columnSeries.columns.template.propertyFields.stroke = "stroke";
// columnSeries.columns.template.propertyFields.strokeWidth = "strokeWidth";
// columnSeries.columns.template.propertyFields.strokeDasharray = "columnDash";
// columnSeries.tooltip.label.textAlign = "middle";



// chart.data = data;



// // Add legend
// // chart.legend = new am4charts.Legend();

// // Add cursor
// // chart.cursor = new am4charts.XYCursor();

//     return () => {
//       chart.dispose();
//     };
//   }, [props.data]);

//   return (
//     <div id="userAct" style={{ width: "100%", height: "500px" }}></div>
//   );
// }

// export default UserActivenessChart;

// new version

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import '@amcharts/amcharts4/themes/animated';

function UserActivenessChart() {
  const activeUsersData = useSelector((state) => state.analytics.activeUsers);

  useEffect(() => {
    // Create chart instance
    let chart = am4core.create('userAct', am4charts.XYChart);

    // Export menu
    chart.exporting.menu = new am4core.ExportMenu();

    // Transform data to consolidate active and inactive users for each date
    let dataMap = {};
    activeUsersData && activeUsersData.length>0 && activeUsersData.forEach(item => {
      const { date, active, inActive } = item;
      if (!dataMap[date]) {
        dataMap[date] = { date, active: 0, inActive: 0 };
      }
      if (active !== undefined) dataMap[date].active = active;
      if (inActive !== undefined) dataMap[date].inActive = inActive;
    });

    let data = Object.values(dataMap);
    console.log('Data: ', data);

    chart.data = data;

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'date';
    categoryAxis.renderer.minGridDistance = 30;

    // Create value axis
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

    // Create active users series
    let activeSeries = chart.series.push(new am4charts.ColumnSeries());
    activeSeries.name = 'Active Users';
    activeSeries.dataFields.valueY = 'active';
    activeSeries.dataFields.categoryX = 'date';
    activeSeries.columns.template.tooltipText = '[#fff font-size: 15px]{name} on {categoryX}:\n[/][#fff font-size: 20px]{valueY}[/]';
    activeSeries.columns.template.propertyFields.fillOpacity = 'fillOpacity';
    activeSeries.columns.template.propertyFields.stroke = 'stroke';
    activeSeries.columns.template.propertyFields.strokeWidth = 'strokeWidth';
    activeSeries.columns.template.propertyFields.strokeDasharray = 'columnDash';
    activeSeries.tooltip.label.textAlign = 'middle';

    // Create inactive users series
    let inactiveSeries = chart.series.push(new am4charts.ColumnSeries());
    inactiveSeries.name = 'Inactive Users';
    inactiveSeries.dataFields.valueY = 'inActive';
    inactiveSeries.dataFields.categoryX = 'date';
    inactiveSeries.columns.template.tooltipText = '[#fff font-size: 15px]{name} on {categoryX}:\n[/][#fff font-size: 20px]{valueY}[/]';
    inactiveSeries.columns.template.propertyFields.fillOpacity = 'fillOpacity';
    inactiveSeries.columns.template.propertyFields.stroke = 'stroke';
    inactiveSeries.columns.template.propertyFields.strokeWidth = 'strokeWidth';
    inactiveSeries.columns.template.propertyFields.strokeDasharray = 'columnDash';
    inactiveSeries.tooltip.label.textAlign = 'middle';

    // Add legend
    chart.legend = new am4charts.Legend();

    // Add cursor
    chart.cursor = new am4charts.XYCursor();

    // Cleanup
    return () => {
      chart.dispose();
    };
  }, [activeUsersData]);

  return (
    <div id="userAct" style={{ width: '100%', height: '500px' }}></div>
  );
}

export default UserActivenessChart;
