import React ,{useEffect} from "react";
import { BrowserRouter as Router, NavLink, useLocation } from "react-router-dom";
import {useSelector} from "react-redux";

const ClinicalSidebar = (props) => {
  // // console.log("PROPS :", props);
    const location = useLocation();
    const {modus2} = useSelector((state) => {return (state.admin)});
    let mod = modus2;
    useEffect(() => {
      // // console.log("modus2 sidebar", mod);
    }, [mod]);
    // // console.log("MODUS2", modus2);


  return (

    <nav className="navbar sticky-top navbar-expand navbar-primary bg-primary flex-md-column flex-row align-items-start py-2 px-2">
    <div className="collapse navbar-collapse align-items-start w-100">
        <ul className="flex-md-column flex-row navbar-nav w-100 justify-content-between text-white">
        {props.cAdmin===true?
        <li className="nav-item">
            <NavLink to="/dashboard"   className={location.pathname==="/admin/Clinics"|| location.pathname === "/dashboard"?"is-active nav-link  px-2" : "nav-link  px-2"} > <i className="fas fa-users-medical"></i><span className="d-none d-md-inline  ml-3">Clinical Data</span></NavLink>
        </li>
        :null}
        {props.eAdmin===true?
            <li className="nav-item">
                <NavLink to={`/admin/EngineerData/:${modus2}`}  className={location.pathname.includes("/admin/EngineerData") ?"is-active nav-link  px-2" : "nav-link  px-2"} > <i className="fa-solid fa-helmet-safety"></i><span className="d-none d-md-inline  ml-3">Engineer Data</span></NavLink>
            </li>
            :null}
            {props.eAdmin===true?

                <li className="nav-item">
                    <NavLink to={`/admin/applicationLogs/:modus`}  className={location.pathname.includes("/admin/applicationLogs/")?"is-active nav-link  px-2" : "nav-link  px-2"} > <i className="fa-solid fa-sensor"></i><span className="d-none d-md-inline  ml-3">Application logs</span></NavLink>
                </li>
                :null}
            {props.cAdmin===true?

            <li className="nav-item">
                <NavLink to="/SessionsParams"   className={location.pathname==="/SessionsParams"?"is-active nav-link  px-2" : "nav-link  px-2"} > <i className="fas fa-users-medical"></i><span className="d-none d-md-inline  ml-3">Sessions Parameters</span></NavLink>
            </li>
            :null}
            {props.cAdmin===true?

            <li className="nav-item">
                <NavLink to="/Subjects"   className={location.pathname==="/Subjects"?"is-active nav-link  px-2" : "nav-link  px-2"} > <i className="fas fa-users-medical"></i><span className="d-none d-md-inline  ml-3">Subjects</span></NavLink>
            </li>
            :null}





        </ul>
    </div>
</nav>
  );
};

export default ClinicalSidebar;
