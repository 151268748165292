export const DefaultTitle = "MODUS";

export default {
  default: `${DefaultTitle} | Welcome`,
  login: `${DefaultTitle} | Login`,
  userManagement: `${DefaultTitle} | User Management`,
  trialsSubjects: `${DefaultTitle} | Subjects`,
  clinicalTrials: `${DefaultTitle} | Clinical Trials`,

  engineerData: `${DefaultTitle} | Engineers`,
  sessions: `${DefaultTitle} | Sessions`,
  sessionParams: `${DefaultTitle} | Session Parameters`,
  allSubjects: `${DefaultTitle} | All Subjects`,
  clinicalDashboard: `${DefaultTitle} | Clinical Dashboard`,
  // tialsSubjects: `${DefaultTitle} | Trinal's Subjects`,





  // register: `${DefaultTitle} | Register`,
  // dashboard: `${DefaultTitle} | Dashboard`,
  // history: `${DefaultTitle} | History`,
  // emergency: `${DefaultTitle} | Emergency`,
  // forgotPassword: `${DefaultTitle} | Forgot Password`,
  // resendVerificationEmail: `${DefaultTitle}  | Resend Verification Email`,
};
