import {
  API_BASE_URL,
  getEngineeringData,
  getSensorLogsData,
  getSessionDataUrl,
  getMeasurementDataUrl,
  getPointDataUrl,

  sensorLogsUrl,
  getSubjects,
  addSubjectsUrl,
  stopClinicalTrialUrl,
  stopSubjectFromCTUrl,
addSubToClinicalTrialUrl } from "../../../utils/urls";
import {
  FETCH_ADMIN_START,
  FETCH_ADMIN_FAILED,
  FETCH_ADMIN_SUCCESS,
  FETCH_CLINICIAN_START,
  FETCH_CLINICIAN_FAILED,
  FETCH_CLINICIAN_SUCCESS,
  FETCH_ENGINEER_START,
  FETCH_ENGINEER_FAILED,
  FETCH_ENGINEER_SUCCESS,
  FETCH_PATIENT_START,
  FETCH_PATIENT_FAILED,
  FETCH_PATIENT_SUCCESS,
  ADD_ENGINEER_START,
  ADD_ENGINEER_FAILED,
  ADD_ENGINEER_SUCCESS,
  SAVE_APP_SUCCESS,
  SAVE_APP_MODUS_SUCCESS,

  FETCH_ENGINEER_DATA_START,
  FETCH_ENGINEER_DATA_FAILED,
  FETCH_ENGINEER_DATA_SUCCESS,
  FETCH_SENSOR_LOGS_DATA_START,
  FETCH_SENSOR_LOGS_DATA_FAILED,
  FETCH_SENSOR_LOGS_DATA_SUCCESS,
  FETCH_ENGINEER_SESSIONS_DATA_START,
  FETCH_ENGINEER_SESSIONS_DATA_FAILED,
  FETCH_ENGINEER_SESSIONS_DATA_SUCCESS,

  ADD_CLINICIAN_START,
  ADD_CLINICIAN_FAILED,
  ADD_CLINICIAN_SUCCESS,

  ADD_CLINICAL_ADMIN_START,
  ADD_CLINICAL_ADMIN_FAILED,
  ADD_CLINICAL_ADMIN_SUCCESS,

  FETCH_CLINICIANS_START,
  FETCH_CLINICIANS_FAILED,
  FETCH_CLINICIANS_SUCCESS,

  FETCH_SUBJECTS_START,
  FETCH_SUBJECTS_FAILED,
  FETCH_SUBJECTS_SUCCESS,

  FETCH_ALL_SUBJECTS_START,
  FETCH_ALL_SUBJECTS_FAILED,
  FETCH_ALL_SUBJECTS_SUCCESS,

  FETCH_CLINICIANS_SESSIONS_DATA_START,
  FETCH_CLINICIANS_SESSIONS_DATA_FAILED,
  FETCH_CLINICIANS_SESSIONS_DATA_SUCCESS,

  FETCH_SESSIONS_PARAMS_START,
  FETCH_SESSIONS_PARAMS_FAILED,
  FETCH_SESSIONS_PARAMS_SUCCESS,


} from "../../actionTypes/admin";
import http from "../../../utils/http-service";

import ShowToast from "../../../utils/show-toast";

import {encrypt, decrypt} from "../../../utils/encryption";

export const getPatients = (patient, app) => async(dispatch) => {

  // role = JSON.stringify(role);
  let user = await encrypt(patient);
  let encApp = await encrypt(app);
  dispatch({type: FETCH_PATIENT_START})
  try {

    const res = await http.get(`${API_BASE_URL}/admin/e_getUsers?role=${user}&app=${encApp}`);
    let data = res.data.data;
    data = await decrypt(data);
    data = JSON.parse(data);
    dispatch({type: FETCH_PATIENT_SUCCESS, payload: data.users})
    // ShowToast(res.data.message,"success");
    return res;
  } catch (error) {
    dispatch({type: FETCH_PATIENT_FAILED});
    ShowToast(error.message,"error");

  }
}

export const getAdmins = (admin,app) => async(dispatch) => {
  let user = await encrypt(admin);
  let encApp = await encrypt(app);
  // role = JSON.stringify(role);
  dispatch({type: FETCH_ADMIN_START})
  try {

    const res = await http.get(`${API_BASE_URL}/admin/e_getUsers?role=${user}&app=${encApp}`);
    let data = res.data.data;
    data = await decrypt(data);
    data = JSON.parse(data);

    dispatch({type: FETCH_ADMIN_SUCCESS, payload: data.users})
    // ShowToast(res.data.message,"success");
    return res;
  } catch (error) {
    dispatch({type: FETCH_ADMIN_FAILED});
    return false;
    // ShowToast(error.message,"error");

  }
}

export const getClinicians = (clinician, app) => async(dispatch) => {
  let user = await encrypt(clinician);
  let encApp = await encrypt(app);
  // role = JSON.stringify(role);
  dispatch({type: FETCH_CLINICIAN_START})
  try {

    const res = await http.get(`${API_BASE_URL}/admin/e_getUsers?role=${user}&app=${encApp}`);
    let data = res.data.data;
    data = await decrypt(data);
    data = JSON.parse(data);

    dispatch({type: FETCH_CLINICIAN_SUCCESS, payload: data.users})
    // ShowToast(res.data.message,"success");
    return res;
  } catch (error) {
    dispatch({type: FETCH_CLINICIAN_FAILED});
    ShowToast(error.message,"error");
  }
}

export const getEngineers = (engineer,app) => async(dispatch) => {
  let user = await encrypt(engineer);
  let encApp = await encrypt(app);
  // role = JSON.stringify(role);
  dispatch({type: FETCH_ENGINEER_START})
  try {

    const res = await http.get(`${API_BASE_URL}/admin/e_getUsers?role=${user}&app=${encApp}`);
    let data = res.data.data;
    data = await decrypt(data);
    data = JSON.parse(data);

    dispatch({type: FETCH_ENGINEER_SUCCESS, payload: data.users})
    // ShowToast(res.data.message,"success");
    return res;
  } catch (error) {
    dispatch({type: FETCH_ENGINEER_FAILED});

    ShowToast(error.response.data.message,"error");


  }
}

export const getSimpleEngineers = (engineer,app) => async(dispatch) => {
  let user = await encrypt(engineer);
  let encApp = await encrypt(app);
  // role = JSON.stringify(role);
  dispatch({type: FETCH_ENGINEER_START})
  try {

    const res = await http.get(`${API_BASE_URL}/admin/e_getUsers?role=${user}&app=${encApp}`);
    let data = res.data.data;
    data = await decrypt(data);
    data = JSON.parse(data);

    dispatch({type: FETCH_ENGINEER_SUCCESS, payload: data.users})
    // ShowToast(res.data.message,"success");
    return res;
  } catch (error) {
    dispatch({type: FETCH_ENGINEER_FAILED});

    ShowToast(error.response.data.message,"error");


  }
}
export const makingEngineer = (_id) => async(dispatch) => {

let encId = await encrypt(_id);
  // role = JSON.stringify(role);

  try {

    const res = await http.get(`${API_BASE_URL}/admin/e_makeEngineer?id=${encId}`);
    ShowToast(res.data.message,"success");
    return res;
    // dispatch({type: MAKE_ENGINEER_SUCCESS, payload: res.data.data.users})
  } catch (error) {
    // dispatch({type: MAKE_ENGINEER_FAILED});
    ShowToast(error.message,"error");


  }
}
export const makingEngineerAdmin = (_id) => async(dispatch) => {


  // role = JSON.stringify(role);

  try {

    const res = await http.get(`${API_BASE_URL}/admin/makeEngineerAdmin?id=${_id}`);

    ShowToast(res.data.message,"success");
    return res;
    // dispatch({type: MAKE_ENGINEER_SUCCESS, payload: res.data.data.users})
  } catch (error) {
    // dispatch({type: MAKE_ENGINEER_FAILED});
    ShowToast(error.message,"error");


  }
}

export const makingAdmin = (_id) => async(dispatch) => {


  // role = JSON.stringify(role);

  try {

    const res = await http.get(`${API_BASE_URL}/admin/makeAdmin?id=${_id}`);

    ShowToast(res.data.message,"success");
    return res;
    // dispatch({type: MAKE_ENGINEER_SUCCESS, payload: res.data.data.users})
  } catch (error) {
    // dispatch({type: MAKE_ENGINEER_FAILED});
    ShowToast(error.message,"error");


  }
}

export const makingClinicalAdmin = (_id) => async(dispatch) => {
let id = await encrypt(_id);

  // role = JSON.stringify(role);

  try {

    // const res = await http.get(`${API_BASE_URL}/admin/makeClinicalAdmin?id=${_id}`);
    const res = await http.get(`${API_BASE_URL}/admin/e_makeClinicalAdmin?id=${id}`);


    ShowToast(res.data.message,"success");
    return res;
    // dispatch({type: MAKE_ENGINEER_SUCCESS, payload: res.data.data.users})
  } catch (error) {
    // dispatch({type: MAKE_ENGINEER_FAILED});
    ShowToast(error.message,"error");


  }
}

export const makingPatient = (_id) => async(dispatch) => {


  // role = JSON.stringify(role);
let id = await encrypt(_id);
  try {

    // const res = await http.get(`${API_BASE_URL}/admin/makePatient?id=${_id}`);
    const res = await http.get(`${API_BASE_URL}/admin/e_makePatient?id=${id}`);


    ShowToast(res.data.message,"success");
    return res;
    // dispatch({type: MAKE_ENGINEER_SUCCESS, payload: res.data.data.users})
  } catch (error) {
    // dispatch({type: MAKE_ENGINEER_FAILED});
    ShowToast(error.message,"error");



  }
}

const addEngineerStart = () => {
  return { type: ADD_ENGINEER_START };
};

const addEngineerFailed = () => {
  return { type: ADD_ENGINEER_FAILED };
};

const addEngineerSuccess = (payload) => {
  return { type: ADD_ENGINEER_SUCCESS, payload };
};

export const addEngineer = (data) => async (dispatch) => {
  try {
    dispatch(addEngineerStart());
    let addEngineerData=data;

    addEngineerData=JSON.stringify(addEngineerData)
    addEngineerData =await encrypt(addEngineerData);
    // const result = await http.post(`${API_BASE_URL}/admin/addEngineerAdmin`, {...data});
    const result = await http.post(`${API_BASE_URL}/admin/e_addEngineerAdmin`, {data:addEngineerData});

    ////(result);
    if (result.status === 200) {
      let {
        data: { data },
      } = result;

      // localStorage.setItem("grxAuthToken", data.token);
      // data= decrypt(data)
      // data=JSON.parse(data)


      dispatch(addEngineerSuccess(data));
      ShowToast(result.data.message,"success");
      // showToast(err.response.data.message,"error");
      // dispatch(fetchUser());
    }

    return true;
  } catch (error) {
    ShowToast(error.message,"error");
    dispatch(addEngineerFailed());
    return false

    // throw error;
  }
};
const fetchEngineerDataStart = (email) => {
  return { type: FETCH_ENGINEER_DATA_START, payload:email };
};

const fetchEngineerDataFailed = () => {
  return { type: FETCH_ENGINEER_DATA_FAILED };
};

const fetchEngineerDataSuccess = (payload) => {
  return { type: FETCH_ENGINEER_DATA_SUCCESS, payload };
};

export const fetchEngineerData = (_id, email) => async (dispatch) => {
  try {
    dispatch(fetchEngineerDataStart(email))

    // addEngineerData=JSON.stringify(addEngineerData)
    // addEngineerData = encrypt(addEngineerData);
    // const result = await http.get(`${API_BASE_URL}/engineer/getData?id=${_id}`);

  const  result = await http.get(`${API_BASE_URL}${getEngineeringData}?id=${_id}`);


    ////(result);
    if (result.status === 200) {
      let {
        data: { data },
      } = result;

      // localStorage.setItem("grxAuthToken", data.token);
      data= decrypt(data)
      data=JSON.parse(data)


      dispatch(fetchEngineerDataSuccess(data));
      // showToast(err.response.data.message,"error");
      // dispatch(fetchUser());
    }

    return result;
  } catch (error) {

    dispatch(fetchEngineerDataFailed());
    throw error;
  }
};


// Sensor Logs Data

const fetchSensorLogsDataStart = (email) => {
  return { type: FETCH_SENSOR_LOGS_DATA_START, payload:email };
};

const fetchSensorLogsDataFailed = () => {
  return { type: FETCH_SENSOR_LOGS_DATA_FAILED };
};

const fetchSensorLogsDataSuccess = (payload) => {// // // console.log("PAYLOAD", payload);
  return { type: FETCH_SENSOR_LOGS_DATA_SUCCESS, payload };
};

export const fetchSensorLogsData = (_id, email) => async (dispatch) => {
  let encId = await encrypt(_id);
  try {
    dispatch(fetchSensorLogsDataStart(email))

    const result = await http.get(`${API_BASE_URL}${getSensorLogsData}?id=${encId}`);




      let {
        data: { data },
      } = result;
      data= await decrypt(data);
      data=JSON.parse(data);
      // console.log("sensor data", data);


      dispatch(fetchSensorLogsDataSuccess(data));



    return result;
  } catch (error) {

    dispatch(fetchSensorLogsDataFailed());
    throw error;
  }
};


// Emgineer Sessions Data
const fetchEngineerSessionsDataStart = (email) => {
  return { type: FETCH_ENGINEER_SESSIONS_DATA_START, payload:email };
};

const fetchEngineerSessionsDataFailed = () => {
  return { type: FETCH_ENGINEER_SESSIONS_DATA_FAILED };
};

export const fetchEngineerSessionsDataSuccess = (payload) => {
  // // // console.log("payload", payload);
  return { type: FETCH_ENGINEER_SESSIONS_DATA_SUCCESS, payload };
};

export const fetchEngineerSessionsData = (_id, email, app) => async (dispatch) => {
  // // console.log("APP", app);
  let id= await encrypt(_id);
  try {
    dispatch(fetchEngineerSessionsDataStart(email))
    const result = await http.get(`${API_BASE_URL}${getSessionDataUrl}?id=${id}`);
    if (result.status === 200) {
      let {
        data: { data },
      } = result;
      data = await decrypt(data);
      data=JSON.parse(data);
      dispatch(fetchEngineerSessionsDataSuccess(data));
    }

    return result;
  } catch (error) {

    dispatch(fetchEngineerSessionsDataFailed());
    throw error;
  }
};

// Emgineer Measurement Data


export const fetchEngineerMeasurementData = (_id) => async (dispatch) => {

  try {
    const result = await http.get(`${API_BASE_URL}${getMeasurementDataUrl}?id=${_id}`);
    // // // console.log("RESULT :", result);
    ////(result);
    // if (result.status === 200) {
    //   let {
    //     data: { data },
    //   } = result;
    //
    // }

    return result;
  } catch (error) {

    throw error;
  }
};



export const getLogsBySession = (_id) => async (dispatch) => {
  let encId = await encrypt(_id);
  try {

      const result = await http.get(`${API_BASE_URL}${sensorLogsUrl}?id=${encId}`);

      let {
        data: { data },
      } = result;
      data = await decrypt(data);
      data = JSON.parse(data);

    return data;
  } catch (error) {

    throw error;
  }
};



export const fetchEngineerMeasurement = (_id) => async (dispatch) => {
  let encId = await encrypt(_id);
  try {
    const result = await http.get(`${API_BASE_URL}${getMeasurementDataUrl}?id=${encId}`);

      let {
        data: { data },
      } = result;

      data = await decrypt(data);
      data = JSON.parse(data);

    return data;
  } catch (error) {

    throw error;
  }
};

export const fetchEngineerPoint = (_id) => async (dispatch) => {
  let encId = await encrypt(_id);
  try {
    const result = await http.get(`${API_BASE_URL}${getPointDataUrl}?id=${encId}`);

      let {
        data: { data },
      } = result;
    data = await decrypt(data);
    data = JSON.parse(data);

    return data;
  } catch (error) {

    throw error;
  }
};





export const saveApp=(app)=>async(dispatch) =>{

  dispatch({type: SAVE_APP_SUCCESS, payload: app})

}

export const saveModus=(app)=>async(dispatch) =>{

  dispatch({type: SAVE_APP_MODUS_SUCCESS, payload: app})

}

// To Delete specific Session with the help of id.

export const deleteSession = (_id) => async (dispatch) => {
  let listToBeDeleted = {ids:_id};
  listToBeDeleted = JSON.stringify(listToBeDeleted);
  let encList = await encrypt(listToBeDeleted);
  try {

    const  result = await http.delete(`${API_BASE_URL}/admin/e_deleteSessionData`,{data:{data:encList}});
    // const result = await http.post(`${API_BASE_URL}/clinician/e_updateSessionParams`,{data:encParam});

    // // // console.log("RESULT :", result);
    if (result.status === 200) {
      ShowToast(result.data.message,"success");


    }

    return result;
  } catch (error) {
    ShowToast(error.message,"error");

    return error;
    // throw error;
  }
};

export const engineerSessionsDataFailed = () => {
  return { type: FETCH_ENGINEER_SESSIONS_DATA_FAILED };
};


// All Clinical relevent apis after this comments

const addClinicianStart = () => {
  return { type: ADD_CLINICIAN_START };
};

const addClinicianFailed = () => {
  return { type: ADD_CLINICIAN_FAILED };
};

const addClinicianSuccess = (payload) => {
  return { type: ADD_CLINICIAN_SUCCESS, payload };
};

export const addClinicID= (data) => async(dispatch) => {
  console.log("ADD ID DATA :", data);
  let encData = data;
  encData = JSON.stringify(encData);
   encData = await encrypt(encData);

  try {
    dispatch(addClinicianStart());

    const result = await http.post(`${API_BASE_URL}/clinician/e_add`, {data:encData});

      let {
        data: { data },
      } = result;

      data=await decrypt(data);
      data=JSON.parse(data)


      dispatch(addClinicianSuccess(data));
      ShowToast(result.data.message,"success");


    return {status:200,data:data};
  } catch (error) {
    dispatch(addClinicianFailed());
    ShowToast(error.response.data.message,"error");
    return error;
  }
}



// Add Clinical Admin

const addAdminStart = () => {
  return { type: ADD_CLINICAL_ADMIN_START };
};

const addAdminFailed = () => {
  return { type: ADD_CLINICAL_ADMIN_FAILED };
};

const addAdminSuccess = (payload) => {
  return { type: ADD_CLINICAL_ADMIN_SUCCESS, payload };
};

export const addAdmin= (data) => async(dispatch) => {
  try {
    dispatch(addAdminStart());
    // let addAdminData=data;

    // addAdminData=JSON.stringify(addAdminData)
    // addAdminData = encrypt(addClinicaladminData);
    const result = await http.post(`${API_BASE_URL}/admin/addAdmin`, {...data});
    ////(result);
    if (result.status === 200) {
      let {
        data: { data },
      } = result;

      // localStorage.setItem("grxAuthToken", data.token);
      // data= decrypt(data)
      // data=JSON.parse(data)


      dispatch(addAdminSuccess(data));
      ShowToast(result.data.message,"success");
      // showToast(err.response.data.message,"error");
      // dispatch(fetchUser());
    }

    return true;
  } catch (error) {

    dispatch(addAdminFailed());
    // throw error;
    return false;

  }
}



// Add Clinical Admin

const addClinicaladminStart = () => {
  return { type: ADD_CLINICAL_ADMIN_START };
};

const addClinicaladminFailed = () => {
  return { type: ADD_CLINICAL_ADMIN_FAILED };
};

const addClinicaladminSuccess = (payload) => {
  return { type: ADD_CLINICAL_ADMIN_SUCCESS, payload };
};

export const addClinicAdmin= (data) => async(dispatch) => {
  try {
    dispatch(addClinicaladminStart());
    let addClinicaladminData=data;

    addClinicaladminData=JSON.stringify(addClinicaladminData)
    addClinicaladminData =await encrypt(addClinicaladminData);
    // console.log("addClinicaladminData", addClinicaladminData);
    // const result = await http.post(`${API_BASE_URL}/admin/addClinicalAdmin`, {...data});
    const result = await http.post(`${API_BASE_URL}/admin/e_addClinicalAdmin`, {data:addClinicaladminData});

    ////(result);
    if (result.status === 200) {
      let {
        data: { data },
      } = result;

      // localStorage.setItem("grxAuthToken", data.token);
      // data= decrypt(data)
      // data=JSON.parse(data)


      dispatch(addClinicaladminSuccess(data));
      ShowToast(result.data.message,"success");
      // dispatch(fetchUser());
    }

    return result;
  } catch (error) {

    dispatch(addClinicaladminFailed());
    throw error;
  }
}

// Fetching Clinicians
const fetchCliniciansStart = () => {
  return { type: FETCH_CLINICIANS_START};
};

const fetchCliniciansFailed = () => {
  return { type: FETCH_CLINICIANS_FAILED };
};

export const fetchCliniciansSuccess = (payload) => {
  return { type: FETCH_CLINICIANS_SUCCESS, payload };
};

export const fetchClinicians = () => async (dispatch) => {
  try {
    dispatch(fetchCliniciansStart())

    const result = await http.get(`${API_BASE_URL}/clinician/e_get`);

    if (result.status === 200) {
      let {
        data: { data },
      } = result;
      data = await decrypt(data);
      data = JSON.parse(data);
      dispatch(fetchCliniciansSuccess(data));
    }

    return result;
  } catch (error) {

    dispatch(fetchCliniciansFailed());
    throw error;
  }
};
export const saveCliniciansSubjects =(payload)=>{
  return {type: FETCH_SUBJECTS_SUCCESS, payload: payload};

}



const getCliniciansSubjectsStart = () => {
  return { type: FETCH_SUBJECTS_START};
};

export const getCliniciansSubjectsFailed = () => {
  return { type: FETCH_SUBJECTS_FAILED };
};

export const getCliniciansSubjects = (shortId) => async(dispatch) => {
  let encShortId = await encrypt(shortId);

  // role = JSON.stringify(role);
  dispatch(getCliniciansSubjectsStart())
  try {

    const res = await http.get(`${API_BASE_URL}/clinician/e_subjects?clinicId=${encShortId}`);


      let {
        data: { data },
      } = res;

      data = await decrypt(data);
      data = JSON.parse(data);
    dispatch({type: FETCH_SUBJECTS_SUCCESS, payload: data});
    // ShowToast(res.data.message,"success");
    return res;
  } catch (error) {
    dispatch(getCliniciansSubjectsFailed());
    ShowToast(error.response.data.message,"error");

  }
}
// All Subjects

export const getAllSubjects = () => async(dispatch) => {

  // role = JSON.stringify(role);
  dispatch({type: FETCH_ALL_SUBJECTS_START})
  try {

    const res = await http.get(`${API_BASE_URL}${getSubjects}`);

// // console.log("RES", res);
let {
  data: { data },
} = res;

data = await decrypt(data);
data = JSON.parse(data);

    dispatch({type: FETCH_ALL_SUBJECTS_SUCCESS, payload: data})
    // ShowToast(res.data.message,"success");
    if(res.status===200){
      dispatch(fetchClinicians());
    }
    return true;
  } catch (error) {
    dispatch({type: FETCH_ALL_SUBJECTS_FAILED});
    // ShowToast(error.message,"error");

  }
}

// Clinicinas Sessions Data
const fetchCliniciansSessionsDataStart = (email) => {
  return { type: FETCH_CLINICIANS_SESSIONS_DATA_START, payload:email };
};

export const fetchCliniciansSessionsDataFailed = () => {
  return { type: FETCH_CLINICIANS_SESSIONS_DATA_FAILED };
};

export const fetchCliniciansSessionsDataSuccess = (payload) => {
  // // // console.log("payload", payload);
  return { type: FETCH_CLINICIANS_SESSIONS_DATA_SUCCESS, payload };
};

export const fetchCliniciansSessionsData = (_id) => async (dispatch) => {
  let encId = await encrypt(_id);
  try {
    dispatch(fetchCliniciansSessionsDataStart())
    const result = await http.get(`${API_BASE_URL}${getSessionDataUrl}?id=${encId}`);
    if (result.status === 200) {
      let {
        data: { data },
      } = result;
      data = await decrypt(data);
      data = JSON.parse(data);
      dispatch(fetchCliniciansSessionsDataSuccess(data));
    }

    return result;
  } catch (error) {

    dispatch(fetchCliniciansSessionsDataFailed());
    throw error;
  }
};
// getting and settings of Sessions Parameters




const fetchSessionsParamsStart = () => {
  return { type: FETCH_SESSIONS_PARAMS_START };
};

const fetchSessionsParamsFailed = () => {
  return { type: FETCH_SESSIONS_PARAMS_FAILED };
};

const fetchSessionsParamsSuccess = (payload) => {
  return { type: FETCH_SESSIONS_PARAMS_SUCCESS, payload };
};

export const fetchSessionsParams = (user) => async (dispatch) => {
  dispatch(fetchSessionsParamsStart());

  try {
      const result = await http.get(`${API_BASE_URL}/clinician/e_getSessionParams`);
      // console.log("RESULT Params", result);
      if (result.status === 200 ) {
        let  data = result.data.data;
        data = await decrypt(data);
        data = JSON.parse(data);
        dispatch(fetchSessionsParamsSuccess({ sParams: data }));
      }
    return result;
  } catch (error) {
    dispatch(fetchSessionsParamsFailed());
    ShowToast(error.response.data.message,"error");
    // throw error;
  }
};


export const updateSessionsParams = (param) => async (dispatch) => {
  // // // console.log("USER", param);
  let encParam = {
    ovsRate:param.ovsRate,
    intermissionTime:param.intermissionTime,
    pointSampling:param.pointSampling,
    safeGuardVoltage:param.safeGuardVoltage,
    measuringPeriod:param.measuringPeriod,
    noOfPoints:param.noOfPoints,
    samplingPointDelay:param.samplingPointDelay,
    noOfMeasurement:param.noOfMeasurement,
    scanType:param.scanType,
    defaultDeltaTime: param.defaultDeltaTime,
    minimumDeltaTime: param.minimumDeltaTime,
    deltaUintToDeltaT1: param.deltaUintToDeltaT1,
    ksg: param.ksg,
    T2ToEomp: param.T2ToEomp,
    saturationVoltage: param.saturationVoltage,
  };
  encParam = JSON.stringify(encParam);
  encParam = await encrypt(encParam);

  try {
      const result = await http.post(`${API_BASE_URL}/clinician/e_updateSessionParams`,{data:encParam});
      // // // // console.log("RESULT", result);
      if (result.status === 200) {
        // const  data = result.data;
        ShowToast(result.data.message,"success");


      }else {
        // ShowToast(result.data.message,"error");
      }
    return result;
  } catch (error) {
    throw error;
  }
};

export const addSubjects = (data) => async (dispatch) => {
  let encryptData = [...data];
  console.log("encryptData :",encryptData);
  encryptData = JSON.stringify(encryptData);
  encryptData = await encrypt(encryptData);
  try {

    const result = await http.post(`${API_BASE_URL}${addSubjectsUrl}`, {data:encryptData});
      let {
        data: { data },
      } = result;
      console.log("result", data);
      data = await decrypt(data);
      console.log("decryptedData:", data);
      data = JSON.parse(data);
      console.log("decryptedData parsed:", data);

      ShowToast(result.data.message, "success");

    return data;
  } catch (error) {
    // throw error;
    ShowToast(error.response.data.message, "error");

return false;
  }
};

export const stopClinicalTrial = (_id) => async (dispatch) => {
  try {
    let encId = await encrypt(_id);

    const result = await http.get(`${API_BASE_URL}${stopClinicalTrialUrl}?clinicalId=${encId}`);
    if (result.status === 200) {
      // let {
      //   data: { data },
      // } = result;
      ShowToast(result.data.message, "success");
    }

    return true;
  } catch (error) {
    ShowToast(error.response.data.message, "error");
    return false;

    // throw error;
  }
};
export const removeRole = (_id, role) => async (dispatch) => {
  let encId = await encrypt(_id);
  let encRole = await encrypt(role)
  try {

    const result = await http.delete(`${API_BASE_URL}/admin/e_removeRole?id=${encId}&role=${encRole}`);

    ShowToast(result.message, "success");

    return true;
  } catch (error) {
    ShowToast(error.response.data.message, "error");

    // throw error;
    return false;
  }
};


export const removeAdmin = (_id) => async (dispatch) => {
  try {

    const result = await http.delete(`${API_BASE_URL}/admin/removeAdmin?id=${_id}`);
    if (result.status === 200) {
      // let {
      //   data: { data },
      // } = result;
      ShowToast(result.data.message, "success");

    }

    return true;
  } catch (error) {
    ShowToast(error.response.data.message, "error");

    // throw error;
    return false;
  }
};

export const removeClinicalAdmin = (_id) => async (dispatch) => {
  try {

    const result = await http.delete(`${API_BASE_URL}/admin/removeUser?id=${_id}`);
    ShowToast(result.data.message, "success");

    return true;
  } catch (error) {
    ShowToast(error.response.data.message, "error");

    // throw error;
    return false;
  }
};

export const removeEngineerAdmin = (_id) => async (dispatch) => {
  try {

    const result = await http.delete(`${API_BASE_URL}/admin/removeUser?id=${_id}`);
    if (result.status === 200) {
      // let {
      //   data: { data },
      // } = result;
      ShowToast(result.data.message, "success");

    }

    return true;
  } catch (error) {
    ShowToast(error.response.data.message, "error");

    // throw error;
    return false;
  }
};

export const removePatient = (_id) => async (dispatch) => {
  try {

    const result = await http.delete(`${API_BASE_URL}/admin/removeUser?id=${_id}`);
    if (result.status === 200) {
      // let {
      //   data: { data },
      // } = result;
      ShowToast(result.data.message, "success");

    }

    return true;
  } catch (error) {
    ShowToast(error.response.data.message, "error");

    // throw error;
    return false;
  }
};

export const suspendUser = (_id) => async (dispatch) => {
  let id = await encrypt(_id);
  try {

    // const result = await http.get(`${API_BASE_URL}/admin/suspendUser?id=${_id}`);
    const result = await http.get(`${API_BASE_URL}/admin/e_suspendUser?id=${id}`);
    ShowToast(result.message, "success");


    return true;
  } catch (error) {
    ShowToast(error.response.data.message, "error");

    // throw error;
    return false;
  }
};


export const activateUser = (_id) => async (dispatch) => {
  let id= await encrypt(_id)
  try {
    const result = await http.get(`${API_BASE_URL}/admin/e_activateUser?id=${id}`);
    ShowToast(result.message, "success");

    return true;
  } catch (error) {
    ShowToast(error.response.data.message, "error");

    // throw error;
    return false;
  }
};



export const removeFromClinicalTrial = (_id) => async (dispatch) => {
  let encId = await encrypt(_id);
  try {

    const result = await http.get(`${API_BASE_URL}${stopSubjectFromCTUrl}?subjectId=${encId}`);
    // if (result.status === 200) {
    //   let {
    //     data: { data },
    //   } = result;
    //
    // }
    ShowToast(result.data.message, "success");


    return true;
  } catch (error) {
    ShowToast(error.response.data.message, "error");

    // throw error;
    return false;
  }
};

export const addSubjectsToClinicalTirals =(data)=>async(dispatch)=>{
// // console.log(data);
let encClinicalId = await encrypt(data.clinicalId);
let encSubId = await encrypt(data.subId);




  try {

    const result = await http.get(`${API_BASE_URL}${addSubToClinicalTrialUrl}?clinicalId=${encClinicalId}&subjectId=${encSubId}`);
    ////(result);
    if (result.status === 200) {
      // let {
      //   data: { data },
      // } = result;
      ShowToast("Subject successfully added!", "success");
    }

    return true;
  } catch (error) {
    ShowToast(error.response.data.message, "error");
    // throw error;

return false;
  }

}

export const checkClinicID =(val)=> async (dispatch)=>{
  let encId = await encrypt(val);
  try {
    const result = await http.get(`${API_BASE_URL}/clinician/e_checkClinicId?clinicId=${encId}`);
    // // console.log("RESULT", result);
    return result;

  } catch (e) {
    console.log("I'm Here in else Part of checkClinicID in Action :", e);
    return e;
  }

}

export const checkEmail =(val)=> async (dispatch)=>{
  let encId = await encrypt(val);

  try {
    const result = await http.get(`${API_BASE_URL}/admin/e_checkEmail?email=${encId}`);
    // // console.log("RESULT", result);
    return result;

  } catch (e) {
    // console.log("e", e.response.data.message);
    return e;
  }
}