import * as actions from "../actionTypes/ota";

const INITIAL_STATE = {
  ota: [],

  isLoading: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.FETCH_OTA_START:
      return { ...state, isLoading: true };
    case actions.FETCH_OTA_FAILED:
      return { ...state, isLoading: false };
    case actions.FETCH_OTA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ota: action.payload,
      };

    default:
      return{
        ...state
      }
  }
};
