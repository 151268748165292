import React, { Component } from 'react';
import RegistrationNewComp from '../Components/Auth/RegistrationNewComp';

class RegistrationNewPage extends Component {
    render() {
        return (
            <div>
                <RegistrationNewComp/>
            </div>
        );
    }
}

export default RegistrationNewPage;