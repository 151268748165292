// import React, { useState, useEffect } from 'react';
// import $ from "jquery";
// import { useDispatch, useSelector } from "react-redux";
// import { Spin } from 'antd';



// const Table = ({ row, count, onClick, onLogs, deleteModal, makeDelArray, index, modus, onMeasure, onPoint }) => {
//   // // // console.log("Modus 2", modus);
//   const dispatch = useDispatch();
//   // const docToken = useSelector((state) => state.auth.token);
//   const [collapse, setCollapse] = useState(false);
//   const [loading, setLoading] = useState(false);



//   //Handling View btn
//   const settingDeletingFlow = () => {
//     deleteModal();
//     makeDelArray();
//   }
//   const delArray = () => {
//     makeDelArray();
//   }
//   return (
//     <>

//       <tbody key={count} style={{ textAlign: 'center', width: "100%", height: "100%" }}>
//         <tr key={index}>
//           <td>{row.deviceName}</td>
//           <td>{row.sessionStartTime}</td>
//           <td>{row.status === 1 ? "Not started" :
//             row.status === 2 ? "In progress" :
//               row.status === 3 ? "Completed" :
//                 row.status === 4 ? "Interrupted" :
//                   row.status === 5 ? "Failed" :
//                     row.status === 6 ? "Abandoned" :
//                       "Unknown"}</td>
         
//           <td>{row.measurementCount}</td>
//           <td className='text-center session-td' width="30%">
//             <button onClick={onLogs} style={{ veritcalAlign: "middle" }} type="button" data-toggle="collapse" data-target={`#collapseExample${count}`} aria-expanded="false" aria-controls="collapseExample"><i className={"fas fa-download"}></i> Events</button>


//             <button onClick={onMeasure} className="ml-2" style={{ veritcalAlign: "middle", background: "#007bff", color: "#ffffff" }} type="button" data-toggle="collapse" data-target={`#collapseExample${count}`} aria-expanded="false" aria-controls="collapseExample"><i className={"fas fa-download"}></i> Measurement</button>
//             <button className="ml-2" style={{ veritcalAlign: "middle", background: "orange", color: "#ffffff" }} onClick={onPoint} type="button" data-toggle="collapse" data-target={`#collapseExample${count}`} aria-expanded="false" aria-controls="collapseExample"><i className={"fas fa-download"}></i> Point Data</button>





//           </td>
//           <td style={{ minWidth: "50px", display: "flex", alignItems: "center", justifyContent: "space-around" }}>
//             <input type="checkbox" name="checkbox" style={{ veritcalAlign: "middle", width: "20px", height: "20px" }} className="" onChange={delArray}></input>

//             <i className="fas fa-spinner fa-pulse invisible" style={{ width: "50%" }} id={'spinner' + count}></i>
//           </td>


//         </tr>
//       </tbody>
//     </>
//   );
// };

// export default Table;
// new version

import React from 'react';

const TableRow = ({ row, count, onClick, onLogs, deleteModal, makeDelArray, index, modus, onMeasure, onPoint }) => {
  return (
    <tr key={index}>
      <td>{row.deviceName}</td>
      <td>{row.sessionStartTime}</td>
      <td>{row.status === 1 ? "Not started" :
          row.status === 2 ? "In progress" :
          row.status === 3 ? "Completed" :
          row.status === 4 ? "Interrupted" :
          row.status === 5 ? "Failed" :
          row.status === 6 ? "Abandoned" :
          "Unknown"}</td>
      <td>{row.measurementCount}</td>
      <td className='text-center session-td' width="30%">
        <button onClick={onLogs} style={{ verticalAlign: "middle" }} type="button"><i className={"fas fa-download"}></i> Events</button>
        <button onClick={onMeasure} className="ml-2" style={{ verticalAlign: "middle", background: "#007bff", color: "#ffffff" }} type="button"><i className={"fas fa-download"}></i> Measurement</button>
        <button className="ml-2" style={{ verticalAlign: "middle", background: "orange", color: "#ffffff" }} onClick={onPoint} type="button"><i className={"fas fa-download"}></i> Point Data</button>
      </td>
      <td style={{ minWidth: "50px", display: "flex", alignItems: "center", justifyContent: "space-around" }}>
        <input type="checkbox" name="checkbox" style={{ verticalAlign: "middle", width: "20px", height: "20px" }} onChange={makeDelArray}></input>
        <i className="fas fa-spinner fa-pulse invisible" style={{ width: "16px" }} id={'spinner' + count}></i>
      </td>
    </tr>
  );
};

export default TableRow;
