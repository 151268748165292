
import React from "react";
import { useDispatch, useSelector} from "react-redux";



const Maintainance = () => {
    const userData = useSelector((state) => state.user);

    return (
        <div className="container-fluid h-100 bg-white">

    <div className="row h-100 bg-light rounded mx-1 pl-md-2 pl-0 py-2">

        <main className="col  bg-faded " style={{minHeight:"100vh"}}>
        <h1 className="text-center mt-5 mb-5">This Page is under maintainance.</h1>
         </main>
    </div>
</div>
    );
};

export default Maintainance;
