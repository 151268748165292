// import React, { useEffect } from "react";
//
// import * as am4core from "@amcharts/amcharts4/core";
// import * as am4charts from "@amcharts/amcharts4/charts";
// import am4themes_animated from "@amcharts/amcharts4/themes/animated";
//
//
// am4core.useTheme(am4themes_animated);
//
// function History(props) {
//
//   useEffect(() => {
//
// let chart = am4core.create(props.chartId, am4charts.XYChart);
//
//
// chart.colors.step = 2;
//
//
// // Add data
// // // // console.log("data in chart",props);
// // let data = JSON.parse(localStorage.getItem("chartData"));
// // // // // console.log("Chart Data In local", data);
// let chartData=[];
// if (props.data && props.data.data && props.data.data.length > 0){
//   // // // console.log(props.data);
//   for (let x = 0; x<props.data.data.length; x++) {
//     // // // // console.log(x);
//     let obj = {
//       "date": props.data.data[x].hour +":" +props.data.data[x].minutes,
//       "value": props.data.data[x].value
//     }
//     // // // console.log(obj);
//     chartData.push(obj)
//   }
// }
// chart.data = chartData;
// // // // console.log(chart.data);
//
// // Create axes
// let dateAxis = chart.xAxes.push(new am4charts.CategoryAxis());
// dateAxis.dataFields.category = "date";
//
//
// // Create series
// function createAxisAndSeries(field, name, opposite, bullet) {
//   let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
//
//   let series = chart.series.push(new am4charts.LineSeries());
//   series.dataFields.valueY = field;
//   series.dataFields.categoryX = "date";
//   series.strokeWidth = 2;
//   // series.yAxis = valueAxis;
//   series.name = name;
//   series.tooltipText = "{name}: [bold]{valueY}[/]";
//   series.tensionX = 0.8;
//   series.showOnInit = true;
//
//   let interfaceColors = new am4core.InterfaceColorSet();
//
//   switch(bullet) {
//     case "triangle":
//       let bullet = series.bullets.push(new am4charts.Bullet());
//       bullet.width = 12;
//       bullet.height = 12;
//       bullet.horizontalCenter = "middle";
//       bullet.verticalCenter = "middle";
//
//       let triangle = bullet.createChild(am4core.Triangle);
//       triangle.stroke = interfaceColors.getFor("background");
//       triangle.strokeWidth = 2;
//       triangle.direction = "top";
//       triangle.width = 12;
//       triangle.height = 12;
//       break;
//     case "rectangle":
//       let bullet1 = series.bullets.push(new am4charts.Bullet());
//       bullet1.width = 10;
//       bullet1.height = 10;
//       bullet1.horizontalCenter = "middle";
//       bullet1.verticalCenter = "middle";
//
//       let rectangle = bullet1.createChild(am4core.Rectangle);
//       rectangle.stroke = interfaceColors.getFor("background");
//       rectangle.strokeWidth = 2;
//       rectangle.width = 10;
//       rectangle.height = 10;
//       break;
//     default:
//       let bullet2 = series.bullets.push(new am4charts.CircleBullet());
//       bullet2.circle.stroke = interfaceColors.getFor("background");
//       bullet2.circle.strokeWidth = 2;
//       break;
//   }
//
//   valueAxis.renderer.line.strokeOpacity = 0;
//   // valueAxis.renderer.line.strokeWidth = 0;
//   // valueAxis.renderer.line.stroke = series.stroke;
//   // valueAxis.renderer.labels.template.fill = series.stroke;
//   valueAxis.renderer.opposite = opposite;
//   // valueAxis.renderer.line.disabled = true;
// }
//
// createAxisAndSeries("value", "Reading", false, "circle");
// // createAxisAndSeries("tuesday", "Tuesday", true, "triangle");
// // createAxisAndSeries("wednessday", "Wednessday", true, "rectangle");
// // createAxisAndSeries("thursday", "Thursday", true, "rectangle");
// // createAxisAndSeries("friday", "Friday", true, "rectangle");
// // createAxisAndSeries("saturday", "Saturday", true, "rectangle");
//
//
//
// // Add legend
// chart.legend = new am4charts.Legend();
//
// // Add cursor
// chart.cursor = new am4charts.XYCursor();
//
// }
// ,
//  [props.data]);
//
//   return (<div className="">
//
//         <div id={props.chartId} style={{ width: "100%", minHeight: "500px" }}></div>
//   </div>
//   );
// }
//
// export default History;










import React, { useEffect, useState } from "react";
import {useSelector} from "react-redux";
import moment from "moment";
import Loading from '../../Common/Loading';
import * as _ from "lodash";


import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


am4core.useTheme(am4themes_animated);

function History(props) {
  // console.log("PROPS HISTORY :", props);

  const [showGraph, setShowGraph]=useState(false);
  const {user, settings} = useSelector((state)=>{return state});

  useEffect(() => {

let chart = am4core.create(props.chartId, am4charts.XYChart);
// const transformHistoricalReadings = (data) => {
// console.log("DATA in Chart", data);
//   let transformedData = [];

//   transformedData = data && data.length>0 && data.map((record) => {
// console.log("Record:", record);
//     let color= record.glucose>=user.userDetails.user.high? "#f38f20": record.glucose<= user.userDetails.user.low ? "#d61a00": "#2a93fc";
//     // console.log("Settings", settings);
//     return {
//       time: new Date(record.time),
//       glucose: record.glucose,
//       time2: moment(record.time).format("HH:mm"),
//       color: color
//     }
//       });
//   let myArray = _.sortBy(transformedData, function(dateObj) {
//
//     return new Date(dateObj.date);
//   });
//   console.log("myArray", myArray);
//   return myArray;
// };
let dataForChart = props.data && props.data.length > 0 && props.data.map(record => {
  let color= record.reading>=user.userDetails.user.high? "#f38f20": record.reading<= user.userDetails.user.low ? "#d61a00": "#2a93fc";
// console.log("Settings", settings);
  return {
    time: new Date(record.date),
    glucose: record.reading,
    time2: moment(record.date).format("HH:mm"),
    color: color
  }
});
// dataForChart = transformHistoricalReadings(dataForChart);
chart.data = dataForChart;

// let dataLength = chart.data.length - 1;
// let startTime = chart.data && chart.data.length>0?chart.data[0].time: null;
// let endTime = chart.data && chart.data.length>0?chart.data[dataLength].time:null;

chart.dateFormatter.dateFormat = "yyyy-MM-dd";
  // Create axes
var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
dateAxis.startLocation = 0.5;
// dateAxis.endLocation = 0.5;
dateAxis.dateFormatter = new am4core.DateFormatter();
dateAxis.dateFormatter.dateFormat = "YYYY-dd";
dateAxis.cursorTooltipEnabled = false;

// dateAxis.zoomToDates(new Date(startTime), new Date(endTime));



// Create value axis
var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
valueAxis.min = 0;
valueAxis.title.text = user.userDetails && user.userDetails.user && user.userDetails.user.unit === 0?"mmol/L":"mgdl";
valueAxis.cursorTooltipEnabled = false;





// chart.tapToActivate = true;
// chart.tapTimeout = 5000;
// chart.dragGrip.disabled = false;
// Create axes
// var dateAxis = chart.xAxes.push(new am4charts.DateAxis());

// dateAxis.renderer.grid.template.location = 0;
// dateAxis.renderer.minGridDistance = 30;


// Create series

let lineSeries = chart.series.push(new am4charts.LineSeries());
lineSeries.dataFields.valueY = "glucose";
lineSeries.dataFields.dateX = "time";
// lineSeries.showTooltipOn = "always"
// lineSeries.tooltipText = "X: {valueX} / Y: {valueY}";

// lineSeries.name = "Sales";
lineSeries.strokeWidth = 4;
lineSeries.fillOpacity = 0;

lineSeries.sequencedInterpolation = false;

let bullet = lineSeries.bullets.push(new am4core.Circle());
    bullet.stroke = am4core.color("#fff");
    bullet.radius = 4;
    bullet.propertyFields.fill = "color";

    // bullet.fill = ("#2a93fc");
    bullet.tooltipText = "Date: {dateX} \n Time: {time2} \n Reading: {valueY} mmol/L";;

// lineSeries.stacked = true;

// var fillModifier = new am4core.LinearGradientModifier();
// fillModifier.opacities = [1, 0];
// fillModifier.offsets = [0, 1];
// fillModifier.gradient.rotation = 90;
// lineSeries.segments.template.fillModifier = fillModifier;
// bullet.adapter.add("fill", (fill, target)=> {
//   if (target.dataItem) {
//     for(let i = 0; i < chart.data.length; i++) {
//       if (
//         target.dataItem.valueY <= 4
//       ) {
//         return am4core.color("yellow");
//       } else if(target.dataItem.valueY >= 12){
//         return am4core.color("#ff008dd9");
//
//       }else{
//         return am4core.color("#2a93fc");
//
//       }
//     }
//   }
//   return fill;
// });
// Add a no data placeholder
chart.events.on("beforedatavalidated", (ev) => {
  if (chart.data.length === 0) {
    let noData = chart.plotContainer.createChild(am4core.Label);
    noData.text = "No data available";
    noData.align = "center";
    noData.valign = "middle";
    noData.fontSize = 20;
    noData.dy = -50; // Adjust the vertical position as needed
  }
});

chart.scrollbarX = new am4core.Scrollbar();
chart.scrollbarX.parent = chart.bottomAxesContainer;
chart.scrollbarX.thumb.minWidth = 5;
chart.scrollbarX.startGrip.disabled = true;
chart.scrollbarX.endGrip.disabled = true;

let range = valueAxis.axisRanges.create();
range.value = 0;
range.endValue = user.userDetails && user.userDetails.user && user.userDetails.user.low?user.userDetails.user.low:0;
range.axisFill.fill = am4core.color("#ff7dc561");
range.axisFill.fillOpacity = 0.2;
range.grid.strokeOpacity = 0;

let range1 = valueAxis.axisRanges.create();
range1.value = user.userDetails && user.userDetails.user && user.userDetails.user.high?user.userDetails.user.high:0;
range1.endValue = 30;
range1.axisFill.fill = am4core.color("#ffff008c");
range1.axisFill.fillOpacity = 0.2;
range1.grid.strokeOpacity = 0;

chart.cursor = new am4charts.XYCursor();
// chart.cursor.behavior= 'panXY';

chart.events.on('ready', () => {
  setShowGraph(true);
});




return () => {
      chart.dispose();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
},[props.data, props.chartId]);
let startDate=moment(new Date(props.startDate)).format('DD-MM-YYYY HH:MM');
let endDate=moment(new Date(props.startDate)).add(1, 'days').format('DD-MM-YYYY HH:MM').toString();
  return (
<div>
    <div className={showGraph?"d-none":" w-100 p-5"}>                    <Loading/>
    </div>
    <div className={showGraph?"":"d-none"}>

  <p className="pb-3">From: {startDate}  To: {endDate}</p>

        <div id={props.chartId} style={{ width: "100%", minHeight: "500px" }}></div>
  </div>
  </div>

  );
}

export default History;
