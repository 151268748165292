import React, { useEffect, useState } from 'react';

const AgesDistribution = () => {
    const [allGender, setAllGender] = useState(0);
    const [men, setMen] = useState(0);
    const [women, setWomen] = useState(0);
    
    return (
        <div class="chart">
            <div class="row m-auto">
                <div className='col-3 heading'>
                <h1>Age </h1>

                </div>
                <div className='col-9 filters'>
                    <div class="row">
                        <div class="col-4">
                            <button class="btn btn-primary w-100" type='button'>All</button>
                        </div>
                        <div class="col-4">
                        <button class="btn btn-primary w-100" type='button'>Women</button>

                        </div>
                        <div class="col-4">
                        <button class="btn btn-primary w-100" type='button'>Men</button>

                        </div>

                    </div>
                </div>
            </div>
            <div class="filters row ">
                
            </div>
            <div class="age-range">
                <span>18-24</span>
                <span>6%</span>
            </div>
            <div class="bar" style={{width: "6%"}}></div>
            <div class="age-range">
                <span>25-34</span>
                <span>20%</span>
            </div>
            <div class="bar" style={{width: "20%"}}></div>
            <div class="age-range">
                <span>35-44</span>
                <span>8%</span>
            </div>
            <div class="bar" style={{width: "8%"}}></div>
            <div class="age-range">
                <span>45-54</span>
                <span>6%</span>
            </div>
            <div class="bar" style={{width: "6%"}}></div>
            <div class="age-range">
                <span>55-64</span>
                <span>2%</span>
            </div>
            <div class="bar" style={{width: "2%"}}></div>
        </div>
    );
};

export default AgesDistribution;