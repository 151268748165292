
import * as actions from "../actionTypes";
import { API_BASE_URL, fetchUserURL } from "../../utils/urls";
import http from "../../utils/http-service";
import { loginSuccess, logout } from "./auth";
import { fetchSettings } from "./settings";
import { encrypt, decrypt } from "../../utils/encryption";
import ShowToast from "../../utils/show-toast";
const fetchUserStart = () => {
  return { type: actions.FETCH_USER_START };
};

const fetchUserFailed = () => {
  return { type: actions.FETCH_USER_FAILED };
};

const fetchUserSuccess = (payload) => {
  return { type: actions.FETCH_USER_SUCCESS, payload };
};

export const fetchUser = () => async (dispatch) => {
  try {
    dispatch(fetchUserStart());

    const token = localStorage.getItem("EostreAuthToken");
    if (!token) {
      dispatch(fetchUserFailed());
      return;
    }

    const result = await http.get(`${API_BASE_URL}${fetchUserURL}`, {});

    if (result.status === 200) {
      let {
        data: { data },
      } = result;
      data = await decrypt(data);
      data = JSON.parse(data);
      console.log("DATA", data);
      let detail = data;

      dispatch(loginSuccess({ token: localStorage.getItem("EostreAuthToken") }));
      let refinedUser = {
        user: {
          address: detail.user.address,
          app: detail.user.app,
          clinicalId: detail.user.clinicalId,
          country: detail.user.country,
          dateOfBirth: detail.user.dateOfBirth,
          diabetesType: detail.user.diabetesType,
          email: detail.user.email,
          firstName: detail.user.firstName,
          gender: detail.user.gender,
          hmac: detail.user.hmac,
          lastName: detail.user.lastName,
          phone: detail.user.phone,
          picture: detail.user.picture,
          postcode: detail.user.postcode,
          role: detail.user.role,
          status: detail.user.status,
          subjectId: detail.user.subjectId,
          town: detail.user.town,
          low:  detail.settings && detail.settings.low?detail.settings.low:3.1,
          high: detail.settings && detail.settings.high?detail.settings.high:9.1,
          unit: detail.settings && detail.settings.unit?detail.settings.unit:0,
        },
      };
      dispatch(fetchUserSuccess({ user: refinedUser }));
      dispatch(fetchSettings());
    }

    return result;
  } catch (error) {
    dispatch(logout());
    dispatch(fetchUserFailed());
    throw error;
  }
};

const saveSelectedUserStart = () => {
  return { type: actions.SAVE_USER_START };
};

const sveSelectedUserFailed = () => {
  return { type: actions.SAVE_USER_FAILED };
};

const saveSelectedUserSuccess = (payload) => {
  return { type: actions.SAVE_USER_SUCCESS, payload };
};

export const saveUser = (user) => async (dispatch) => {
  try {
    dispatch(saveSelectedUserStart());
    dispatch(saveSelectedUserSuccess({ user: user }));
    window.history.pushState({}, null, "/dashboard");
    return true;
  } catch (error) {
    dispatch(sveSelectedUserFailed());
    throw error;
  }
};

export const updateUserRole = (role) => {
  let apple = JSON.stringify(role);
  apple = encrypt(apple).then((encrypted) => {
    apple = encrypted;
    localStorage.setItem("Apple", apple);
  }).catch((error) => {
    // Handle encryption error
  });

  return { type: actions.UPDATE_ROLE, payload: role };
};

const updateUserStart = () => {
  return { type: actions.UPDATE_USER_START };
};

const updateUserFailed = () => {
  return { type: actions.UPDATE_USER_FAILED };
};

const updateUserSuccess = (payload) => {
  return { type: actions.UPDATE_USER_SUCCESS, payload };
};

export const updateUser = (data) => async (dispatch) => {
  dispatch(updateUserStart());

  try {
    let updateData = data;

    const encryptedData = await encrypt(updateData);
    updateData = encryptedData;

    let result = await http.post(`${API_BASE_URL}/user/e_update`, { data: updateData });

    let gift = result.data.data;

    dispatch({ type: actions.UPDATE_USER_SUCCESS, payLoad: gift });
    ShowToast(result.data.message, "success");

    return true;
  } catch (error) {
    ShowToast(error.response.data.message, "error");

    if (error.response.status === 422 || error.response.status === 401) {
      dispatch(logout(true));
      window.location.reload();
    } else {
      dispatch(updateUserFailed());
      return false;
      throw error;
    }
  }
};

const filterFromDateSuccess = (payload) => {
  return { type: actions.SAVE_FROM_DATE_SUCCESS, payload };
};

export const filterFromDate = (date) => async (dispatch) => {
  try {
    dispatch(filterFromDateSuccess({ fromDate: date }));
    return true;
  } catch (error) {
    return false;
  }
};

const filterToDateSuccess = (payload) => {
  return { type: actions.SAVE_TO_DATE_SUCCESS, payload };
};

export const filterToDate = (date) => async (dispatch) => {
  try {
    dispatch(filterToDateSuccess({ toDate: date }));
    return true;
  } catch (error) {
    return false;
  }
};
