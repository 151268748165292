import React, {useEffect, useState } from "react";
import GlucoRxLogo from "../../Assets/Logo/GlucoRxLogo.svg";
import { default as Titles } from "../../utils/document-titles";
import { accountActivation } from "../../redux/actions/auth";
// import { Spin } from "antd";
import { useDispatch } from "react-redux";
import ShowToast from "../../utils/show-toast";
import { Link, useNavigate } from "react-router-dom";

 const  AccountActivationComp=()=>{
  // document.title = Titles.resendVerificationEmail;

  const history = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();

  useEffect(()=>{

   activation()

 },[])

  const activation=async()=>{

let url = window.location;

let token= new URLSearchParams(url.search).get('token');


    try {
      setIsLoading(true);
      // e.preventDefault();
      const result = await dispatch(accountActivation(token));


     // history("/auth/login");
      // if check on status


      let status=result.status
      if(status===200){
        // ShowToast(result.data.message,"success");
        history("/auth/login");

      }


      setIsLoading(false);
    } catch (err) {
console.log("Error :", err);
      let status=err.response.status


      if( status===409){
        // ShowToast(err.response.data.message,"success");
      }
      else{
        ShowToast(err.response.data.message,"error");
      }
      setIsLoading(false);
    }
  }
  return (
    <div className='container-fluid d-flex flex-column forget-background ' >

    <div className="row justify-center align-item-center my-auto" >
        <div className="col-md-7 col-11 forget-field-box align-items-center py-5 m-auto">
           <div className='d-flex justify-content-center '><img src={GlucoRxLogo} className='forget-gluco-logo '></img></div>
           <p className=' text-white text-center forget-text-font mt-3'>Activation in process please wait!</p>
           {/*}<form onSubmit={handleSubmit}>
            <div className="row justify-content-center align-items-center my-3 text-white">
                <div className="col-md-10 col-10">
                    <input
                      type="email"
                      value={email}
          onChange={(e) => setEmail(e.target.value)}
                      className="forget-form-control"
                      id="formGroupExampleInput"
                      required
                    />
                </div>
            </div>
            <div className="row justify-content-center align-items-center my-3 text-white">
                <div className="col-md-10 col-10">
            <Spin spinning={isLoading}>
                  <button
                        type="submit"
                        className="forget-submit-btn btn-primary "
                      >
                        Signin
                    </button>
                    </Spin>
                </div>
            </div>
            </form>*/}
            <div className="mt-3 text-white">
                <p className="text-center forget-text-font">
                <Link
              to={"/auth/login"}>
              Back to Sign In!
            </Link>
                </p>
            </div>
        </div>


        </div>


</div>
  );
}
export default AccountActivationComp;
