import * as actions from "../actionTypes/index";

const initialState = {
  userDetails: null,
  isLoading: false,
  fromDate:null,
  toDate:null,
  unit:null,
};

export default (state = initialState, action) => {
  // console.log("ACTION", action);
  switch (action.type) {
    case actions.FETCH_USER_START:
      return {
        ...state,
        isLoading: true,
      };
      break;
    case actions.FETCH_USER_FAILED:
      return {
        ...state,
        isLoading: false,
      };
      break;
    case actions.FETCH_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userDetails: action.payload.user,
        unit: action.payload.user.user.unit,
      };

      break;
      case actions.UPDATE_ROLE:
        return {
          ...state,
          isLoading: false,
          userDetails: action.payload,
          unit: action.payload.user.unit,
        };
        break;
      case actions.UPDATE_USER_START:
        return {
          ...state,
          isLoading: true,
        };
        break;
      case actions.UPDATE_USER_FAILED:
        return {
          ...state,
          isLoading: false,
        };
        break;
      case actions.UPDATE_USER_SUCCESS:
        return {
          ...state,
          isLoading: false,

        };
        break;

        case actions.SAVE_FROM_DATE_SUCCESS:
          return {
            ...state,
            isLoading: false,
            fromDate: action.payload.fromDate,

          };
          break;

          case actions.SAVE_TO_DATE_SUCCESS:
            return {
              ...state,
              isLoading: false,
              toDate: action.payload.toDate,
            };
            break;
    default:
      return state;
  }
};
