
import React, { useEffect } from "react";
import { useSelector } from 'react-redux';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


am4core.useTheme(am4themes_animated);
// Themes end
function RegionChart(props) {

  const {countriesPercentage} = useSelector((state) => state.analytics);

  // let chart;
  useEffect(() => {


    let chart = am4core.create("region", am4charts.PieChart);
    chart.hiddenState.properties.opacity = 0;
    // chart.data = [{
    //     "country": "Lithuania",
    //     "litres": 501.9
    //   }, {
    //     "country": "Czech Republic",
    //     "litres": 301.9
    //   }, {
    //     "country": "Ireland",
    //     "litres": 201.1
    //   }, {
    //     "country": "Germany",
    //     "litres": 165.8
    //   }, {
    //     "country": "Australia",
    //     "litres": 139.9
    //   }, {
    //     "country": "Austria",
    //     "litres": 128.3
    //   }, {
    //     "country": "UK",
    //     "litres": 99,
    //     "hidden": true
    //   }, {
    //     "country": "Belgium",
    //     "litres": 60,
    //     "hidden": true
    //   }, {
    //     "country": "The Netherlands",
    //     "litres": 50,
    //     "hidden": true
    //   }];
chart.data = countriesPercentage;
   // Add and configure Series
var pieSeries = chart.series.push(new am4charts.PieSeries());
pieSeries.dataFields.value = "percentage";
pieSeries.dataFields.category = "country";
pieSeries.dataFields.hidden = "hidden";

// Let's cut a hole in our Pie chart the size of 40% the radius
chart.innerRadius = am4core.percent(40);

// Disable ticks and labels
pieSeries.labels.template.disabled = true;
pieSeries.ticks.template.disabled = true;

// Disable tooltips
pieSeries.slices.template.tooltipText = "";

// Add a legend
chart.legend = new am4charts.Legend();
chart.legend.position = "right";

// chart.legend.maxWidth = 120;
var marker = chart.legend.markers.template.children.getIndex(0);
marker.cornerRadius(12, 12, 12, 12);
marker.strokeWidth = 2;
marker.strokeOpacity = 1;
marker.stroke = am4core.color("#ccc");


}, [countriesPercentage]);

  return (<div className="">
        <div id="region" style={{ width: "100%", minHeight: "250px" }}></div>
  </div>
  );
}
export default RegionChart;
