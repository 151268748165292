import React, { useState, useEffect, useTransition } from "react";
import { BrowserRouter as Router, NavLink, Link , useNavigate} from "react-router-dom";
import { Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Components/Common/Loading"
import moment from "moment";
import { default as Titles } from "../utils/document-titles";

import { fetchClinicians, addClinicID, getClinicians, checkClinicID,addSubjects,stopClinicalTrial} from "../redux/actions/admin/admin";
import {fetchUser,updateUserRole} from "../redux/actions/user";
// import {encrypt, decrypt} from "../utils/encryption";

import { Tabs } from "antd";




export default function SubAdmins() {
  document.title = Titles.clinicalDashboard;

  const dispatch = useDispatch();
  const history = useNavigate();
  const {cliniciansData} = useSelector((state)=>{return state.admin});
  const { userDetails } = useSelector((state) => {return state.user});
  const [toDelete, setToDelete] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [subjectModal, setSubjectModal] = useState(false);

  const [cliId, setCliId] = useState("");
  const [filileUploadingStart, setFilileUploadingStart] = useState(false);
  const [isLoading, setIsLoading] = useState(false);



  // document.title = DocumentTitles.history;
  const [existingSubjects, setExistingSubjects] = useState([]);

  const [management, setManagement] = useState(false);
  const [addModal, setAddModal] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [idAvail, setIdAvail] = useState(null);
  const [showAddBtn, setShowAddBtn] = useState(false);

  const [clinicId, setClinicId]= useState("");
  const [isPending, startTransition] = useTransition();


  useEffect(() => {
    if(userDetails && userDetails.user){

    } else {
      if(localStorage.getItem("Apple")){
        let user = localStorage.getItem("Apple");

        dispatch(updateUserRole(user));
      } else {
        dispatch(fetchUser());

      }
    }
fetchingClinicians();
},[]);

useEffect(() => {
},[idAvail]);

useEffect(() => {
  setStartDate("");
  setClinicId("");
  setIdAvail(null);
},[addModal]);

const fetchClinicsEntities = (shrtId) => {
 history(`/admin/Clinicians/:${shrtId}`);
  };

const fetchingClinicians = async () => {
  startTransition(() => {
  dispatch(fetchClinicians());
  });
  };


const toAddClinicID= async(e)=>{
  e.preventDefault();

if(idAvail){
  console.log("STARTDATE :", startDate);
      let data = {
    "startDate": startDate=== ""? new Date(): new Date(startDate),
    "shortId": clinicId
  }
    let res = await dispatch(addClinicID(data));
  if(res.status && res.status===200){
    setAddModal(false);
    dispatch(fetchClinicians());
  }

}
}
const checkCliID = async(e) => {
  const val = e.target.value;
  const newVal = e.target.value.replace(/^\s+/g, "");
  setClinicId(newVal);
  if(val !== "" && val.length>2 && val.trim().length !== 0 && val.trim().length >2 && val.endsWith(" ") === false){
  let res = await dispatch(checkClinicID(val.trim()));

  if(res.status && res.status === 200) {
    setIdAvail(true);
    setShowAddBtn(true);
  } else{
    setIdAvail(false);
    setShowAddBtn(false);


  }

} else if(val.length<3){
  // Using setTimeout to adding a delay
    setTimeout(() => {
      setIdAvail(null);
      setShowAddBtn(false);
    }, 400); // 1000 milliseconds (1 second) delay

}
}
const csvReader = (e) =>{
  const [file] = e.target.files;

  const reader = new FileReader();


  reader.onload = () => {
    // @ts-ignore

    let cs = reader.result.split("\n");
    let data = [];
    for(let  i = 0; i < (cs.length-1); i++){
      let obj = {

      };
      let inst = cs[i].split(",");
      obj = {
        subjectId: inst[0],
        password: inst[1].replace("\r", "")
      }
      data.push(obj);
    }


addingSubjects(data);
  };
  // start reading the file. When it is done, calls the onload event defined above.
  // @ts-ignore
  reader.readAsBinaryString(document.getElementById('csvInput').files[0]);
}

const addingSubjects=async(data)=>{
  setFilileUploadingStart(true);

  let res = await dispatch(addSubjects(data));
  fetchingClinicians();
  setFilileUploadingStart(false);
  if(res.existingSubjects && res.existingSubjects.length>0){
    setSubjectModal(true);
  }
  setExistingSubjects(res.existingSubjects);

}
const stoppingClinicalTrials = async()=>{
  let id = toDelete;
  let result = await dispatch(stopClinicalTrial(id));

  if(result === true){
    fetchingClinicians();
  }
  setDeleteModal(false);




}
const getCurrentDateInput = () => {

  const dateObj = new Date();

  // get the month in this format of 04, the same for months
  const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
  const day = ("0" + dateObj.getDate()).slice(-2);
  const year = dateObj.getFullYear();

  const shortDate = `${year}-${month}-${day}`;
  // setStartDate(new Date());
  return shortDate;
};

const convertJsonToCsv =(jsonData)=> {
    const header = ["subjectId", "password"].join(',');
    const rows = jsonData.map(obj => [obj.subjectId, obj.password].join(','));

  let csvData = `${header}\n${rows.join('\n')}`;
    downloadCsv(csvData, 'data.csv');
}


const downloadCsv=(csvData, fileName)=> {
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
}


if (userDetails &&
   userDetails.user && (userDetails.user.role.includes(6)===true || userDetails.user.role.includes(1)===true || userDetails.user.role.includes(0)===true)) {

return (
  <div className="container-fluid patient-list h-100">
          <div className="row p-2 px-3 h-100">
              <div className="col-12">
                  <div className="row justify-content-between pl-3 h-100">

                      <div className="col-12 col-md-6">
                        <h1 className="pt-3 pb-3 font-weight-bold chart-heading">Clinical Dashboard</h1>
                      </div>
                      <div className="col-12 col-md-6 text-right">
                        <button className="btn btn-primary mt-3" onClick={()=>{setAddModal(true)}}>Add Clinic ID</button>
                        <label htmlFor="csvInput" className="btn btn-primary mt-3 mb-0 ml-2"><i className="fa fa-upload mr-2" aria-hidden="true"></i>Subjects CSV</label>
                        <input
                          accept=".csv"
                          value=""
                          id="csvInput"
                          hidden
                          onChange={(e) => {
                            csvReader(e);
                          }}
                          type="file"
                        />

                      </div>

                      <div className="col-12">
                      
                      
                      <table className="table table-hover" id="task-table">
                        <thead>
                        <tr>
                          <th>Date</th>
                          <th>Clinic Test ID</th>
                          <th>No of Subjects</th>
                          <th className="text-left">Actions</th>
                        </tr>
                        </thead>
                        {isPending?
                      <Loading/>
                      :
                        <tbody>
                        { cliniciansData !== null && cliniciansData.clinics && cliniciansData.clinics.length > 0 && cliniciansData.clinics.map((row, index)=>(

                        <tr key={index+1}>
                          <td>{moment(row.startDate).format("DD-MM-YYYY")}</td>
                          <td>{row.shortId}</td>
                          <td>{Array.isArray(row.subjects) === true? row.subjects.length:null}</td>
                          <td className='text-left'>
                            <button
                                type="button"
                                className=""
                                title="View"
                                onClick={(event)=>{
                                fetchClinicsEntities(row.shortId);
                                }}
                            >
                              <span className="">
                              <i className="fa fa-eye" aria-hidden="true"></i>
                              </span>
                            </button>
                            {row.status === 1?
                            <button
                                type="button"
                                className="ml-2"
                                title="Stop Clinical Trial"
                                onClick={(event)=>{
                                  setToDelete(row.shortId);
                                  setDeleteModal(true);
                                  setCliId(row.shortId);
                                }}
                            >
                              <span className="">
                              <i className="fa fa-times" aria-hidden="true"></i>
                              </span>
                            </button>:null}
                          </td>
                        </tr>

                      ))}

                        </tbody>}

                      </table>
                      
                    
                      </div>

                      <div
                          className={`modal big_modal_dialog ${
                              addModal? "d-block modal-fading" : " d-none"
                          }`}
                          tabIndex="-1"
                          data-backdrop="static"
                          data-keyboard="false"

                          role="dialog"

                          aria-hidden="true"
                      >
                        <div className="modal-dialog" role="document">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="addHospitalModalTitle">
                                Add Clinic ID
                              </h5>
                              <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                  onClick={()=>setAddModal(false)}
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <form className="pl-3 pr-3" onSubmit={toAddClinicID}>
                              <div className="container my-4 ">

                                <div className="row justify-content-center align-items-center mb-3 text-white">

                                  <div className="col-12 registration-input-labels"><p className="text-black text-center">Start Date</p></div>
                                  <div className="col-12 text-center">
                                    <input
                                      defaultValue={getCurrentDateInput()}
                                      onChange={(e) => setStartDate(e.target.value)}
                                      type="date"
                                      className="registration-datePicker"
                                      required
                                      style={{color:"black"}}
                                      min={new Date().toISOString().split('T')[0]}
                                    />
                                  </div>
                                </div>

                                <Spin spinning={isLoading}>
                                <div className="row justify-content-center align-items-center mb-3 text-white">

                                  <div className="col-12 registration-input-labels"><p className="text-black text-center">Clinic ID</p></div>
                                  <div className="col-12 text-center">


                                    <input type="text" id="clinicID" name="clinicID" placeholder="Enter Clinic ID"
                                      pattern="{3,50}" required title="Clinic ID should be between 3 and 50 characters long"
                                      value={clinicId}
                                      onChange={(e) => {
                                        checkCliID(e)}}
                                      type="text"
                                      required
                                      style={{color:"black"}}
                                       />
                                      <div className="invalid-feedback">
                                        Please enter a valid Clinic ID (between 3 and 50 characters).
                                      </div>

                                  </div>
                                  <div className="col-12 text-center">
                                    {idAvail===true?<p className="text-success">Available</p>:idAvail === false ?<p className="text-danger">Not Available</p>:null}
                                  </div>

                                </div>
                                </Spin>


                              </div>
                              <div className="modal-footer">
                              <button className="btn btn-primary" type="submit" disabled={showAddBtn ? false : true} >Add Clinic ID </button>

                              </div>
                            </form>
                          </div>
                        </div>
                      </div>


                  </div>

                  <div
      tabIndex="-1"
      id="myModal-delete"
      className={`modal  ${
        deleteModal ? "d-block fading" : " d-none"
      }`}
    >
      <div className="modal-dialog modal-dialog-centered modal-confirm">
        <div className="modal-content">
          <div className="modal-header">
            <div className="icon-box mr-2">
              <i className="fas fa-trash-alt text-danger"></i>
            </div>
            <h4 className="modal-heading w-100 text-black">
              Are you sure?
            </h4>
            <button
              type="button"
              className="close"
              onClick={()=>{setDeleteModal(false);setCliId("");}}
            >
              &times;
            </button>
          </div>
          <div className="modal-body">
            <p>
              Do you really want to stop "{cliId}" clinical trial? This
              process cannot be undone.
            </p>
          </div>
          <div className="modal-footer justify-content-center">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={()=>{setDeleteModal(false);setCliId("");}}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => {

                stoppingClinicalTrials();
              }}
            >
              Stop
            </button>
          </div>
        </div>
      </div>
    </div>








    <div
tabIndex="-1"
id="existing-modal"
className={`modal  ${
subjectModal ? "d-block fading" : " d-none"
}`}
>
<div className="modal-dialog modal-dialog-centered modal-confirm">
<div className="modal-content">
<div className="modal-header">

<h4 className="modal-heading w-100 text-black">
These subjects are already added.
</h4>
<button
type="button"
className="close"
onClick={()=>{setSubjectModal(false);}}
>
&times;
</button>
</div>
<div className="modal-body" style={{maxHeight: "450px", overflow: "auto"}}>
<table className="table session-table" id="tab1">
  <thead style={{textAlign:'center'}}>
   <tr>

       <th>Subject ID</th>
    <th>Password</th>
  </tr>
  </thead>
  <tbody>
{existingSubjects!== null && existingSubjects.length>0 && existingSubjects.map((subject,index)=>(
  <tr keys={index}>
    <td>
    <p>
      {subject.subjectId}
      </p>
    </td>
    <td>
    <p>

    {subject.password}
    </p>
    </td>
  </tr>
))}
</tbody>
</table>
</div>
<div className="modal-footer justify-content-center">
<button
type="button"
className="btn btn-secondary"
onClick={()=>{setSubjectModal(false)}}
>
Cancel
</button>
<button
type="button"
className="btn btn-primary"
onClick={()=>{convertJsonToCsv(existingSubjects)}}
>
Download CSV
</button>

</div>
</div>
</div>
</div>

              </div>
          </div>
  </div>
);
}



  return (
    <h1 className="text-center" style={{height:"85vh"}}>You Are Not Athorized</h1>

  );
}
