
import React, { useEffect } from "react";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


am4core.useTheme(am4themes_animated);
// Themes end
function TrendsBloodGlucose(props) {
  // let chart;
  useEffect(() => {


    let chart = am4core.create("trendsBloodGlucose", am4charts.XYChart);

// Add data
chart.data = [{
    "date": new Date(2018, 3, 20),
    "value": 90
  }, {
    "date": new Date(2018, 3, 21),
    "value": 102
  }, {
    "date": new Date(2018, 3, 22),
    "value": 65
  }, {
    "date": new Date(2018, 3, 23),
    "value": 62
  }, {
    "date": new Date(2018, 3, 24),
    "value": 55
  }, {
    "date": new Date(2018, 3, 25),
    "value": 81
  }];


  // Create axes
var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
dateAxis.startLocation = 0.5;
dateAxis.endLocation = 0.5;
dateAxis.renderer.labels.template.disabled = true;

// Create value axis
var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
valueAxis.min = 0;

// Create series
var lineSeries = chart.series.push(new am4charts.LineSeries());
lineSeries.dataFields.valueY = "value";
lineSeries.dataFields.dateX = "date";
lineSeries.name = "Sales";
lineSeries.strokeWidth = 3;
lineSeries.fillOpacity = 1;

var fillModifier = new am4core.LinearGradientModifier();
fillModifier.opacities = [1, 0];
fillModifier.offsets = [0, 1];
fillModifier.gradient.rotation = 90;
lineSeries.segments.template.fillModifier = fillModifier;



}, []);

  return (<div className="">

        <div id="trendsBloodGlucose" style={{ width: "100%", minHeight: "300px" }}></div>
  </div>
  );
}
export default TrendsBloodGlucose;
