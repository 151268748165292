export const FETCH_COMPARE_RIGHT_OVERLAY_START ="FETCH_COMPARE_RIGHT_OVERLAY_START"
export const FETCH_COMPARE_RIGHT_OVERLAY_SUCCESS ="FETCH_COMPARE_RIGHT_OVERLAY_SUCCESS"
export const FETCH_COMPARE_RIGHT_OVERLAY_FAILED ="FETCH_COMPARE_RIGHT_OVERLAY_FAILED"

export const FETCH_COMPARE_LEFT_OVERLAY_START ="FETCH_COMPARE_LEFT_OVERLAY_START"
export const FETCH_COMPARE_LEFT_OVERLAY_SUCCESS ="FETCH_COMPARE_LEFT_OVERLAY_SUCCESS"
export const FETCH_COMPARE_LEFT_OVERLAY_FAILED ="FETCH_COMPARE_LEFT_OVERLAY_FAILED"

export const FETCH_COMPARE_BG_RIGHT_START ="FETCH_COMPARE_BG_RIGHT_START"
export const FETCH_COMPARE_BG_RIGHT_SUCCESS ="FETCH_COMPARE_BG_RIGHT_SUCCESS"
export const FETCH_COMPARE_BG_RIGHT_FAILED ="FETCH_COMPARE_BG_RIGHT_FAILED"

export const FETCH_COMPARE_BG_LEFT_START ="FETCH_COMPARE_BG_LEFT_START"
export const FETCH_COMPARE_BG_LEFT_SUCCESS ="FETCH_COMPARE_BG_LEFT_SUCCESS"
export const FETCH_COMPARE_BG_LEFT_FAILED ="FETCH_COMPARE_BG_LEFT_FAILED"

export const FETCH_COMPARE_SENSOR_RIGHT_START = "FETCH_COMPARE_SENSOR_RIGHT_START"
export const FETCH_COMPARE_SENSOR_RIGHT_SUCCESS = "FETCH_COMPARE_SENSOR_RIGHT_SUCCESS"
export const  FETCH_COMPARE_SENSOR_RIGHT_FAILED = "FETCH_COMPARE_SENSOR_RIGHT_FAILED"

export const FETCH_COMPARE_SENSOR_LEFT_START = "FETCH_COMPARE_SENSOR_LEFT_START"
export const FETCH_COMPARE_SENSOR_LEFT_SUCCESS = "FETCH_COMPARE_SENSOR_LEFT_SUCCESS"
export const  FETCH_COMPARE_SENSOR_LEFT_FAILED = "FETCH_COMPARE_SENSOR_LEFT_FAILED"


export const FETCH_COMPARE_LEFT_HISTORY_START ="FETCH_COMPARE_LEFT_HISTORY_START"
export const FETCH_COMPARE_LEFT_HISTORY_SUCCESS ="FETCH_COMPARE_LEFT_HISTORY_SUCCESS"
export const FETCH_COMPARE_LEFT_HISTORY_FAILED ="FETCH_COMPARE_LEFT_HISTORY_FAILED"

export const FETCH_COMPARE_RIGHT_HISTORY_START ="FETCH_COMPARE_RIGHT_HISTORY_START"
export const FETCH_COMPARE_RIGHT_HISTORY_SUCCESS ="FETCH_COMPARE_RIGHT_HISTORY_SUCCESS"
export const FETCH_COMPARE_RIGHT_HISTORY_FAILED ="FETCH_COMPARE_RIGHT_HISTORY_FAILED"
