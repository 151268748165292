import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";


import AllFilters from "../Components/Common/Filters";
import AverageGlucoseReport from "../Components/Reports/OverviewComps/AverageGlucoseReport";
import BloodGlucoseReport from "../Components/Reports/OverviewComps/BloodGlucoseReport";
import CarbohydratesReport from "../Components/Reports/OverviewComps/Carbohydrates";
import InsulinReport from "../Components/Reports/OverviewComps/Insulin";
import ActivityReport from "../Components/Reports/OverviewComps/Activity";

import SensorUsageReport from "../Components/Reports/OverviewComps/SensorUsageReport";
import TimeInRangeReport from "../Components/Reports/OverviewComps/TimeInRangeReport";

import { fetchBgData, fetchSensorData } from "../redux/actions/blood-glucose";
import { filterFromDate, filterToDate } from "../redux/actions/user";


import { getDevices } from "../redux/actions/device";
import * as _ from "lodash";
import moment from "moment";
import { Spin, Menu, Dropdown, Button, DatePicker } from "antd";
import Loading from "../Components/Common/Loading";


const ReportsPage = () => {
  const { RangePicker } = DatePicker;
  const history = useNavigate();

  const userData = useSelector((state) => state.user);
  const { unit, low, high } = useSelector((state) => state.settings.settings);

  console.log("UNIT", unit);
  if (userData.userDetails && userData.userDetails.user && userData.userDetails.user.role.length > 1) {
    history("/dashboard");
  }

  const data = useSelector((state) => {

    return (state.bloodGlucose)
  });

  const [filterText, setFilterText] = useState("Choose Data Range");
  const [filter, setFilter] = useState("30 days");
  const [isLoading, setIsLoading] = useState(true);
  const [organizedData, setOrganizedData] = useState([]);
  const [daysDif, setDaysDif] = useState("");


  const [tfHistoricalReadings, setTfHistoricalReadings] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [rangFilter, setRangeFilter] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [avgDevi, setAvgDevi] = useState();

  const [dropMenu, setDropMenu] = useState(false);


  const dispatch = useDispatch();

  useEffect(() => {

    if (filter !== "" || startDate !== "") {
      fetchReadings();
    }
  }, []);

  useEffect(() => {
    transformHistoricalReadings();
    setAvgDevi(calculateAverageDeviation(data.historicalReadings))
  }, [data.historicalReadings]);

  useEffect(() => {
    if (filter !== "" && startDate === "") {
    }
  }, [filter, startDate]);


  useEffect(() => {

  }, [filter,]);

  useEffect(() => {
    console.log("isLoading", isLoading);
  }, [isLoading]);

  const fetchReadings = async () => {
    setIsLoading(true);
    dispatch(fetchSensorData());
    let data = {};
    if (filter) {
      data.time = filter;
      data.unit = unit;
    }
    if (startDate) {

      data.start_date = startDate;
      data.end_date = endDate;
    }


    dispatch(fetchBgData(data));

    if (rangFilter === false) {
      let start = findStartDate(filter);

      setFromDate(moment(start).format('D MMM YYYY , h:mm a').toString());
      setToDate(moment(findStartDate()).format('D MMM YYYY , h:mm a').toString());
      const fromDat = moment(start).format('D MMM YYYY , h:mm a').toString();
      const toDat = moment(findStartDate()).format('D MMM YYYY , h:mm a').toString();
      dispatch(filterFromDate(fromDat));
      dispatch(filterToDate(toDat));

    } else if (rangFilter === true) {

    }
    setIsLoading(false);
  };


  const transformHistoricalReadings = () => {

    let transformedData = [];

    transformedData = data.historicalReadings
      .map((reading) => {


        return {
          date: new Date(reading.time),
          reading: reading.value,
        };
      })
      .flat();

    let myArray = _.sortBy(transformedData.flat(), function (dateObj) {

      return new Date(dateObj.date);
    });

    setTfHistoricalReadings(myArray);
  };

  function handleMenuClick(e) {
    switch (e.item.props.value) {
      case "today":
        break;
      case "week":
        break;
      case "month":
        break;
      case "range":
        break;
      default:
        break;
    }
  }

  const findStartDate = (filter) => {
    console.log("FILTERS DAY", filter);

    let currentDate = new Date();

    if (filter === "30days" || filter === "30 days") {
      let start = moment().subtract(30, 'days');
      return new Date(start._d);
    } else if (filter === "7days" || filter === "7 days") {
      let start = moment().subtract(7, 'days');

      return new Date(start._d);


    } else if (filter === "15days" || filter === "15 days") {

      let start = moment().subtract(14, 'days');
      return new Date(start._d);

    } else if (filter === "90days" || filter === "90 days") {

      let start = moment().subtract(90, 'days');
      return new Date(start._d);

    } else if (filter === "today") {

      return new Date();

    } else {

    }

  }
  const findEndDate = () => {
    let currentDate = new Date();
    return (currentDate);
  }


  const fetchRangeReadings = async () => {
    setIsLoading(true);
    setFilter("");
    if (startDate !== "" && endDate !== "") {
      let a = moment(startDate);
      let b = moment(endDate);
      let dif = moment.duration(b.diff(a)).asDays();
      let finalDif = dif > 1 ? dif + " Days" : dif + " Day";
      // // // // console.log(typeof(finalDif));

      setDaysDif(finalDif);


      setOrganizedData([]);

      let data = {};



      data.start_date = startDate;
      data.end_date = endDate;



      let ress = await dispatch(fetchBgData(data));
      // console.log("RESS :", ress);
      // setChartData(ress);

      if (rangFilter === false) {
        let start = findStartDate(filter);

        setFromDate(moment(start).format('D MMM YYYY , h:mm a').toString());
        setToDate(moment(findStartDate()).format('D MMM YYYY , h:mm a').toString());
        const fromDat = moment(start).format('D MMM YYYY , h:mm a').toString();
        const toDat = moment(findStartDate()).format('D MMM YYYY , h:mm a').toString();
        dispatch(filterFromDate(fromDat));
        dispatch(filterToDate(toDat));
      } else if (rangFilter === true) {

        setFromDate(moment(startDate).format('D MMM YYYY , h:mm a').toString());
        setToDate(moment(endDate).format('D MMM YYYY , h:mm a').toString());

        const fromDat = moment(startDate).format('D MMM YYYY , h:mm a').toString();
        const toDat = moment(endDate).format('D MMM YYYY , h:mm a').toString();
        dispatch(filterFromDate(fromDat));
        dispatch(filterToDate(toDat));
      }
    }
    setIsLoading(false);
  }

  const fetchFilterReadings = (e) => {
    // console.log("event", e.target);
    setStartDate("");
    setEndDate("");
    //setShowDate(false);
    setFilter(e.target.name);
    setFilterText(e.target.name);
    // setWeekFilter(true);
    // setTodayFilter(false);
    // setMonthFilter(false);
    setRangeFilter(false);
    setIsLoading(true);
    setDropMenu(false);


    if (filter !== e.target.name) {
      setOrganizedData([]);
    }

    dispatch(fetchSensorData());
    let data = {};

    data.time = e.target.id;
    data.unit = unit;



    dispatch(fetchBgData(data));

    if (rangFilter === false) {
      // console.log("CALLED IN FIL");
      let start = findStartDate(e.target.name);
      console.log("start", start);
      setFromDate(moment(start).format('D MMM YYYY , h:mm a').toString());
      setToDate(moment(findStartDate()).format('D MMM YYYY , h:mm a').toString());
      const fromDat = moment(start).format('D MMM YYYY , h:mm a').toString();
      const toDat = moment(findStartDate()).format('D MMM YYYY , h:mm a').toString();
      // console.log("fromDat", start);
      dispatch(filterFromDate(fromDat));
      dispatch(filterToDate(toDat));
    } else if (rangFilter === true) {

    }
    setIsLoading(false);

  }
  const calculateAverageDeviation = (data) => {
    // Step 1: Extract values from the data
    const values = data.map(item => item.value);

    // Step 2: Calculate the mean
    const mean = values.reduce((sum, value) => sum + value, 0) / values.length;

    // Step 3: Calculate the sum of absolute deviations
    const sumOfAbsoluteDeviations = values.reduce((sum, value) => sum + Math.abs(value - mean), 0);

    // Step 4: Calculate the average deviation
    const averageDeviation = sumOfAbsoluteDeviations / values.length;

    return averageDeviation;
  }

  return (


    <div className="container-fluid h-100 bg-white p-0">
      {/*<Navbar user={userData}/>*/}
      <div className="row h-100 bg-light rounded mx-1 pl-md-2 pl-0 py-2">
        {/*<aside className="col-12 col-md-2 bg-primary py-3  rounded ">
                    <Sidebar/>
                </aside>*/}
        {/*<main className="col  bg-faded ">*/}
        {/*<OverViewComp/>*/}
        <div className="col-12">
          <div className="row  mr-sm-0 ">
            <div className="col-12 mt-md-0 mt-2" style={{ height: "100%" }}>
              <div className="bg-white rounded shadow " style={{ height: "100%" }}>
                <div className="">
                  <div className="d-flex justify-content-between mb-2">
                    <div className="chart-heading mt-3 loginNew-HCP-panel">
                      <span className="bg-primary px-2 py-2 font-size2 font-weight-bold text-white">
                        Overview
                      </span>
                    </div>
                  </div>
                  <AllFilters filter={filter} rangFilter={rangFilter} setRangeFilter={setRangeFilter}
                    setFilter={setFilter}
                    daysDif={daysDif} fromDate={fromDate} toDate={toDate}
                    startDate={startDate} fetchFilterReadings={fetchFilterReadings}
                    fetchRangeReadings={fetchRangeReadings} startDate={startDate}
                    endDate={endDate} dropMenu={dropMenu} setDropMenu={setDropMenu}
                    setStartDate={setStartDate} setEndDate={setEndDate} filterText={filterText} loader={setIsLoading} setFilterText={setFilterText} showDays="" showRange={true} />
                </div>
              </div>
            </div>
          </div>

          {isLoading ?
            <Loading />
            :
            <><div className="row  mr-sm-0  mt-2">
              <div className="col-12   col-sm-6 col-md-3" style={{ height: "300px" }}>
                <div className="bg-white rounded shadow " style={{ height: "100%" }}>
                  <AverageGlucoseReport avg={data} unit={unit} averageDeviation={avgDevi} />
                </div>
              </div>

              <div className="col-12   col-sm-6 col-md-6 timeRange my-md-0 my-2" style={{ height: "300px" }}>
                <div className="bg-white rounded shadow " style={{ height: "100%" }}>
                  <TimeInRangeReport chartId="overviewChart" data={data} unit={unit} low={low} high={high} />
                </div>
              </div>

              <div className="col-12   col-sm-6 col-md-3" style={{ height: "300px" }}>
                <div className="bg-white rounded shadow " style={{ height: "100%" }}>
                  <SensorUsageReport sensorDetails={data.sensorDetails ? data.sensorDetails : 0} />
                </div>
              </div>
            </div><div className="row  mr-sm-0  mt-2 justify-content-center">
                <div className=" col-md-12" style={{ height: "600px" }}>
                  <div className="bg-white rounded shadow " style={{ height: "100%" }}>

                    <BloodGlucoseReport data={tfHistoricalReadings} unit={unit} carbs={data.carbs} medication={data.medication} activities={data.activities} insulin={data.insulin} />
                  </div>
                </div>
              </div>
              {/* <div className="row  mr-sm-0  mt-2 justify-content-center">
                <div className=" col-md-12">
                  <div className="bg-white rounded shadow">

                    <ActivityReport data={data.activities} />
                  </div>
                </div>
              </div> */}
              </>}

        </div>

        {/*</main>*/}
      </div>
    </div>
  );
};

export default ReportsPage;
