import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";



import { Tabs } from "antd";

import Admins from "./Admins/Admins";
import Clinician from "./Clinician/Clinician";
import Engineers from "./Engineering/Engineer";
import EngineersList from "./Engineers/EngineersList";
import Patients from "./Patients/Patients";




const { TabPane } = Tabs;


const UserMangement=(props)=> {
  const {userDetails, selectedUser} = useSelector((state) => {return (state.user)});
  const {modus2} = useSelector((state) => {return (state.admin)});


  // useEffect(() => {
  // }, [modus2]);


  return (
    <>
    {userDetails && userDetails.user && (userDetails.user.role.includes(1)===true || userDetails.user.role.includes(0)===true)?
    <div className="row mw-100 m-auto">
      <div className="col-12">
      <div className="bg-white p-3 rounded-lg">

        <Tabs type="card">
        {userDetails && userDetails.user && userDetails.user.role.includes(1)===true?
          null
          :<TabPane tab="Admins" key="0">
            {/*<VitalInfo />*/}
            <Admins role="admin" app={modus2}/>
          </TabPane>}
          <TabPane tab="Clinical Admins" key="1">
            {/*<VitalInfo />*/}
            <Clinician role="clinician"/>
          </TabPane>
          <TabPane tab="Engineer Admins" key="2">
            {/*<SocialHistory />*/}
            <Engineers role="engineer" app={modus2}/>
          </TabPane>
          <TabPane tab="Engineers" key="3">
            {/*<HospitalAdmissionHistory />*/}
            <EngineersList role="engineer" app={modus2}/>
          </TabPane>
          <TabPane tab="Patients" key="4">
            {/*<HospitalAdmissionHistory />*/}
            <Patients role="patient" app={modus2}/>
          </TabPane>
        </Tabs>

      </div>
      </div>

    </div>:
    <div className="row mw-100 m-auto">
      <div className="col-12">
      <h1 className="text-center" style={{height:"85vh"}}>You Are Not Athorized</h1>
      </div>
    </div>}



    </>
  );
}
export default UserMangement;
