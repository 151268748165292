import React from "react";
// import { useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom';

import Logo from "../../Assets/Logo/GlucoRxLogo.svg";

import LoadingIcon from "../../Assets/loading.gif";

// import { useLocation } from 'react-router-dom';


const Loading = (props) => {
  // const location = useLocation();

  return (
    <div className= "col-12 col-md-4 m-auto text-center">
      <div className="row mw-100 m-auto h-100 loading">
        <div className="col-12">
        <img src={Logo} className="w-50 grx-logo" alt="AIDEX LOGO"/>

        </div>
        <div className="col-12">
          <img src={LoadingIcon} className="w-50" alt="Loading Gif"/>
        </div>
        <div className="col-12">
        <p className="font-bold">{props && props.uploading && props.uploading===true?"PLEASE WAIT UPLOADING FILE":"PLEASE WAIT DOWNLOADING DATA"}</p>

        </div>

      </div>
    </div>
  );
};

export default Loading;
