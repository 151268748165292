import { combineReducers } from "redux";
import auth from "./auth";
import user from "./user";
import devices from "./device";
import bloodGlucose from "./blood-glucose";
import overlay from "./overlay";
import ogp from "./ogp";

import history from "./history";
import settings from "./settings";
import admin from "./admin";
import compareRight from "./compareRight";
import compareLeft from "./compareLeft";
import ota from "./ota";
import analytics from "./analytics";





const rootReducer = combineReducers({
  auth,
  user,
  bloodGlucose,
  devices,
  overlay,
  history,
  settings,
  admin,
  ogp,
  compareRight,
  compareLeft,
  ota,
  analytics,

});

export default (state, action) =>
  rootReducer(action.type === "USER_LOGGED_OUT" ? undefined : state, action);
