import React from 'react';
import ForgetPassword from "../Components/Auth/ForgetPassword";

const ForgetPasswordPage = () => {
    return (
        <div>
            <ForgetPassword/>
        </div>
    );
};

export default ForgetPasswordPage;