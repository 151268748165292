import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { Spin } from "antd";
import { getClinicians,
   makingPatient,
   makingEngineerAdmin,
   addClinicAdmin,
   checkEmail,
   removeClinicalAdmin,
   suspendUser ,
   activateUser,
   makingEngineer,
   makingAdmin,
   removeRole,
 } from "../../../../redux/actions/admin/admin";

 import { useNavigate, useLocation } from "react-router-dom";
// import {encrypt,decrypt} from "../../../utils/encryption";
import UserDetails from "./userstatchart.js";
import countryList from './CountriesList';


import moment from "moment";

// import UsersMangement from "../../common/nav/usersmanagement.js";

const Clinicians = (props) => {
  // // console.log("PROPS", props);

    const dispatch = useDispatch();
    const history = useNavigate();


    // const [role, setRole] = useState("patient");
    const list = useSelector((state) => {return (state.admin.clinicians)});
    const {modus2} = useSelector((state) => {return (state.admin)});
    const {userDetails} = useSelector((state) => {return (state.user)});



    const [actModal, setActModal] = useState(false);
    const [toActivate, setToActivate] = useState("");

    const [removeRoleModal, setRemoveRoleModal] = useState(false);
    const [toRoleRemove, setToRoleRemove] = useState("");
    const [toRoleRemoveId, setToRoleRemoveId] = useState("");



    const [delModal, setDelModal] = useState(false);
    const [toDelete, setToDelete] = useState("");
    const [susModal, setSusModal] = useState(false);
    const [toSuspend, setToSuspend] = useState("");
    const [addModal, setAddModal] = useState(false);
    const [instance, setInstance] = useState("");
    const [idAvail, setIdAvail] = useState(null);


    const [editModal, setEditModal] = useState(false);
    const [showSave, setShowSave] = useState(false);
    const [delValid, setDelValid] = useState(false);


    const [toEdit, setToEdit] = useState("");
    const [selectedRole, setSelectedRole] = useState("");

    // Add Clinician
        const [firstName, setFirstName] = useState("");
        const [lastName, setLastName] = useState("");
        const [gender, setGender] = useState("");
        const [dob, setDob] = useState();
        const [email, setEmail] = useState("");
        const [clinicianApp, setClinicianApp] = useState("");

        const [address, setAddress] = useState("");
        const [town, setTown] = useState("");
        const [postCode, setPostCode] = useState("");
        const [country, setCountry] = useState("");
        const [phone, setPhoneNo] = useState("");

        const [idMessage, setIdMessage] = useState("");



        // To add New Clinician

        const toAddClinician = async (e) => {
              e.preventDefault()



                    let postcode =postCode;
                    // let eApp =clinicianApp;
                    let eAddData = {
                      firstName:firstName,
                      lastName:lastName,gender:parseInt(gender),dateOfBirth:dob,email:email,
                      app:"modus",address:address,town:town,postcode:postcode,
                      country:country,phone:phone
                    }
                    try {

                      // setIsLoading(true);
                      // e.preventDefault();
                      let result = await dispatch(addClinicAdmin(eAddData));
                      ////("Result",result);
                      // setIsLoading(false);
                      // ShowToast(result.data.message,"success");
                      setAddModal(false);
                      fetchClinicians();



                    } catch (err) {
                      ////(err.response)

                      // ShowToast(err.response.data.message,"error");
                      // setIsLoading(false);
                    }


                    return true;


                  };


    useEffect(() => {
          fetchClinicians();
      },[]);
      useEffect(() => {
            // // console.log("instance", instance);
        }, [instance]);

      const handleRole =(event)=>{
        setSelectedRole(event.target.value);

        if(event.target.value === ""){
          setShowSave(false);

        }else {
          setShowSave(true);

        }

      }
      const fetchClinicians = async () => {
        // // console.log("APP", modus2);
      dispatch(getClinicians(6,"modus"))

        };

        const assignRole = async () => {
  let dispatchAction;

  switch (selectedRole) {
    case "engineer":
      dispatchAction = makingEngineer(toEdit);
      break;
    case "admin":
      dispatchAction = makingAdmin(toEdit);
      break;
    case "patient":
      dispatchAction = makingPatient(toEdit);
      break;
    case "engineerAdmin":
      dispatchAction = makingEngineerAdmin(toEdit);
      break;
    default:
      // Handle other roles if needed
      break;
  }

  if (dispatchAction) {
    dispatch(dispatchAction);
  }

  setEditModal(false);
};
          const saveId=(id)=>{
            // dispatch(fetchClinicianData(id));
            document.getElementById("clinicianTab").click();
            //history("/admin/viewClinician");
            // dispatch(props.setEData(true))
          }


          const implementChecks = (val) => {
            if(val.length>0){
              setIdAvail(null);
              setIdMessage("");
            }
          }

          const deletingClinicalAdmin=async()=>{
          const res =  await dispatch(removeClinicalAdmin(toDelete));
          if(res===true){
            setDelModal(false);
            fetchClinicians();

          }
          }
          const suspendingUser=async()=>{
          const res =  await dispatch(suspendUser(toSuspend));
          if(res===true){
            setSusModal(false);
            fetchClinicians();

          }
          }
        const checkInstanceEmail=(e)=>{
            // // console.log("event :", e.target.value);
            if(e.target.value === instance.email){
              // // console.log("YES");
              setDelValid(true);
            }else{
              setDelValid(false);

            }
          }

          const checkClientEmail = async(val) => {
            // console.log("val", val);
            if(val!==""){
            let res = await dispatch(checkEmail(val));

            if(res.status && res.status === 200) {
              // console.log("Res in Comp", res);
              setIdAvail(true);
              setIdMessage(res.data.message);
            } else{
              // console.log("Res in Comp else", res.response.data.message);

              setIdAvail(false);
              setIdMessage(res.response.data.message);
            }

          }
          }

          const activatingUser=async()=>{
          const res =  await dispatch(activateUser(toActivate));
          if(res===true){
            setActModal(false);
            fetchClinicians();

          }
          }


          const removingRole = async () => {
  let roleCode;

  switch (selectedRole) {
    case "engineer":
      roleCode = 3;
      break;
    case "admin":
      roleCode = 1;
      break;
    case "clinicalAdmin":
      roleCode = 6;
      break;
    case "engineerAdmin":
      roleCode = 5;
      break;
    case "patient":
      roleCode = 4;
      break;
    default:
      break;
  }

  if (roleCode) {
    dispatch(removeRole(toRoleRemove, roleCode));
    setRemoveRoleModal(false);
    setShowSave(false);
    fetchClinicians();
  }
};

            const handleRemoveRole =(event)=>{
              setSelectedRole(event.target.value);
              // // console.log("event.target.value", event.target.value);
              setShowSave(true);

            }
            const setMaxDate =()=>{
              const datePickerCli = document.getElementById("datePickerCli");
              datePickerCli.max = new Date().toISOString().split("T")[0];
            }
    return (
        <div className="container-fluid patient-list">
            {/*<div className="container bg-white rounded my-5 p-3">*/}
            {/* <form onSubmit={handleSubmit}> */}
                <div className="row p-2 px-3">
                    <div className="col-12">
                        <div className="row justify-content-between pl-3">

                            <div className="col-12 col-md-6">
                              <h1 className="pt-3 pb-3 font-weight-bold chart-heading">Clinical Admins's Details</h1>
                            </div>
                            <div className="col-12 col-md-6 text-right">
                              <button className="btn btn-primary mt-3" onClick={()=>{setAddModal(true); setMaxDate();}}>Add Clinic Admin</button>
                            </div>
                            {/*<div className="col-12 col-md-6 text-right">
                              <button className="btn btn-primary mt-3" onClick={()=>{setAddModal(true)}}>Add Clinician</button>
                            </div>*/}
                            <div className="col-12">
                              <table className="table table-hover" id="task-table">
                                <thead>
                                  <tr>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>DOB</th>
                                    <th>Email</th>
                                    <th>Joined At</th>
                                    <th>Status</th>
                                    <th>Country</th>
                                    <th>City</th>
                                    <th>Phone</th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {list !== null && list.length > 0 && list.map((row, index) => (
                                    <tr key={index}>
                                      <td>{row.firstName}</td>
                                      <td>{row.lastName}</td>
                                      <td>{moment(row.dateOfBirth).format("D MMM YYYY")}</td>
                                      <td>{row.email}</td>
                                      <td>{moment(row.createdAt).format("D MMM YYYY")}</td>
                                      <td>{row.status}</td>
                                      <td>{row.country}</td>
                                      <td>{row.city}</td>
                                      <td>{row.phone}</td>
                                      <td className="text-left">
                                        {row.role.length !== 5 && (
                                          <button
                                            type="button"
                                            className="mr-2"
                                            data-toggle="modal"
                                            data-target="#addHospitalModal"
                                            onClick={() => {
                                              setEditModal(true);
                                              setToEdit(row._id);
                                            }}
                                          >
                                            <span className="">
                                              <i className="fas fa-edit text-primary"></i>
                                            </span>
                                          </button>
                                        )}

                                        {userDetails?.user?.role?.includes(0) && (
                                          <button
                                            type="button"
                                            className="mr-2"
                                            onClick={() => {
                                              setToDelete(row._id);
                                              setInstance(row);
                                              setDelModal(true);
                                            }}
                                          >
                                            <span className="">
                                              <i className="fas fa-trash-alt text-danger"></i>
                                            </span>
                                          </button>
                                        )}

                                        {userDetails?.user?.role?.includes(0) && row.status === "active" && (
                                          <button
                                            type="button"
                                            className="mr-2"
                                            onClick={() => {
                                              setToSuspend(row._id);
                                              setSusModal(true);
                                            }}
                                          >
                                            <span className="">
                                              <i className="fa fa-ban text-danger" aria-hidden="true"></i>
                                            </span>
                                          </button>
                                        )}

                                        {userDetails?.user?.role?.includes(0) && row.status !== "active" && (
                                          <button
                                            type="button"
                                            className="mr-2"
                                            onClick={() => {
                                              setToActivate(row._id);
                                              setActModal(true);
                                            }}
                                          >
                                            <span className="">
                                              <i className="fa fa-check text-success"></i>
                                            </span>
                                          </button>
                                        )}

                                        {userDetails?.user?.role?.includes(0) && (
                                          <button
                                            type="button"
                                            className="mr-2"
                                            onClick={() => {
                                              setInstance(row);
                                              setRemoveRoleModal(true);
                                              setToRoleRemove(row._id);
                                            }}
                                          >
                                            <span className="">
                                              <i className="fa fa-users" aria-hidden="true"></i>
                                            </span>
                                          </button>
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                            <div className={editModal? "modal d-block fading" : "modal d-none"}>
                                <div className="modal-dialog modal-dialog-centered modal-confirm">
                                  <div className="modal-content">
                                    <div className="modal-header flex-column">
                                      <div className="icon-box">
                                        <i className="fas fa-edit text-primary"></i>
                                      </div>
                                      <h4 className="modal-heading w-100 text-black">Change Role</h4>
                                      <button type="button" className="close" onClick={()=>{setEditModal(false)}} >&times;</button>
                                    </div>
                                    <div className="modal-body">
                                    <label htmlFor="roles">Choose a New Role &nbsp; </label>

                                    <select name="roles" id="roles" onChange={(e)=>{handleRole(e)}}>
                                      <option value=""></option>
                                      {/*<option value="clinician">Clinician</option>*/}

                                      {instance!== "" && instance!== null && instance.role.includes(1) === false?

                                      <option value="engineer">Admin</option>
                                      :null}

                                      {instance!== "" && instance!== null && instance.role.includes(3) === false?

                                      <option value="engineer">Engineer</option>
                                      :null}

                                      {instance!== "" && instance!== null && instance.role.includes(4) === false?

                                      <option value="patient">Patient</option>
                                      :null}
                                      {instance!== "" && instance!== null && instance.role.includes(5) === false?

                                      <option value="engineerAdmin">Engineer Admin</option>
                                      :null}


                                    </select>
                                    </div>
                                    <div className="modal-footer justify-content-center">
                                      {showSave?<button type="button" className="btn btn-secondary" onClick={()=>{assignRole()}}>Save</button>: null}

                                    </div>
                                  </div>
                                </div>

                              </div>


                            <div className={delModal? "modal d-block fading" : "modal d-none"}>
                                <div className="modal-dialog modal-dialog-centered modal-confirm">
                                  <div className="modal-content">
                                    <div className="modal-header flex-column">
                                      <div className="icon-box">
                                        <i className="fas fa-trash-alt text-danger"></i>
                                      </div>
                                      <h4 className="modal-heading w-100 text-black">Are you sure?</h4>
                                      <button type="button" className="close" onClick={()=>{setDelModal(false); setDelValid(false)}} >&times;</button>
                                    </div>
                                    <div className="modal-body">
                                    <p>Do you really want to delete this account?<br></br> Type "<strong>{instance.email}</strong>" to confirm deletion.</p>

                                      <input
                                      type="email"
                                      className="form-control"
                                      placeholder="Email"
                                      id="title"
                                      onChange={(e)=>{checkInstanceEmail(e)}}
                                      required
                                    />
                                    </div>
                                    <div className="modal-footer justify-content-center">
                                      <button type="button" className="btn btn-secondary" onClick={()=>{setDelModal(false); setDelValid(false)}}>Cancel</button>

                                        {delValid === true?<button type="button" className="btn btn-danger" onClick={(e)=>{deletingClinicalAdmin()}} >Delete</button>:null}

                                    </div>
                                  </div>
                                </div>

                              </div>
                              <div className={susModal? "modal d-block fading" : "modal d-none"}>
                                  <div className="modal-dialog modal-dialog-centered modal-confirm">
                                    <div className="modal-content">
                                      <div className="modal-header flex-column">
                                        <div className="icon-box">
                                          <i className="fas fa-ban text-danger"></i>
                                        </div>
                                        <h4 className="modal-heading w-100 text-black">Are you sure?</h4>
                                        <button type="button" className="close" onClick={()=>{setSusModal(false)}} >&times;</button>
                                      </div>
                                      <div className="modal-body">
                                        <p>Do you really want to suspend this records? This process cannot be undone.</p>
                                      </div>
                                      <div className="modal-footer justify-content-center">
                                        <button type="button" className="btn btn-secondary" onClick={()=>{setSusModal(false)}}>Cancel</button>
                                        <button type="button" className="btn btn-danger" onClick={()=>{suspendingUser()}} >Suspend</button>
                                      </div>
                                    </div>
                                  </div>

                                </div>
                                <div className={actModal? "modal d-block fading" : "modal d-none"}>
                                    <div className="modal-dialog modal-dialog-centered modal-confirm">
                                      <div className="modal-content">
                                        <div className="modal-header flex-column">
                                          <div className="icon-box">
                                            <i className="fas fa-ban text-danger"></i>
                                          </div>
                                          <h4 className="modal-heading w-100 text-black">Are you sure?</h4>
                                          <button type="button" className="close" onClick={()=>{setActModal(false)}} >&times;</button>
                                        </div>
                                        <div className="modal-body">
                                          <p>Do you really want to Activate this user? This process cannot be undone.</p>
                                        </div>
                                        <div className="modal-footer justify-content-center">
                                          <button type="button" className="btn btn-secondary" onClick={()=>{setActModal(false)}}>Cancel</button>
                                          <button type="button" className="btn btn-danger" onClick={()=>{activatingUser()}} >Activate</button>
                                        </div>
                                      </div>
                                    </div>

                                  </div>
                                <div className={editModal? "modal d-block fading" : "modal d-none"}>
                                    <div className="modal-dialog modal-dialog-centered modal-confirm">
                                      <div className="modal-content">
                                        <div className="modal-header flex-column">
                                          <div className="icon-box">
                                            <i className="fas fa-edit text-primary"></i>
                                          </div>
                                          <h4 className="modal-heading w-100 text-black">Change Role</h4>
                                          <button type="button" className="close" onClick={()=>{setEditModal(false)}} >&times;</button>
                                        </div>
                                        <div className="modal-body">
                                        <label htmlFor="roles">Choose a New Role &nbsp; </label>

                                        <select name="roles" id="roles" onChange={(e)=>{handleRole(e)}}>
                                          <option value=""></option>
                                          <option value="engineerAdmin">Engineer Admin</option>
                                          <option value="patient">Patient</option>

                                        </select>
                                        </div>
                                        <div className="modal-footer justify-content-center">
                                          {showSave?<button type="button" className="btn btn-secondary" onClick={()=>{assignRole()}}>Save</button>: null}

                                        </div>
                                      </div>
                                    </div>

                                </div>
                                <div className={removeRoleModal? "modal d-block fading" : "modal d-none"}>
                                    <div className="modal-dialog modal-dialog-centered modal-confirm">
                                      <div className="modal-content">
                                        <div className="modal-header flex-column">
                                          <div className="icon-box">
                                            <i className="fas fa-edit text-primary"></i>
                                          </div>
                                          <h4 className="modal-heading w-100 text-black">Change Roles</h4>
                                          <button type="button" className="close" onClick={()=>{setRemoveRoleModal(false)}} >&times;</button>
                                        </div>
                                        <div className="modal-body">
                                        <label htmlFor="roles">Select a Role to be removed &nbsp; </label>

                                        <select name="roles" id="roles" onChange={(e)=>{handleRemoveRole(e)}}>
                                          <option value=""></option>
                                          {/*<option value="clinician">Clinician</option>*/}

                                          {instance!== "" && instance!== null && instance.role.includes(1) === true?

                                          <option value="admin">Admin</option>
                                          :null}

                                          {instance!== "" && instance!== null && instance.role.includes(3) === true?

                                          <option value="engineer">Engineer</option>
                                          :null}

                                          {instance!== "" && instance!== null && instance.role.includes(4) === true?

                                          <option value="patient">Patient</option>
                                          :null}
                                          {instance!== "" && instance!== null && instance.role.includes(5) === true?

                                          <option value="engineerAdmin">Engineer Admin</option>
                                          :null}
                                          {instance!== "" && instance!== null && instance.role.includes(6) === true?

                                          <option value="clinicalAdmin">Clinical Admin</option>
                                          :null}

                                        </select>
                                        </div>
                                        <div className="modal-footer justify-content-center">
                                          {showSave?<button type="button" className="btn btn-secondary" onClick={()=>{removingRole()}}>Remove</button>: null}

                                        </div>
                                      </div>
                                    </div>

                                  </div>

                                <div
                                    className={`modal big_modal_dialog ${
                                        addModal? "d-block modal-fading" : " d-none"
                                    }`}
                                    tabIndex="-1"
                                    data-backdrop="static"
                                    data-keyboard="false"

                                    role="dialog"

                                    aria-hidden="true"
                                >
                                  <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                      <div className="modal-header">
                                        <h5 className="modal-title" id="addHospitalModalTitle">
                                          Add Clinician
                                        </h5>
                                        <button
                                            type="button"
                                            className="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                            onClick={()=>setAddModal(false)}
                                        >
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                      <form className="pl-3 pr-3" onSubmit={toAddClinician}>
                                        <div className="container my-4 ">
                                        <div className="row justify-content-center align-items-center mb-3 text-white">
                                          <div className="col-md-2 registration-input-labels"><p className="text-black">Email</p></div>
                                          <div className="col-md-10">
                                            <input
                                              type="email"
                                              value={email}
                                              onChange={(e) => {setEmail(e.target.value); implementChecks(e.target.value)}}
                                              onBlur={(e)=> checkClientEmail(e.target.value)}
                                              className={idAvail ===true ?"form-control border-success": idAvail=== false ?"form-control border-danger":"form-control"}

                                              required
                                              style={{color:"black"}}
                                            />
                                            <span className={idAvail === true?"text-success ml-3":"text-danger ml-3"}>{idMessage}</span>
                                          </div>
                                        </div>
                                          <div className="row justify-content-center align-items-center mb-3 text-white">
                                            <div className="col-md-2 registration-input-labels"> <p className="text-black">First Name</p></div>
                                            <div className="col-md-4">
                                            <div className="form-group">

                                            <input type="text" className="form-control" id="firstName" name="firstName" placeholder="Enter your first name"
                                              pattern=".{3,50}" required title="First Name should be between 3 and 50 characters long"
                                              style={{color:"black"}}
                                              value={firstName}
                                              onChange={(e) => setFirstName(e.target.value)}
                                               type="text"/>
                                              <div className="invalid-feedback">
                                                Please enter a valid first name (between 3 and 50 characters).
                                              </div>
                                            </div>
                                            </div>
                                            <div className="col-md-2 registration-input-labels"><p className="text-black">Last Name</p></div>
                                            <div className="col-md-4">
                                            <div className="form-group">

                                            <input type="text" className="form-control" id="lastName" name="lastName" placeholder="Enter your last name"
                                              pattern=".{3,50}" required title="Last Name should be between 3 and 50 characters long"
                                              style={{color:"black"}}
                                              value={lastName}
                                              onChange={(e) => setLastName(e.target.value)}
                                               type="text"/>
                                              <div className="invalid-feedback">
                                                Please enter a valid first name (between 3 and 50 characters).
                                              </div>
                                            </div>

                                            </div>
                                          </div>

                                          <div className="row justify-content-center align-items-center mb-3 text-white">
                                            <div className="col-md-2 registration-input-labels"><p className="text-black">Gender</p></div>
                                            <div className="col-md-4">
                                            <div className="form-group">

                                              <select className="form-control" id="gender" name="gender" required  value={gender}
                                              onChange={(e) => setGender(e.target.value)}
                                        style={{color:"black"}}>
                                                <option value="" disabled selected>Select a gender</option>
                                                <option value="1">Male</option>
                                                <option value="2">Female</option>
                                                <option value="3">Other</option>
                                                <option value="4">Prefer Not To Share</option>
                                              </select>
                                              <div className="invalid-feedback">
                                                Please select your gender.
                                              </div>
                                            </div>


                                            </div>
                                            <div className="col-md-2 registration-input-labels"><p className="text-black">DOB</p></div>
                                            <div className="col-md-4">
                                              <input
                                                value={dob}
                                                onChange={(e) => setDob(e.target.value)}
                                                type="date"
                                                className="registration-datePicker"
                                                id="datePickerCli"
                                                required
                                                style={{color:"black"}}
                                              />
                                            </div>
                                          </div>
                                          {/*<div className="row justify-content-center">
                                          <div className="col-md-2 registration-input-labels"><p className="text-black">App</p></div>

                                            <div className="col-md-10 col-10 ">
                                              <div className="form-group">
                                                <div className="styled-select">
                                                  <select
                                                    name="relation"
                                                    placeholder="Select a Role"
                                                    className="form-control input-md msg"

                                                    onChange={(e) =>{

                                                      setClinicianApp(e.target.value);
                                                    }
                                                    }
                                                    required
                                                    style={{color:"black"}}
                                                  >
                                                    <option value="">Select</option>
                                                    <option value="eostre">Eostre</option>
                                                    <option value="modus">Modus</option>


                                                  </select>

                                                </div>
                                              </div>
                                            </div>
                                          </div> */}



                                          <div className="row justify-content-center align-items-center mb-3 text-white">
                                            <div className="col-md-2 registration-input-labels"><p className="text-black">Phone No</p></div>
                                            <div className="col-md-10">
                                            <div className="form-group">

                                                  <input type="tel" className="form-control" id="phone" name="phone" placeholder="Enter your phone number"
                                                         pattern="[+]?[0-9]{9,15}" required title="Phone should be between 9 and 15 digits and only accept the + sign" value={phone}
                                                         onChange={(e) => setPhoneNo(e.target.value)} style={{color:"black"}}/>
                                                  <div className="invalid-feedback">
                                                    Please enter a valid phone number (between 9 and 15 digits and only accept the + sign).
                                                  </div>
                                                </div>
                                            </div>
                                          </div>

                                          <div className="row justify-content-center align-items-center mb-3 text-white">
                                            <div className="col-md-2 registration-input-labels"><p className="text-black">Address</p></div>
                                            <div className="col-md-10">
                                              <input
                                              value={address}
                                              onChange={(e) => setAddress(e.target.value)}
                                                type="text"
                                                className="form-control"

                                                required
                                                style={{color:"black"}}
                                              />
                                            </div>
                                          </div>

                                          <div className="row justify-content-center align-items-center mb-3 text-white">
                                            <div className="col-md-2 registration-input-labels"> <p className="text-black">Town</p></div>
                                            <div className="col-md-10">
                                              <input
                                               value={town}
                                               onChange={(e) => setTown(e.target.value)}
                                                type="text"
                                                className="form-control"

                                                required
                                                style={{color:"black"}}
                                              />
                                            </div>
                                          </div>

                                          <div className="row justify-content-center align-items-center mb-3 text-white">
                                            <div className="col-md-2 registration-input-labels"><p className="text-black">Postcode</p></div>
                                            <div className="col-md-10">
                                              <input
                                              value={postCode}
                                              onChange={(e) => setPostCode(e.target.value)}
                                                type="text"
                                                className="form-control"

                                                required
                                                style={{color:"black"}}
                                              />
                                            </div>
                                          </div>

                                          <div className="row justify-content-center align-items-center mb-3 text-white">
                                            <div className="col-md-2 registration-input-labels "><p className="text-black">Country</p></div>
                                            <div className="col-md-10">
                                            <div className="styled-select">
                                                <select
                                                   value={country}
                                                   onChange={(e) => setCountry(e.target.value)}
                                                  placeholder="Select a country"
                                                  className="form-control "
                                                  style={{color:"black"}}

                                                >
                                                  <option value="Select">Select Country</option>
                                                  {countryList.map((option, index) => (
                            <option key={index} value={option}>{option}</option>
                          ))}
                                                </select>
                                                <i className="fa fa-chevron-down"></i>
                                              </div>

                                            </div>
                                          </div>
                                        </div>
                                        <div className="modal-footer">
                                        <button className="btn btn-primary" type="submit">Add Clinic Admin </button>

                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                        </div>

                    </div>
                </div>
            {/*</div>*/}
        </div>
    );
};

export default Clinicians;
