import crypto from "crypto-js";
// require("crypto-js/aes");
// require("crypto-js/mode-gcm");
import moment from "moment";
import { Buffer } from "buffer";

const algorithm = 'aes-256-gcm',
    AES256Password = '56cf86ert74ghlk58624s3rfgh558000',
    // do not use a global iv for production,
    // generate a new one for each encryption
    iv = '0123456789qw';

export  const encrypt =async  (text) => {
  // console.log("TEXT", text);
    const encoder = new TextEncoder();
    const data = encoder.encode(text);

    // Import the key
    const cryptoKey = await window.crypto.subtle.importKey(
        "raw",
        encoder.encode(AES256Password),
        {
            name: "AES-GCM",
            length: 256,
        },
        false,
        ["encrypt"]
    );

    // Encrypt using AES-GCM
    const encryptedData = await window.crypto.subtle.encrypt(
        {
            name: "AES-GCM",
            iv: encoder.encode(iv),
        },
        cryptoKey,
        data
    );

    // Convert the encrypted data to a hex string
    const encryptedHex = await Array.from(new Uint8Array(encryptedData))
        .map((byte) => byte.toString(16).padStart(2, "0"))
        .join("");
        // console.log("encryptedHex", encryptedHex);

    return encryptedHex;
}



export  const decrypt =async (encryptedDataHex) => {

    const encoder = new TextEncoder();
    const decoder = new TextDecoder();
    const encryptedData = Uint8Array.from(
        encryptedDataHex.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
    );

    // Import the key
    const cryptoKey = await window.crypto.subtle.importKey(
        "raw",
        encoder.encode(AES256Password),
        {
            name: "AES-GCM",
            length: 256,
        },
        false,
        ["decrypt"]
    );

    // Decrypt using AES-GCM
    const decryptedData = await window.crypto.subtle.decrypt(
        {
            name: "AES-GCM",
            iv: encoder.encode(iv),
        },
        cryptoKey,
        encryptedData
    );

    const decryptedText = decoder.decode(decryptedData);
    return decryptedText;

  // const decipher = crypto.createDecipheriv(algorithm, AES256Password, iv)
  // encrypted = Buffer.from(encrypted, 'hex');
  // const content = encrypted.slice(0, encrypted.length - 16);
  // const tag = encrypted.slice(encrypted.length - 16, encrypted.length);
  // decipher.setAuthTag(tag);
  // let dec = decipher.update(content)
  // dec += decipher.final('utf8');
  // return dec;
}
// new encrypt
// import { AES, enc } from "crypto-js";
//
// const key = "56cf86ert74ghlk58624s3rfgh558000"; // Replace with your secret key
// const iv = "0123456789qw"; // Replace with your initialization vector (IV)
//
// export const encrypt = (text) => {
//   const encrypted = AES.encrypt(text, key, { iv: iv }).toString();
//   return encrypted;
// };
//
// export const decrypt = (encryptedText) => {
//   const decrypted = AES.decrypt(encryptedText, key, { iv: iv }).toString(enc.Utf8);
//   return decrypted;
// };



export const makeSeconds=(date)=>{
  var now = new Date(date);
  var time = now.getTime();
  var offset = now.getTimezoneOffset();
  offset = offset * 60000;

  return time - offset;


  // let time=((((((((((((new Date(date).getFullYear()-1)*12+(new Date(date).getMonth()-1))*30)+new Date(date).getDay()-1)*24)+(new Date(date).getHours()-1)))*60)+(new Date(date).getMinutes()-1)))*60));
  // return time;
}
