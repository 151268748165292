import React from 'react';
import ResetPassword from "../Components/Auth/ResetPassword";

const ResetPasswordPage = () => {
    return (
        <div>
            <ResetPassword/>
        </div>
    );
};

export default ResetPasswordPage;