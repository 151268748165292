import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Spin } from "antd";
import { useDispatch } from "react-redux";
import { decrypt } from "../../../utils/encryption";
import { default as Titles } from "../../../utils/document-titles";
import { fetchSessionsParams, updateSessionsParams } from "../../../redux/actions/admin/admin";
export const useMountEffect = (fun) => useEffect(fun, []);


// Gneral Focus Hook
const UseFocus = () => {
  const htmlElRef = useRef(null)
  const setFocus = () => { htmlElRef.current && htmlElRef.current.focus() }

  return [htmlElRef, setFocus]
}

const SessionsParameters = () => {
  document.title = Titles.sessionParams;

  const history = useNavigate();
  const [input1Ref, setInput1Focus] = UseFocus();
  const [isLoading, setIsLoading] = useState(false);
  const [ovsRate, setOvsRate] = useState();
  const [intermissionTime, setIntermissionTime] = useState();
  const [measuringPeriod, setMeasuringPeriod] = useState();
  const [samplingPointDelay, setSamplingPointDelay] = useState();
  const [noOfMeasurement, setNoOfMeasurement] = useState();
  const [targetedUweAtT1, setTargetedUweAtT1] = useState();
  const [defaultDeltaTime, setDefaultDeltaTime] = useState();
  const [ksg, setKsg] = useState();
  const [saturationVoltage, setSaturationVoltage] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    fetchingSessionsParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  // getting paramas
  const fetchingSessionsParams = async () => {
    let result = await dispatch(fetchSessionsParams());


    if (result && result.status && result.status === 200) {

      let data = await decrypt(result.data.data);
      data = JSON.parse(data);
      setOvsRate(data.ovsRate);
      setIntermissionTime(data.intermissionTime);
      setMeasuringPeriod(data.measuringPeriod);
      setSamplingPointDelay(data.samplingPointDelay);
      setNoOfMeasurement(data.noOfMeasurement);
      setTargetedUweAtT1(data.targetedUweAtT1);
      setDefaultDeltaTime(data.defaultDeltaTime);
      setKsg(data.ksg);
      setSaturationVoltage(data.saturationVoltage);


    }
  }


  const handleUserUpdate = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);

      let result = await dispatch(
        updateSessionsParams({
          ovsRate: ovsRate,
          intermissionTime: parseInt(intermissionTime),
          measuringPeriod: parseInt(measuringPeriod),
          samplingPointDelay: parseInt(samplingPointDelay),
          noOfMeasurement: parseInt(noOfMeasurement),
          targetedUweAtT1: parseInt(targetedUweAtT1),
          defaultDeltaTime: parseInt(defaultDeltaTime),
          ksg: parseInt(ksg),
          saturationVoltage: parseInt(saturationVoltage),

        })
      );
      setIsLoading(false);



      // ShowToast(result.data.message, "success");
    } catch (err) {
      ////(err.response)

      // ShowToast(err.response.data.message, "error");
      setIsLoading(false);
    }
  };
  const validate = (val) => {
    // // console.log("Val", val);
    let v1 = document.getElementById("targetedUweAtT1");
    let v2 = document.getElementById("ovsRate");
    let v3 = document.getElementById("measuringPeriod");
    let v4 = document.getElementById("noOfMeasurement");
    let v5 = document.getElementById("samplingPointDelay");
    let v6 = document.getElementById("intermissionTime");
    let v7 = document.getElementById("defaultDeltaTime");
    let v8 = document.getElementById("ksg");
    let v9 = document.getElementById("saturationVoltage");




    // let v6 = document.getElementById("ans");

    let flag1 = true;
    let flag2 = true;
    let flag3 = true;
    let flag4 = true;
    let flag5 = true;
    let flag6 = true;
    let flag7 = true;
    let flag8 = true;
    let flag9 = true;
    let flag10 = true;
    let flag11 = true;
    let flag12 = true;
    let flag13 = true;
    let flag14 = true;
    let flag15 = true;




    if (val === 1) {
      if (v1.value === "") {
        v1.style.borderColor = "#b78181";
        flag1 = false;
      }
      else {
        v1.style.borderColor = "#1f3c90";
        flag1 = true;
      }
    }

    if (val === 2) {
      if (v2.value === "") {
        v2.style.borderColor = "#b78181";
        flag2 = false;
      }
      else {
        v2.style.borderColor = "#1f3c90";
        flag2 = true;
      }
    }
    if (val === 3) {
      if (v3.value === "") {
        v3.style.borderColor = "#b78181";
        flag3 = false;
      }
      else {
        v3.style.borderColor = "#1f3c90";
        flag3 = true;
      }
    }
    if (val === 4) {
      if (v4.value === "") {
        v4.style.borderColor = "#b78181";
        flag4 = false;
      }
      else {
        v4.style.borderColor = "#1f3c90";
        flag4 = true;
      }
    }
    if (val === 5) {
      if (v5.value === "") {
        v5.style.borderColor = "#b78181";
        flag5 = false;
      }
      else {
        v5.style.borderColor = "#1f3c90";
        flag5 = true;
      }
    }
    if (val === 6) {
      if (v6.value === "") {
        v6.style.borderColor = "#b78181";
        flag6 = false;
      }
      else {
        v6.style.borderColor = "#1f3c90";
        flag6 = true;
      }
    }
    if (val === 7) {
      if (v7.value === "") {
        v7.style.borderColor = "#b78181";
        flag7 = false;
      }
      else {
        v7.style.borderColor = "#1f3c90";
        flag7 = true;
      }
    }
    if (val === 8) {
      if (v8.value === "") {
        v8.style.borderColor = "#b78181";
        flag8 = false;
      }
      else {
        v8.style.borderColor = "#1f3c90";
        flag8 = true;
      }
    }
    if (val === 9) {

      if (v9.value === "") {
        v9.style.borderColor = "#b78181";
        flag9 = false;
      }
      else {
        v9.style.borderColor = "#1f3c90";
        flag9 = true;
      }
    }


    // if (val === 10) {
    //   if (v10.value === "") {
    //     v10.style.borderColor = "#b78181";
    //     flag10 = false;
    //   }
    //   else {
    //     v10.style.borderColor = "#1f3c90";
    //     flag10 = true;
    //   }
    // }

    // if (val === 11) {
    //   if (v11.value === "") {
    //     v11.style.borderColor = "#b78181";
    //     flag11 = false;
    //   }
    //   else {
    //     v11.style.borderColor = "#1f3c90";
    //     flag11 = true;
    //   }
    // }

    // if (val === 12) {
    //   if (v12.value === "") {
    //     v12.style.borderColor = "#b78181";
    //     flag12 = false;
    //   }
    //   else {
    //     v12.style.borderColor = "#1f3c90";
    //     flag12 = true;
    //   }
    // }

    // if (val === 13) {
    //   if (v13.value === "") {
    //     v13.style.borderColor = "#b78181";
    //     flag13 = false;
    //   }
    //   else {
    //     v13.style.borderColor = "#1f3c90";
    //     flag13 = true;
    //   }
    // }

    // if (val === 14) {
    //   if (v14.value === "") {
    //     v14.style.borderColor = "#b78181";
    //     flag14 = false;
    //   }
    //   else {
    //     v14.style.borderColor = "#1f3c90";
    //     flag14 = true;
    //   }
    // }

    // if (val === 15) {
    //   if (v15.value === "") {
    //     v15.style.borderColor = "#b78181";
    //     flag15 = false;
    //   }
    //   else {
    //     v15.style.borderColor = "#1f3c90";
    //     flag9 = true;
    //   }
    // }

    let flag = flag1 && flag2 && flag3 && flag4 && flag5 && flag6 && flag7 && flag8 && flag9;

    return flag;
  }


  useMountEffect(setInput1Focus);

  return (
    <section className="row mw-100 ml-auto sessions-params mr-auto">

      <div className="col-12 col-md-9 m-auto">
        <form className="form-card" onSubmit={handleUserUpdate}>
          <div className="card">
            <div className="row justify-content-center pb-1 pt-1 text-left mw-100 m-auto">
              <div className="col-12 p-3">
                <h4 className="font-bold">Sessions Parameters</h4></div>
            </div>

          </div>
          <div className="card mt-3">
            <div className="row">
              {/* <div className="col-8 m-auto mb-5 d-flex justify-content-between">
                <p><span className="">L</span> : <span className="">L-Scan</span></p>
                <p><span className="">Z</span> : <span className="">Z-Scan</span></p>
                <p><span className="">M</span> : <span className="">Modus Scan</span></p>

              </div> */}
            </div>
            <div className="row justify-content-center pb-1 pt-2 text-left">

              <div className="form-group col-12 col-md-5 flex-column d-flex">
                <label className="form-control-label">Targeted Uwe At T1 <span className="" style={{ marginLeft: "3%" }}>[mV]</span></label>


                <input

                  defaultValue={targetedUweAtT1}
                  onChange={(e) => setTargetedUweAtT1(e.target.value)}
                  type="number"
                  id="targetedUweAtT1"
                  name="targetedUweAtT1"
                  required
                  onBlur={() => validate(1)}
                  className="registration-form-control"


                />
              </div>



              <div className="form-group col-12 col-md-5 flex-column d-flex">
                <label className="form-control-label">ovsRate <span className="" style={{ marginLeft: "3%" }}>[power of 2]</span></label>

                <input

                  defaultValue={ovsRate}
                  onChange={(e) => setOvsRate(e.target.value)}
                  type="number"
                  id="ovsRate"
                  name="ovsRate"
                  required
                  onBlur={() => validate(2)}
                  className="registration-form-control"
                />
              </div>
            </div>
            <div className="row justify-content-center pb-1 pt-2 text-left">
              <div className="form-group col-sm-5 flex-column d-flex">
                <label className="form-control-label">Measurement Period <span className="" style={{ marginLeft: "3%" }}>[s]</span></label>
                <input
                  required
                  defaultValue={measuringPeriod}
                  onChange={(e) => setMeasuringPeriod(e.target.value)}
                  type="number"
                  className="registration-form-control"
                  id="measuringPeriod" name="measuringPeriod" onBlur={() => validate(3)}


                />
              </div>
              <div className="form-group col-12 col-sm-5 flex-column d-flex">
                <label className="form-control-label">No Measurement <span className="" style={{ marginLeft: "3%" }}>[-]</span></label>
                <input
                  required

                  defaultValue={noOfMeasurement}
                  onChange={(e) => setNoOfMeasurement(e.target.value)}
                  type="number"
                  className="registration-form-control"
                  id="noOfMeasurement" name="noOfMeasurement" onBlur={() => validate(4)}

                />

              </div>
              
            </div>
            <div className="row justify-content-center pb-1 pt-2 text-left">
              <div className="form-group col-sm-5 flex-column d-flex">
                <label className="form-control-label">Sampling Point Delay <span className="" style={{ marginLeft: "3%" }}>[ms]</span></label>
                <input
                  required

                  defaultValue={samplingPointDelay}
                  onChange={(e) => setSamplingPointDelay(e.target.value)}
                  type="number"
                  className="registration-form-control"
                  id="samplingPointDelay" name="samplingPointDelay" onBlur={() => validate(5)}

                />
              </div>

              
              <div className="form-group col-sm-5 flex-column d-flex">
                <label className="form-control-label">IntermissionTime <span className="" style={{ marginLeft: "3%" }}>[ms]</span></label>
                <input
                  required

                  defaultValue={intermissionTime}
                  onChange={(e) => setIntermissionTime(e.target.value)}
                  type="number"
                  id="intermissionTime"
                  name="intermissionTime"

                  onBlur={() => validate(6)}
                  className="registration-form-control"


                />
              </div>




              {/*New fields*/}

              <div className="form-group col-12 col-sm-5 flex-column d-flex">
                <label className="form-control-label">Default Delta Time <span className="" style={{ marginLeft: "3%" }}>[ms]</span></label>
                <input
                  required

                  defaultValue={defaultDeltaTime}
                  onChange={(e) => setDefaultDeltaTime(e.target.value)}
                  type="number"
                  className="registration-form-control"
                  id="defaultDeltaTime" name="defaultDeltaTime" onBlur={() => validate(7)}

                />

              </div>
              
              <div className="form-group col-sm-5 flex-column d-flex">
                <label className="form-control-label">Ksg<span className="" style={{ marginLeft: "3%" }}>[%]</span></label>
                <input
                  required

                  defaultValue={ksg}
                  onChange={(e) => setKsg(e.target.value)}
                  type="number"
                  id="ksg"
                  name="ksg"

                  onBlur={() => validate(8)}
                  className="registration-form-control"


                />
              </div>
              <div className="form-group col-sm-5 flex-column d-flex">
                <label className="form-control-label">Saturation Voltage <span className="" style={{ marginLeft: "3%" }}>[mV]</span></label>
                <input
                  required

                  defaultValue={saturationVoltage}
                  onChange={(e) => setSaturationVoltage(e.target.value)}
                  type="number"
                  id="saturationVoltage"
                  name="saturationVoltage"

                  onBlur={() => validate(9)}
                  className="registration-form-control"


                />
              </div>

            </div>

          </div>
          <div className="row justify-content-center">

            <div className="form-group col-sm-6 flex-column d-flex mt-3">
              <Spin spinning={isLoading}>
                <button
                  type="Submit"
                  className={`registration-btn btn-primary `}
                >
                  Submit
                </button>
              </Spin>
            </div>
          </div>
        </form>

      </div>

    </section>

  );
};

export default SessionsParameters;
