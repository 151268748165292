import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import GlucoRxLogo from "../../Assets/Logo/GlucoRxLogo.svg";
import voyagerLogo from "../../Assets/Logo/voyagerLogo.svg";
import appStore from "../../Assets/Logo/app-store.png";
import playStore from "../../Assets/Logo/play-store.png";

class RegistrationComp extends Component {
  render() {
    return (
      <div>
        <div className="">
          <div className="container-fluid bg-white registration-top-bar">
            <div className="row align-items-center py-2 px-2">
              <img className="mr-auto" src={GlucoRxLogo} width="130px"></img>
            </div>
          </div>
          <div className="container-fluid registration-background d-flex flex-column">
            <div className="row  justify-content-md-between  justify-content-center  ml-md-3 ml-lg-0">
              <div className="col-md-4 col-11  mt-5">
                <div className="text-md-left text-center text-uppercase registration-account-heading1">
                  <p>CREATE EOSTRE ACCOUNT</p>
                </div>
                <div className="text-md-left text-center registration-account-heading2 mt-n4 mr-md-5 mr-0">
                  <p>
                    Create a GlucoRx Account here to place orders in the store,
                    use our apps and access data software. It only takes a few
                    minutes.
                  </p>
                </div>
                <div className="text-md-left text-center ml-md-3 ml-0">
                  <img src={voyagerLogo} width="200px"></img>
                </div>
              </div>
              <div className="col-md-3 col-11 registration-account-field-box mr-md-5 mr-0 mt-5 ">
                <form>
                  <div className="container mt-5">
                    <div className="row justify-content-center text-white">
                      <div className="col-md-8">
                        <div className="form-group">
                          <label htmlFor="formGroupExampleInput ">Email</label>
                          <input
                            type="email"
                            className="form-control"
                            id="formGroupExampleInput"
                          />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div className="form-group">
                          <label htmlFor="formGroupExampleInput ">
                            Confirm Email
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="formGroupExampleInput"
                          />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div className="form-group">
                          <label htmlFor="formGroupExampleInput ">Password</label>
                          <input
                            type="password"
                            className="form-control"
                            id="formGroupExampleInput"
                          />
                        </div>
                      </div>
                      <div className="col-md-8 ">
                        <div className="form-group">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input registration-checkbox"
                              type="checkbox"
                              id="inlineCheckbox1"
                              value="option1"
                            />
                            <label
                              className="form-check-label ml-3"
                              htmlFor="inlineCheckbox1"
                            >
                              By checking this box, you agree to the terms of
                              use and privacy policy.
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-8 mt-5">
                        <button
                          type="submit"
                          className="login-btn btn-primary "
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RegistrationComp;
