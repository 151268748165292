export const FETCH_BG_START = "FETCH_BG_START"
export const FETCH_BG_SUCCESS = "FETCH_BG_SUCCESS"
export const  FETCH_BG_FAILED = "FETCH_BG_FAILED"
export const FETCH_SENSOR_START = "FETCH_SENSOR_START"
export const FETCH_SENSOR_SUCCESS = "FETCH_SENSOR_SUCCESS"
export const  FETCH_SENSOR_FAILED = "FETCH_SENSOR_FAILED"

export const FETCH_CARBS_START = "FETCH_CARBS_START"
export const FETCH_CARBS_SUCCESS = "FETCH_CARBS_SUCCESS"
export const  FETCH_CARBS_FAILED = "FETCH_CARBS_FAILED"

export const FETCH_INSULIN_START = "FETCH_INSULIN_START"
export const FETCH_INSULIN_SUCCESS = "FETCH_INSULIN_SUCCESS"
export const  FETCH_INSULIN_FAILED = "FETCH_INSULIN_FAILED"

export const FETCH_ACTIVITY_START = "FETCH_ACTIVITY_START"
export const FETCH_ACTIVITY_SUCCESS = "FETCH_ACTIVITY_SUCCESS"
export const  FETCH_ACTIVITY_FAILED = "FETCH_ACTIVITY_FAILED"

export const FETCH_CGM_REPO_SUCCESS = "FETCH_CGM_REPO_SUCCESS"
export const FETCH_HISTORY_CGM_RECORD_SUCCESS = "FETCH_HISTORY_CGM_RECORD_SUCCESS"
export const SAVE_MBG_SUCCESS = "SAVE_MBG_SUCCESS"
export const SAVE_SDBG_SUCCESS = "SAVE_SDBG_SUCCESS"
export const SAVE_ADBG_SUCCESS = "SAVE_ADBG_SUCCESS"

export const SAVE_CV_SUCCESS = "SAVE_CV_SUCCESS"
export const SAVE_LAGE_SUCCESS = "SAVE_LAGE_SUCCESS"
export const SAVE_JINDEX_SUCCESS = "SAVE_JINDEX_SUCCESS"
export const SAVE_OGP_SUCCESS = "SAVE_OGP_SUCCESS"
export const SAVE_MAGE_SUCCESS = "SAVE_MAGE_SUCCESS"
export const SAVE_MVALUE_SUCCESS = "SAVE_MVALUE_SUCCESS"
export const SAVE_IQR_SUCCESS = "SAVE_IQR_SUCCESS"
export const SAVE_AAC_SUCCESS = "SAVE_AAC_SUCCESS"
export const SAVE_AUC_SUCCESS = "SAVE_AUC_SUCCESS"
export const SAVE_HBGI_SUCCESS = "SAVE_HBGI_SUCCESS"
export const SAVE_ADRR_SUCCESS = "SAVE_ADRR_SUCCESS"
export const SAVE_HBA1C_SUCCESS = "SAVE_HBA1C_SUCCESS"



