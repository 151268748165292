import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector} from "react-redux";
import { default as Titles } from "../../utils/document-titles";
import { login } from "../../redux/actions/auth";
import GlucoRxLogo from "../../Assets/Logo/GlucoRxLogo.svg";
import ModusFooter from "../../Assets/Logo/modusfooter.png";
import Playstore from "../../Assets/Logo/play-store.png";
import appstore from "../../Assets/Logo/app-store.png";
import ShowToast from "../../utils/show-toast";

const LoginNewComp = () => {
  document.title = Titles.login;


  const history = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);

  const dispatch = useDispatch();
  useEffect(()=>{

  },[
    isValidEmail
  ])
    const validateEmail = () => {
      // Regular expression for basic email format validation
      // const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;
      const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      const isValid = emailRegex.test(email);
      console.log("isValid", isValid);
      setIsValidEmail(isValid);

      return isValid;
    };
  const handleSubmit = async (e) => {


    try {
      e.preventDefault();

      setIsLoading(true);
      if (validateEmail()) {
      // Your form submission logic goes here
      await dispatch(login({ email, password }));
      setIsLoading(false);
    } else {
      console.log('Invalid email format. Please correct before submitting.');
    }

    } catch (err) {
// console.log("err", err);
      // ShowToast(err.response.data.message, "error");
      setIsLoading(false);

      if (err.response && err.response.data && err.response.data.message === "Please activate your account first!") {


       history("/auth/resendVerificationEmail");
      }
    }
  };
  return (
    <div>
            <div className="">
            <div className='container-fluid'>


              <div className="row  justify-content-md-between loginNew-bg justify-content-center pl-md-3 .pl-lg-0 pr-1 pr-0">

              <div className="col-md-5 col-11  mt-3">

                <div className="text-md-left text-center ">
                  <img src={GlucoRxLogo} width="200px"></img>
                </div>
                <div className="text-md-left text-center my-5  text-uppercase">
                  <h1 className='loginNew-heading-1'><span className="font-weight-bold">Login to your</span> Modus Account</h1>
                </div>
                <div className="text-md-left text-center mt-5">

                </div>
              </div>
              <div className="col-md-5 col-sm-6 col-11 loginNew-fieldBox  mt-5 mb-5 ">
                <form onSubmit={handleSubmit}>
                <div className="container-fluid mb-3 mt-3">
                          <div className="row justify-content-end">
                              <div className="col-md-3 col-sm-3 col-4 loginNew-HCP-panel  text-right text-black">


                              </div>

                          </div>
                      </div>
                  <div className="container ">

                    <div className="row justify-content-center text-white">
                    <div className="col-md-8">
                        <h1 className="font-weight-bold font-blue-dark">Welcome</h1>
                        <h6 className="font-weight-bold font-blue-dark">Log in to continue</h6>
                    </div>
                      <div className="col-md-8 mt-4">
                        <div className="form-group">

                          <input
                            type="email"
                            className="loginNew-form-control"
                            placeholder="Email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                        </div>
                        {isValidEmail===false?<div>
                                          <span>Please provide a valid email.</span>
                                        </div>:null}
                      </div>
                      <div className="col-md-8">
                        <div className="form-group">

                          <input
                            type="password"
                            className="loginNew-form-control"
                            placeholder="Password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-8">
                      <div className="text-right  text-white ">
                        <Link to="/forgetPassword"><p className="font-blue">Forgot your password?</p></Link>
                      </div>

                      </div>
                      <div className="col-md-8 mt-2 ">
                        <button
                          type="submit"
                          className="login-btn btn-primary  bg-primary"
                        >
                          Login
                        </button>
                      </div>
                      {/*<div className="col-md-8 mt-2 ">


                          <Link to="/Registration" className="login-btn btn-primary ">
                          Signup
                           </Link>
                      </div>*/}

                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className='row justify-content-between align-items-center loginNew-footer'>
                <div className='col-md-3 col-11'>
                    <h1 className="fw-bolder m-auto">MODUS</h1>
                </div>
                <div className='col-md-5  col-11'>
                    <div className="container-fluid">
                    <div className='row '>

                    </div>
                 <div className='row '>
                     <div className='col-12'>
                     <div className='text-right '>
                      <p className="m-auto">
                        Modus, Clinical Trials.
                       </p>

                   </div>
                     </div>
                 </div>

                </div>
                </div>
            </div>
            </div>

     </div>
    </div>
  );
};

export default LoginNewComp;
