import React, { useState, useEffect }from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, NavLink} from "react-router-dom";


import Sidebar from "./Sidebar";
import AdminSidebar from "./adminSidebar";
import ClinicalSidebar from "./clinicalSidebar";



import Navbar from "./Navbar";
import ExportData from "../Export/ExportData";
import {fetchSettings} from "../../redux/actions/settings";

export default function SharedLayout(props) {
  const dispatch = useDispatch();
  let location = useLocation();
  const { userDetails } = useSelector((state) => {return state.user});
  // const [showSidebar, setShowSidebar]= useState(false);
  // console.log("PROPS IN SHARED", props);

  useEffect(() => {
    dispatch(fetchSettings());
    // if(userDetails && userDetails.user && userDetails.user.role.length===1 && userDetails.user.role[0]=== 4){
    //
    //     // setShowSidebar(true);
    // }
    // if(userDetails && userDetails.user && userDetails.user.role.length>1){
    //   for(let i = 0; i<userDetails.user.role.length; i++) {
    //     if(userDetails.user.role[i] === 4 ) {
    //
    //       // setShowSidebar(true);
    //     }
    //   }
    // }
    }, []);

  // if(userDetails && userDetails.user && userDetails.user.role.length===1 && userDetails.user.role[0]=== 4){

  //     setShowSidebar(true);
  // }
  // if(userDetails && userDetails.user && userDetails.user.role.length>1){
  //   for(let i = 0; i<userDetails.user.role.length; i++) {
  //     if(userDetails.user.role[i] === 4) {

  //       setShowSidebar(true);
  //     }
  //   }
  // }


  const [collapsed, setCollapsed]= useState(true);

        return (
            <div className="row mw-100 m-auto" style = {{background: "#fff" }} >
                <div className="w-full p-0 m-0 header col-12" >
                    <Navbar collapsed = {collapsed}/>
                </div>
                {userDetails && userDetails.user && userDetails.user.role.length === 1 && userDetails.user.role.includes(4)===true && location.pathname !== "/auth/deleteUser" && location.pathname !== "/auth/updateEmail"?
                <div className="col-12 col-md-2 bg-primary rounded ">
                  <Sidebar collapsed = {collapsed}/>
                </div>
                :userDetails && userDetails.user && (userDetails.user.role.includes(0)===true || userDetails.user.role.includes(1)===true) && userDetails.user.role.includes(4)===false && location.pathname !== "/auth/deleteUser" && location.pathname !== "/auth/updateEmail"?

                <div className="col-12 col-md-2 bg-primary rounded " style={{height:"89vh"}}>
                <AdminSidebar collapsed = {collapsed}/>
                </div>
                :userDetails && userDetails.user && (userDetails.user.role.includes(5)===true || userDetails.user.role.includes(6)===true) && userDetails.user.role.includes(4)===false && location.pathname !== "/auth/deleteUser" && location.pathname !== "/auth/updateEmail"?

                <div className="col-12 col-md-2 bg-primary rounded " style={{height:"92vh"}}>
                <ClinicalSidebar collapsed = {collapsed} roles={userDetails.user.role} cAdmin={userDetails.user.role.length === 1 && userDetails.user.role.includes(6)=== true? true :
                userDetails.user.role.length === 1 && userDetails.user.role.includes(5)=== true? false: userDetails.user.role.length > 1 && userDetails.user.role.includes(6)=== true? true:false}
                eAdmin={userDetails.user.role.length === 1 && userDetails.user.role.includes(5)=== true? true :
                userDetails.user.role.length === 1 && userDetails.user.role.includes(6)=== true? false: userDetails.user.role.length > 1 && userDetails.user.role.includes(5)=== true? true:false}/>
                </div>:
                null
              }
                <main className="col bg-faded " style={{maxHeight:"92vh",overflow:"auto"}}>
                <div className="row mw-100 m-auto">
                  <div className="col-12 text-right">


                  </div>
                  <div className="col-12">
                  {location.pathname !== "/export-data" &&
                   userDetails && userDetails.user &&
                    userDetails.user.role.includes(1)===false &&
                    userDetails.user.role.includes(0)===false &&
                    userDetails.user.role.includes(5)===false &&
                    userDetails.user.role.includes(6)===false &&
                    location.pathname !== "/auth/deleteUser" &&
                    location.pathname !== "/auth/updateEmail"?
                  <ExportData/>
                  : null}
                  { props.children }
                  </div>
                </div>
                </main>
                <NavLink
                className={location.pathname === "/Feedback"?"d-none":"feedback-btn sticky print-btn"}
                  to="/Feedback"

                >
                  <p className="m-0 print-btn">Feedback</p>
                </NavLink>
        </div>
        );
}
