import * as actions from "../actionTypes";

const initialState = {

  settings:{},
  isLoading: false,

};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_SETTINGS_START:
      return{...state,
      isLoading: true}
      break
    case actions.FETCH_SETTINGS_SUCCESS:
      return {
        ...state,
        ...action.payLoad,
        settings: action.payload,
        isLoading: false
      }
      break
    case actions.FETCH_SETTINGS_FAILED:
      return{
        ...state,
        isLoading: false
      }
      break
    default:
      return state;
  }
}
