import React, { useState,useEffect } from "react";
// import { LogoComponent as Logo } from "../../Assets";
import { default as Titles } from "../../utils/document-titles";
import { Spin, Form, Input } from "antd";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { resetEmailConfirm } from "../../redux/actions/auth";
import GlucoRxLogo from "../../Assets/glucorxwhite.svg";
import ShowToast from "../../utils/show-toast";
import LoadingEmail from "./loading-email";
// import ShowToast from "../../utils/show-toast";

export default function ResetEmail() {
  // const [tokUrl, setTokUrl] = useState("");

  useEffect(()=>{
    // console.log("I'M  HERE AND GLAD");
    const localToken = localStorage.getItem("grxAuthToken");
    // setTokUrl(tokenUrl);

  })
  // document.title = Titles.forgotPassword;

  const history = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");


  const dispatch = useDispatch();







  const handleSubmit = async (event) => {
    event.preventDefault();
    let newEmail = email;
    let tokenUrl = new URLSearchParams(window.location.search).get("token");
    if(tokenUrl === "" || tokenUrl === null){

      tokenUrl =localStorage.getItem("token");

    } else{


    }

    try {
      setIsLoading(true);
      const result=await dispatch(resetEmailConfirm(newEmail, tokenUrl));
      localStorage.removeItem("token");
      history.push("/dashboard");

      ShowToast(result.data.message, "success");
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
    return true;


  };

  return (
    <div className='container-fluid d-flex flex-column forget-background ' >

    <div className="row justify-center align-item-center my-auto" >
        <div className="col-md-7 col-11 forget-field-box align-items-center py-5 m-auto">
           <div className='d-flex justify-content-center '><img src={GlucoRxLogo} className='forget-gluco-logo '></img></div>
           <p className=' text-white text-center forget-text-font mt-3'>Enter new email address </p>
           <form onSubmit={handleSubmit} >
            <div className="row justify-content-center align-items-center my-3 text-white">
                <div className="col-md-10 col-10">
                <div className="pass-wrapper">
                        <input
                          type="email"


              onChange={(e) => setEmail(e.target.value)}
                          className="registration-form-control"
                          id="formGroupExampleInput"
                          required
                        />

                     </div>
                </div>
            </div>

            <div className="row justify-content-center align-items-center my-3 text-white">
                <div className="col-md-10 col-10">
            <Spin spinning={isLoading}>
                  <button
                        type="submit"
                        className="forget-submit-btn btn-primary "
                      >
                       Reset Email
                    </button>
                    </Spin>
                </div>
            </div>
            </form>
            {/*<div className="mt-3 text-white">
                <p className="text-center forget-text-font">
                <Link
              to={"/auth/login"}>
              Back to Sign In!
            </Link>
                </p>
            </div>*/}
        </div>


        </div>


</div>
  );
}
