import http from "../../utils/http-service";
import { API_BASE_URL, fetchBgDataUrl } from "../../utils/urls";
import {
  FETCH_HISTORY_START,
  FETCH_HISTORY_FAILED,
  FETCH_HISTORY_SUCCESS,
} from "../actionTypes/history";
import {encrypt, decrypt} from "../../utils/encryption";
import {convertDates,
convertLastDates, findStartDate} from "./blood-glucose";
import moment from "moment";
export const fetchHistoryData = (data) => async(dispatch) => {
  dispatch({
    type: FETCH_HISTORY_START,
  });

  let type = 1;
try {

  let bgTime;
  let res;
  let dayDif;
    if(data.time){
      bgTime = data.time.replace(/ /g, '');
      dayDif = data.time;
      let calculatedStartDate = await findStartDate(data.time);
      // console.log("calculatedStartDate", calculatedStartDate);
      let bg_start = calculatedStartDate.date;
      bg_start = bg_start.toISOString();
      bg_start = await encrypt(bg_start);
      let bg_end = new Date();
      bg_end = bg_end.toISOString();
      bg_end = await encrypt(bg_end);
      let encType = type;
      encType = JSON.stringify(encType);
      encType = await encrypt(encType);
     res = await http.get(`${API_BASE_URL}${fetchBgDataUrl}${encType}?start_date=${bg_start}&end_date=${bg_end}`);
    } else if(data.start_date) {

      let bg_start = new Date(data.start_date);
      bg_start = bg_start.toISOString();

      let bg_end = new Date(data.end_date);
      bg_end = bg_end.toISOString();

      // Parse date strings using Moment.js
      const date1 = moment.utc(bg_start);
      const date2 = moment.utc(bg_end);
      let today =  new Date();
      today = moment.utc(today);
      // Compare day, month, and year
      if (date1.isSame(date2, 'day') && date1.isSame(date2, 'month') && date1.isSame(date2, 'year')) {
        if(date1.isSame(today, 'day') && date1.isSame(today, 'month') && date1.isSame(today, 'year')){
          bg_end = new Date();
          bg_end = bg_end.toISOString();
        }else {
          bg_end  = convertLastDates(bg_end);
        }

      } else {
        bg_end= bg_end.toISOString();
      }

      bg_start = convertDates(data.start_date);
      bg_start = await encrypt(bg_start);

      bg_end = await encrypt(bg_end);
      dayDif = moment.duration(moment(data.end_date).diff(moment(data.start_date))).asDays();
      let encType = 1;
      encType = JSON.stringify(encType);
      encType = await encrypt(encType);
     res = await http.get(`${API_BASE_URL}${fetchBgDataUrl}${encType}?start_date=${bg_start}&end_date=${bg_end}`);

}
let receivedData = res.data.data;
receivedData = await decrypt(receivedData);
receivedData = JSON.parse(receivedData);
// console.log("receivedData", receivedData);
let historyData = await dispatch(fetchCgmDataForHistory(receivedData.historicalReadings, dayDif));




  // updateUnit(unit, res.data.data)
  // dispatch({ type: FETCH_HISTORY_SUCCESS, payLoad: receivedData });

  return historyData;
} catch (error) {

  dispatch({
    type: FETCH_HISTORY_FAILED,
  });
}
};



// const findMinMax=(arr,key)=> {
// const datas = arr.map((node) => node[key]);
// return {
//   min: Math.min(...datas),
//   max: Math.max(...datas),
// }
// }



const fetchCgmDataForHistory = (response, daysDif) => async(dispatch) => {
let dDifference = daysDif.replace(/[^A-Z\d\s]/g, "");
// console.log("dDifference", dDifference);


    // let cgm_start = data.timeFrom;
    let cgm_start =await findStartDate(daysDif);
    // console.log("CGM DATE", cgm_start);

cgm_start = cgm_start.date;
// console.log("dDifference", dDifference);

    const res = response;
    let daysArr=[];



        let data = response;
        let firstDayStartDate = moment(cgm_start);

        // let numberOfDays = Math.ceil(daysDif / 1);
        let numberOfDays = dDifference;

        let dayStartDate="";
        let dayEndDate="";
        for(let i=0; i<numberOfDays; i++){
          if(i === 0){
            dayStartDate= firstDayStartDate;
            dayEndDate = moment(firstDayStartDate).add(24, 'hours');
          }
          let dayReading=[];
          if (data && data.length > 0) {
            let daySD = dayStartDate;
            let dayED = dayEndDate;

            data.map((day, index)=> {
            let p1 = moment(daySD);
            // let p2 = moment(day.time);
            let p3 = moment(dayED);
            let readingTime = moment(day.time);
            if (readingTime.isSameOrAfter(daySD) && readingTime.isBefore(dayED)) {
                      dayReading.push({
                        time: day.time,
                        value: day.value,
                        // Add other properties as needed
                      });

                }
            // if (p2.isAfter(p1) && p2.isBefore(p3)){
            //   // // // // console.log("READING TIME", p2);
            //   let timeUTC = day.time;
            //   console.log("day",i, day);
            //   // let converted = moment(timeUTC).utc();
            //   // // // // console.log("CHECK", moment(converted).tz.guess());
            //
            //   // const gue =  moment.tz(timeUTC, "UTC");
            //   const gue =  moment(timeUTC);
            //   // // // console.log("gue", gue);
            //   let conTime = moment(gue._d).format("HH:mm");
            //   // // // console.log("conTime",conTime);
            //
            //   // let obj = {readingType: day.readingType, time: day.time, glucose: day.value, takenTime:day.takenTime, userPId: day.userPId, _id: day._id}
            //   // console.log("OBJECT :", obj);
            //   dayReading.push(day);
            // } else {
            //   console.log("dayStartDate :", moment(dayStartDate));
            //   console.log("DAY :", moment(day.time));
            //   console.log("dayEndDate :", moment(dayEndDate));
            //
            // }
          })
        } else {
        }
        // console.log("dayReading",dayReading);
          let obj = {dayStartDate:new Date(dayStartDate).toString(),
            readings:[dayReading]}
          daysArr.push(obj);
          // dayStartDate= moment(dayEndDate).add(1, 'days');
          // dayEndDate = moment(dayEndDate).add(7, 'days');
          dayStartDate= moment(dayEndDate)
          dayEndDate = moment(dayEndDate).add(24, 'hours');
        };




    // let receivedData = res.data;

  dispatch({ type: FETCH_HISTORY_SUCCESS, payLoad: daysArr });
  // // console.log("WEEK ARRAY", daysArr);
  return daysArr;

};


// const findStartDate = (filter) => {
// console.log("filter :", filter);
//
//     if(filter === "30days" || filter === "30 days") {
//       let start = moment().subtract(30, 'days');
//
//
//       return new Date(start._d);
//
//     }else if(filter==="7days" || filter === "7 days") {
//
//       let start = moment().subtract(7, 'days');
//
//
//       return new Date(start._d);
//
//
//     } else if (filter === "15days" || filter === " 15 days") {
//
//
//       let start = moment().subtract(14, 'days');
//
//       return new Date(start._d);
//
//     } else if (filter === "90days" || filter === "90 days") {
//
//       let start = moment().subtract(90, 'days');
//
//
//       return new Date(start._d);
//
//     } else if(filter === "24hours" || filter === "24 hours"){
//
//       let start = moment().add(-24, 'hours');
//
//
//       return new Date(start._d);
//
//     }
//     else if(filter === "12hours" || filter === "12 hours"){
//       let start = moment().add(-12, 'hours');
//
//
//       return new Date(start._d);
//     }
//     else if(filter === "6hours" || filter === "6 hours"){
//       let start = moment().add(-6, 'hours');
//
//
//       return new Date(start._d);
//     } else if (filter === "today") {
//
//       return new Date();
//
//     } else{
//
//     }
//
//   }


//   const makeAGPReadings= (data)=>{
//   let eachHourData=[];
//   let timeSlots=["00", "01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23"];
//
//     for(let i =0;i<24; i++){
//       let obj = data.filter((dayInner, index)=>{
//       return  parseInt(moment(dayInner.time).format("HH")) === parseInt(timeSlots[i])
//
//     });
//       obj.sort((a, b)=>{return a.value-b.value});
//       eachHourData.push(obj);
//     }
//   return eachHourData;
// }
