import * as actions from "../actionTypes/admin";

const INITIAL_STATE = {

  analytics: null,
  totalUsers: null,
  activeUsers: null,
  countryWise: null,
  cityWise: null,
  countriesPercentage: null,
  isLoading: false,

};

export default (state = INITIAL_STATE, action) => {
  // // console.log("ACTION", action);
  switch (action.type) {

    case actions.FETCH_ANALYTICS_START:
      return {
        ...state,
        isLoading: true,
      };
      break;


    case actions.FETCH_ANALYTICS_FAILED:
      return {
        ...state,
        isLoading: false,
        analytics: null,
      };

      break;

    case actions.FETCH_ANALYTICS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        analytics: action.payload,
      };
      break;



    case actions.FETCH_TOTAL_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        totalUsers: action.payload,
      };
      break;

      case actions.FETCH_ACTIVE_USERS_SUCCESS:
        return {
          ...state,
          isLoading: false,
          activeUsers: action.payload,
        };
        break;  

        case actions.FETCH_COUNTRIES_USERS_SUCCESS:
            return {
              ...state,
              isLoading: false,
              countryWise: action.payload,
            };
            break;     
        
            case actions.FETCH_COUNTRIES_PERCENTAGE_SUCCESS:
                return {
                  ...state,
                  isLoading: false,
                  countriesPercentage: action.payload,
                };
                break; 
    default:
      return {
        ...state
      }
  }
};
