import { Pattern } from "@amcharts/amcharts4/core";
import React from "react";
import { useDispatch, useSelector} from "react-redux";

import Navbar from "../Components/Common/Navbar";
import Sidebar from "../Components/Common/Sidebar";

import StatisticsComp from "../Components/Reports/StatisticsComp";
import Maintainance from "./maintainance";


const  StatisticsPage = () => {
  const userData = useSelector((state) => state.user);

  return (

    <div className="container-fluid h-100 bg-white">

    <div className="row h-100 bg-light rounded mx-1 pl-md-2 pl-0 py-2">

        <main className="col  bg-faded ">
          <Maintainance/>
        </main>
    </div>
</div>
  );
};

export default  StatisticsPage;
