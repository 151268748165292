import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import CitiesModal from './citiesModal';
const RegionViews = () => {
    const {countryWise} = useSelector((state) => state.analytics);
    const countries = [
        { name: "UK", cities: ["London", "Manchester", "Birmingham", "Glasgow", "Liverpool"], users: 100 },
        { name: "US", cities: ["New York", "Los Angeles", "Chicago", "Houston", "Phoenix"], users: 200 },
        { name: "Finland", cities: ["Helsinki", "Espoo", "Tampere", "Vantaa", "Oulu"], users: 50 },
        { name: "China", cities: ["Shanghai", "Beijing", "Guangzhou", "Shenzhen", "Chengdu"], users: 150 },
        { name: "Pakistan", cities: ["Karachi", "Lahore", "Islamabad", "Rawalpindi", "Faisalabad"], users: 75 }
    ];
    
    return (
        <div class="p-3">
            <div class="row m-auto">
                <div className='col-12 heading'>
                <h1>Top Users </h1>
                </div>
                
            </div>
            <div class="filters row ">
                <div class="col-6"><h4>Country</h4></div>
                <div class="col-6 text-right"> <h4>Users</h4></div>

            </div>
            {countryWise && countryWise.length>0 && countryWise.map((item, index) => (
                <React.Fragment key={index}>
                    <div class="age-range">
                            <CitiesModal country={item.country} data={item.cities} index={index}/>
                            <span>{item.totalUsers}</span>
                    </div>
                    <div class="bar" style={{width: `${item.totalUsers && item.totalUsers >= 100? "100%": item.totalUsers+"%"}`}}></div>
                </React.Fragment>
            ))}
            {/* <div class="bar" style={{width: "2%"}}></div> */}
        </div>
    );
};

export default RegionViews;