import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link , useParams, useLocation} from "react-router-dom";

import { Spin } from "antd";


 import { useNavigate } from "react-router-dom";
// import {encrypt,decrypt} from "../../../utils/encryption";

import { fetchEngineerData, fetchSensorLogsData } from "../../../redux/actions/admin/admin";
import SensorTable from './sensor-table';


import moment from "moment";

// import UsersMangement from "../../common/nav/usersmanagement.js";

const ViewApplicationLogsData = (props) => {
  const dispatch = useDispatch();
  const[engineerData, setEngineerData] = useState();



  const { userDetails, selectedUser } = useSelector((state) => {return state.user});
  const { applicationLogsData, engeineerEmail } = useSelector((state) => {return state.admin});
  const [csv, setCsv] = useState("");
  const {id, email} = useParams();

  const safari = useSelector((state) => {
      // setJson(state.admin.users);

      return state.auth.safari;
    });

  useEffect(() => {
    if(applicationLogsData.length === 0){
      // // console.log("No Logs");
      fetchingLogsData();
    }
 },[]);
const fetchingLogsData=async()=>{
  let identity = id;
  identity = identity.replace(":", "");
  await dispatch(fetchSensorLogsData(identity, email));
}

const getEngineerData =async(id)=> {
dispatch(fetchEngineerData(id));
localStorage.removeItem("eId");


}


const convert = () => {
    // e.preventDefault();

    // "\uFEFF"+
    let csvString = "Author" + ";" + engeineerEmail.replace(":", "") + "\n" +
        "Download_Time" + ";" + new Date().toISOString() + "\n" + "\n" + "\n";

    const headings = {
        "Empty Column" : "",
        "Time": "",
        "Session Id": "",
        "Type": "",
        "Code": "",
        "Description": "",
        "Device": "",
    };
let logsArray=[];
  applicationLogsData && applicationLogsData.length>0 && applicationLogsData.map((session,index)=>(
    logsArray.push({
    "Empty Column" : "",
    "Time": session.time,
    "Session Id": session.sessionId,
    "Type": session.type,
    "Code": session.code,
    "Description": session.description,
    "Device": session.device,
            })

    ));
    const fileName = moment(applicationLogsData[0].time).format("YYYYMMD") + "_" + moment(applicationLogsData[0].time).format("Hm") + "_" + applicationLogsData[0].device+"logs";

    csvString += Object.keys(headings).join(";");
    csvString += "\n";
    csvString += logsArray.map((j) => Object.values(j).join(";")).join("\n");


    setCsv(`${csvString}`);
    let csvData = csvString;
    // // // // console.log("csvType", typeof (csvData));
    //
    if (safari) {
        download(csvData, fileName, 'text/csv;charset=utf-8;');

    } else {
        download("\uFEFF" + csvData, fileName, 'text/csv;charset=utf-8;');
        // download("\uFEFF"+fileDat,"EngineerNEWFILE", 'text/csv;charset=utf-8;');

    }
  };

  // Function to download data to a file
const download=( processedData, filename, type)=> {
  let data = processedData;

    let file = new Blob([data], {type: type});
    if (window.navigator.msSaveOrOpenBlob) // IE10+
        window.navigator.msSaveOrOpenBlob(file, filename);
    else { // Others
        var a = document.createElement("a"),
                url = URL.createObjectURL(file);
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        }, 0);
    }
}
// // // // console.log("EDATA FROM DATA", sensorLogsData.data);


    return (

      <div>
        <div className="">
        {userDetails && userDetails.user && (userDetails.user.role.includes(1)===true || userDetails.user.role.includes(0)===true || userDetails.user.role.includes(5) === true)?

            <div className="row  justify-content-md-between  justify-content-center mw-100 m-auto">
                <div className="col-12 col-md-6 sticky-det">
                    <h1 className="pt-3 pb-3 font-weight-bold chart-heading">You are veiwing logs of "{engeineerEmail.replace(":", "")}"</h1>
                </div>
                <div className="col-12 col-md-6 text-right sticky-det">
                <button className="btn btn-primary mt-3" onClick={()=>{convert()}}><i className={"fas fa-download"}></i> CSV</button>

                </div>
            <div className="col-12">
                              <table className="table session-table" id="tab1">
                                <thead style={{textAlign:'center'}}>
                                 <tr className="">
                                  <th>Time</th>
                                  <th>Session Id</th>
                                  <th>Type</th>
                                  <th>Code</th>
                                  <th>Description</th>
                                  <th>Device</th>

                                </tr>
                                </thead>
                              {applicationLogsData && applicationLogsData.length>0 && applicationLogsData.map((session,index)=>(

                                <SensorTable row={session} onClick={()=> {convert(session)}} count={index} key={index}/>

                              ))}
                              </table>

                            </div>


            </div>:
            <div className="row mw-100 m-auto">
              <div className="col-12">
              <h1 className="text-center" style={{height:"85vh"}}>You Are Not Athorized</h1>
              </div>
            </div>}

        </div>
      </div>
    );
}

export default ViewApplicationLogsData;
